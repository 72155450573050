import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import { MdOutlinePhotoCamera } from "react-icons/md";
import Profile from "../images/Profile-pik.png";
import { TfiClose } from "react-icons/tfi";
import useAddKYC, { useVerifyAadhaar, useVerifyPan } from "../hooks/useAddKYC";
import NotificationPopup from "./NotificationPopup";
import { useUser } from "../constants/UserContext";

const KYCModal = ({ showModal, handleClose }) => {
  const { mobileNumber } = useUser();
  const submitKyc = useAddKYC();
  const verifyAadhaar = useVerifyAadhaar();
  const verifyPan = useVerifyPan();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const [aadharFront, setAadharFront] = useState(null);
  const [aadharBack, setAadharBack] = useState(null);
  const [panImage, setPanImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [showProfileTab, setShowProfileTab] = useState(false);

  const bank = watch("bank");
  const accountHolder = watch("account_holder");
  const accountNumber = watch("account_number");
  const ifsc = watch("ifsc");

  useEffect(() => {
    if (bank && accountNumber && ifsc) {
      setShowProfileTab(true);
    }
  }, [bank, accountHolder, accountNumber, ifsc]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("aadhar_front", aadharFront);
    formData.append("aadhar_back", aadharBack);
    formData.append("pan_image", panImage);
    formData.append("image", profileImage);

    formData.append("aadhar", data.aadhar);
    formData.append("pan", data.pan);
    formData.append("bank", data.bank);
    formData.append("account_holder", verifyPan?.data?.name);
    formData.append("account_number", data.account_number);
    formData.append("ifsc", data.ifsc);
    formData.append("name", data.name);
    formData.append("mobile", mobileNumber);
    formData.append("email", data.email);
    formData.append("kyc", true);

    submitKyc.mutate({ id: mobileNumber, data: formData });
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header
        closeButton
        style={{ backgroundColor: "black", color: "white" }}
      >
        {handleClose && (
          <div className="custom-close-button" onClick={handleClose}>
            <TfiClose />
          </div>
        )}
        <Modal.Title style={{ textAlign: "center", width: "100%" }}>
          KYC
        </Modal.Title>
      </Modal.Header>

      {submitKyc.isSuccess ? (
        <NotificationPopup message="KYC Form Submitted" isSuccess />
      ) : (
        submitKyc.isError && (
          <NotificationPopup
            message={`Failed to Submit KYC form! ${submitKyc?.error?.response?.data?.error}`}
          />
        )
      )}

      <Modal.Body
        style={{
          backgroundColor: "black",
          color: "white",
          padding: 0,
          borderRadius: 6,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tabs wizard">
            <ul className="nav nav-pills" id="pills-tab" role="tablist">
              {/* Aadhar Tab */}
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-aadhar-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-aadhar"
                  type="button"
                  role="tab"
                  aria-controls="pills-aadhar"
                  aria-selected="true"
                >
                  1
                </button>
                <p>Aadhar Kyc</p>
              </li>
              {/* Pan Tab */}
              <li className="nav-item" role="presentation">
                <button
                  disabled={
                    !(verifyAadhaar.isSuccess && aadharFront && aadharBack)
                  }
                  className="nav-link"
                  id="pills-Pan-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Pan"
                  type="button"
                  role="tab"
                  aria-controls="pills-Pan"
                  aria-selected="false"
                >
                  2
                </button>
                <p>Pan Card</p>
              </li>
              {/* Bank Tab */}
              <li className="nav-item" role="presentation">
                <button
                  disabled={!(verifyPan.isSuccess && panImage)}
                  className="nav-link"
                  id="pills-Bank-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Bank"
                  type="button"
                  role="tab"
                  aria-controls="pills-Bank"
                  aria-selected="false"
                >
                  3
                </button>
                <p>Bank Details</p>
              </li>
              {/* Profile Tab */}
              <li className="nav-item" role="presentation">
                <button
                  disabled={!showProfileTab}
                  className="nav-link"
                  id="pills-four-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-four"
                  type="button"
                  role="tab"
                  aria-controls="pills-four"
                  aria-selected="false"
                >
                  4
                </button>
                <p>Profile Pic</p>
              </li>
            </ul>
          </div>

          <div className="tab-content p-4" id="pills-tabContent-Lable">
            {/* Aadhar Info */}
            <div
              className="tab-pane fade show active"
              id="pills-aadhar"
              role="tabpanel"
              aria-labelledby="pills-aadhar-tab"
            >
              <div className="col">
                <div
                  className="verify-onclick"
                  style={{ display: "block" }}
                  id="box1"
                >
                  <label
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                  >
                    Aadhar Card Number{" "}
                    <span
                      className={
                        verifyAadhaar.isError ? "text-danger" : "text-success"
                      }
                      onClick={() => {
                        if (
                          !(
                            // verifyAadhaar.isError ||
                            (verifyAadhaar.isSuccess || verifyAadhaar.isPending)
                          )
                        ) {
                          verifyAadhaar.mutate({
                            mobile: mobileNumber,
                            aadhar: getValues("aadhar"),
                          });
                        }
                      }}
                    >
                      {verifyAadhaar.isSuccess
                        ? "Verified"
                        : verifyAadhaar.isError
                        ? "Failed! Retry"
                        : verifyAadhaar.isPending
                        ? "Verifying.."
                        : "Verify"}
                    </span>
                  </label>
                  <input
                    type="text"
                    disabled={verifyAadhaar.isSuccess}
                    className={`form-control ${
                      errors.aadhar ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Aadhar Number"
                    {...register("aadhar", { required: true })}
                  />
                  {errors.aadhar && (
                    <div className="invalid-feedback">
                      Aadhar Card Number is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col">
                <label style={{ marginTop: 30 }}>Upload Id Proof </label>
                <div className="camera-content">
                  <div className="camera">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setAadharFront(e.target.files[0])}
                    />
                    <label htmlFor="aadharFrontInput" className="cursor">
                      {aadharFront ? (
                        <img
                          src={URL.createObjectURL(aadharFront)}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <>
                          <MdOutlinePhotoCamera style={{ fontSize: "36px" }} />
                          <p
                            style={{
                              color: "black",
                              fontWeight: 500,
                              fontSize: 14,
                            }}
                          >
                            Front
                          </p>
                        </>
                      )}
                    </label>
                  </div>

                  <div className="camera">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setAadharBack(e.target.files[0])}
                    />
                    <label htmlFor="aadharBackInput" className="cursor">
                      {aadharBack ? (
                        <img
                          src={URL.createObjectURL(aadharBack)}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <>
                          <MdOutlinePhotoCamera style={{ fontSize: "36px" }} />
                          <p
                            style={{
                              color: "black",
                              fontWeight: 500,
                              fontSize: 14,
                            }}
                          >
                            Back
                          </p>
                        </>
                      )}
                    </label>
                  </div>
                </div>
                {/* <div className="extra-margin-top">
                  <button
                    type="submit"
                    className="btn btn-primary btn-blcok width-change"
                  >
                    Continue
                  </button>
                </div> */}
              </div>
            </div>

            {/* Pan Info */}
            <div
              className="tab-pane fade"
              id="pills-Pan"
              role="tabpanel"
              aria-labelledby="pills-Pan-tab"
            >
              <div className="col">
                <label
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  PAN Card Number{" "}
                  <span
                    className={
                      verifyPan.isError ? "text-danger" : "text-success"
                    }
                    onClick={() => {
                      if (
                        !(
                          // verifyPan.isError ||
                          (verifyPan.isSuccess || verifyPan.isPending)
                        )
                      ) {
                        verifyPan.mutate({
                          mobile: mobileNumber,
                          pan: getValues("pan"),
                        });
                      }
                    }}
                  >
                    {verifyPan.isSuccess
                      ? "Verified"
                      : verifyPan.isError
                      ? "Failed! Retry"
                      : verifyAadhaar.isPending
                      ? "Verifying.."
                      : "Verify"}
                  </span>
                </label>
                <input
                  type="text"
                  disabled={verifyPan.isSuccess}
                  className={`form-control ${errors.pan ? "is-invalid" : ""}`}
                  placeholder="Enter Pan Card Number"
                  {...register("pan", { required: true })}
                />
                {errors.pan && (
                  <div className="invalid-feedback">
                    Pan Card Number is required
                  </div>
                )}
              </div>
              <div className="col">
                <label style={{ marginTop: 30 }}>Upload Id Proof</label>
                <div className="camera-content">
                  <div className="camera">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setPanImage(e.target.files[0])}
                    />
                    <label htmlFor="panFrontInput" className="cursor">
                      {panImage ? (
                        <img
                          src={URL.createObjectURL(panImage)}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        <>
                          <MdOutlinePhotoCamera style={{ fontSize: "36px" }} />
                          <p
                            style={{
                              color: "black",
                              fontWeight: 500,
                              fontSize: 14,
                            }}
                          >
                            Front
                          </p>
                        </>
                      )}
                    </label>
                  </div>
                </div>
                {/* <div className="extra-margin-top">
                  <button
                    type="button"
                    className="btn btn-primary btn-blcok width-change"
                  >
                    Continue
                  </button>
                </div> */}
              </div>
            </div>

            {/* Bank Info */}
            <div
              className="tab-pane fade"
              id="pills-Bank"
              role="tabpanel"
              aria-labelledby="pills-Bank-tab"
            >
              <div className="col">
                <label>Bank Name</label>
                <input
                  type="text"
                  className={`form-control ${errors.bank ? "is-invalid" : ""}`}
                  placeholder="Enter Bank Name"
                  {...register("bank", { required: true })}
                />
                {errors.bank && (
                  <div className="invalid-feedback">Bank Name is required</div>
                )}
              </div>
              <div className="col" style={{ marginTop: 20 }}>
                <label>Account Holder Name</label>
                <input
                  type="text"
                  value={verifyPan?.data?.name || ""}
                  className={`form-control`}
                  placeholder="Enter Account Holder Name"
                />
              </div>
              <div className="col" style={{ marginTop: 20 }}>
                <label>Account Number</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.account_number ? "is-invalid" : ""
                  }`}
                  placeholder="Enter Account Number"
                  {...register("account_number", { required: true })}
                />
                {errors.account_number && (
                  <div className="invalid-feedback">
                    Account Number is required
                  </div>
                )}
              </div>
              <div className="col" style={{ marginTop: 20 }}>
                <label>IFSC Code</label>
                <input
                  type="text"
                  className={`form-control ${errors.ifsc ? "is-invalid" : ""}`}
                  placeholder="Enter IFSC Code"
                  {...register("ifsc", { required: true })}
                />
                {errors.ifsc && (
                  <div className="invalid-feedback">IFSC Code is required</div>
                )}
              </div>
              {/* <div className="extra-margin-top">
                <button
                  type="button"
                  className="btn btn-primary btn-blcok width-change"
                >
                  Continue
                </button>
              </div> */}
            </div>

            {/* User Info */}
            <div
              className="tab-pane fade"
              id="pills-four"
              role="tabpanel"
              aria-labelledby="pills-four-tab"
            >
              <div className="profile-page-popup">
                <div className="profile-pik-popup">
                  <input
                    type="file"
                    onChange={(e) => setProfileImage(e.target.files[0])}
                    accept="image/*"
                    style={{ display: "none" }}
                    id="fileInput"
                  />
                  <label htmlFor="fileInput" className="cursor">
                    <img
                      src={
                        profileImage
                          ? URL.createObjectURL(profileImage)
                          : Profile
                      }
                      alt=""
                    />
                    <MdOutlinePhotoCamera style={{ fontSize: "36px" }} />
                  </label>
                  {errors.image && (
                    <div className="invalid-feedback">
                      Profile Image is required
                    </div>
                  )}
                </div>
                <div className="profile-popup-content">
                  <div className="form-row input-input input-bottom">
                    <div className="col">
                      <label>Name</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Your Name"
                        {...register("name", { required: true })}
                      />
                      {errors.name && (
                        <div className="invalid-feedback">Name is required</div>
                      )}
                    </div>
                    <div className="col">
                      <label>Email Address</label>
                      <input
                        type="text"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Email Address"
                        {...register("email", { required: true })}
                      />
                      {errors.email && (
                        <div className="invalid-feedback">
                          Email Address is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="extra-margin-top">
                    <button
                      type="submit"
                      className="btn btn-primary btn-blcok width-change"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default KYCModal;
