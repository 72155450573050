import useBetsAction from "../hooks/useBetsAction";
import { useUser } from "../constants/UserContext";
import { useEffect, useState } from "react";
import NotificationPopup from "./NotificationPopup";

const DeleteModal = ({ bets, refetch, setBetsToDelete }) => {
  const [successStatus, setSuccessStatus] = useState("");

  const { user } = useUser();

  const betsDelete = useBetsAction(refetch);

  const handleDelete = async() => {
    betsDelete.mutateAsync({ ...bets, deleted_by: `User` });
    setBetsToDelete({ bets: [] });
  };


  useEffect(() => {
    setSuccessStatus(
      betsDelete && betsDelete.isSuccess
        ? "success"
        : betsDelete && betsDelete.isError && "error"
    );
  }, [betsDelete]);

  return (
    <div
      className="modal fade"
      id={`deleteModal`}
      tabIndex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      {successStatus === "success" ? (
        <NotificationPopup message="Deleted Successfully." isSuccess />
      ) : (
        successStatus === "error" && (
          <NotificationPopup message="Failed to delete." />
        )
      )}

      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header header-sec">
            <h5 className="modal-title" id="deleteModalLabel">
              Confirm Deletion
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <h5>Are you sure you want to delete?</h5>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={handleDelete}
            >
              Ok
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
