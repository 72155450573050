// Header.js
import React, { useEffect, useState } from 'react';
import '../App.css';
import { Link, useLocation } from 'react-router-dom';
import { SlHome } from "react-icons/sl";
import { IoGameControllerOutline } from "react-icons/io5";
import { IoWalletOutline } from "react-icons/io5";
import { IoHelp } from "react-icons/io5";
import KYCModal from '../screens/KYCModal';
import { usePlayerData } from '../hooks/useHome';









function NavFooter() {

  const location = useLocation();




  return (


    <div className="container">
      <div className="nav-footer">
        <ul>
          <li className={location.pathname === '/HomePage' ? 'active-page' : ''}>
            <Link to="/HomePage"> <SlHome /> Home</Link>
          </li>
          <li className={location.pathname === '/AllGames' ? 'active-page' : ''}>
            <Link to="/AllGames"><IoGameControllerOutline /> Games</Link>
          </li>
          <li className={location.pathname === '/WalletAddAmount' ? 'active-page' : ''}>
            <Link to="/WalletAddAmount/home"> <IoWalletOutline /> Wallet</Link>
          </li>
          <li className={location.pathname === '/Help' ? 'active-page' : ''}>
            <Link to="/Help"> <IoHelp />  Help</Link>
          </li>
        </ul>
      </div>


    </div>


  );
}

export default NavFooter;
