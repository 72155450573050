import { useQuery } from "@tanstack/react-query";
import apiClient from "../constants/api-client";

const fetchRefundPolicy = () => apiClient.get(`refund-policy/`).then(res => res.data);

const useRefundPolicy = () => {
  const { data: RefundPolicy, error, isLoading, } = useQuery({
    queryKey: ['RefundPolicy'],
    queryFn: () => fetchRefundPolicy(),
  });

  return { RefundPolicy, error, isLoading, };
};

export default useRefundPolicy;
