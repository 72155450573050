import { useQuery } from "@tanstack/react-query";
import apiClient from "../constants/api-client";

const fetchTermsAndConditionLogin = () => apiClient.get(`terms-condition-bgm/`).then(res => res.data);

const useTermsAndConditionLogin = () => {
  const { data: TermsAndConditionLogin, error, isLoading } = useQuery({
    queryKey: ['TermsAndConditionLogin'],
    queryFn: () => fetchTermsAndConditionLogin(),
  });

  return { TermsAndConditionLogin, error, isLoading };
};

export default useTermsAndConditionLogin;
