import React from "react";
import apiClient from "../constants/api-client";
import { useQuery } from "@tanstack/react-query";
import { useUser } from "../constants/UserContext";

const fetchNotificationSeen = (mobileNumber) =>
  apiClient
    .get(`notification-seen/`, { params: { mobile: mobileNumber } })
    .then((res) => res.data);

const useNotificationSeen = () => {
  const { mobileNumber } = useUser();

  const {
    data: notificationSeen,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["Notification Seen"],
    queryFn: () => fetchNotificationSeen(mobileNumber),
  });

  return { notificationSeen, error, isLoading, refetch };
};

export default useNotificationSeen;

// export const useGameHostBubbleSeen = () => {
//   return useQuery ({
//     queryKey : ["GameHostBubble"],
//     queryFn: (mobileNumber) => apiClient.get(`game-post-count-player-bubble/`,(mobileNumber)).then((res) => res.data)
//   })
// };

// const fetchGameHostBubbleSeen = (mobileNumber) =>;

export const useGameHostBubbleSeen = () => {
  const { mobileNumber } = useUser();


  const {
    data: gameHostBubble,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["GameHostBubble Seen"],
    queryFn: () =>
      apiClient
        .get(`game-post-count-player-bubble/`, {
          params: { mobile: mobileNumber },
        })
        .then((res) => res.data),
  });

  return { gameHostBubble, error, isLoading, refetch };
};
