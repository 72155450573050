import axios from "axios";

// Create the axios instances
const apiClient = axios.create({
  // baseURL: "http://192.168.1.19:8001/club",
  baseURL: 'https://api.thebgmgame.com/club'
});

export const adminApiClient = axios.create({
  // baseURL: "http://192.168.1.19:8001/dashboard",
  baseURL: 'https://api.thebgmgame.com/dashboard'
});

export const imageApiClient =
  'https://api.thebgmgame.com';
// 'http://192.168.1.19:8001'

export const baseURL = 'https://api.thebgmgame.com/club'
export const upiGatewayKey = "d8500656-c125-4b0b-a82c-ad078f721996"
export const gatewayBaseUrl = 'https://api.ekqr.in'
export const upiGatewayUrl = {
  create_order: `/api/create_order`,
  check_order_status: `/api/check_order_status`,
}

// Add a request interceptor to set the Authorization header
const setAuthHeader = (config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['User-Type'] = 'Player';
    // config.headers['Content-Type'] = `application/json`;

  }
  return config;
};

apiClient.interceptors.request.use(setAuthHeader);
adminApiClient.interceptors.request.use(setAuthHeader);

// Add response interceptors to handle 401 status code
const handle401Error = (error) => {

  if (error.response && error.response.status === 401) {
    console.log('Unauthorized: 401 status code received');
    localStorage.removeItem('mobileNumber');
    localStorage.removeItem('authToken');
    window.location.reload()




  }
  return Promise.reject(error);
};

apiClient.interceptors.response.use(
  response => response,
  handle401Error
);

adminApiClient.interceptors.response.use(
  response => response,
  handle401Error
);

// Function to remove the token
export const removeAuthToken = () => {
  localStorage.removeItem('authToken');
};

// Export the default apiClient
export default apiClient;




