import { useMutation, useQuery } from "@tanstack/react-query";
import { useUser } from "../constants/UserContext";
import apiClient from "../constants/api-client";

const postMobileNumber = (data) =>
  apiClient.post("create-deposits/", data).then((res) => res.data);

const useAddPoints = () => {
  const { mobileNumber } = useUser();

  return useMutation({
    mutationKey: ["AddPoints"],
    mutationFn: (data) =>
      postMobileNumber({ mobile: mobileNumber, ...data }),
    onSuccess: (data) => console.log(data),
  });
};

export default useAddPoints;

export const useMidSetting = () => {
  const {
    data: midSettings,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["MidSetting"],
    queryFn: () => apiClient.get(`manual/`).then((res) => res.data),
  });

  return { midSettings, error, isLoading, refetch };
};





export const useMidSettingManualAccount = () => {
  const {
    data: midSettingsManualAccount,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["MidSettingManualAccount"],
    queryFn: () => apiClient.get(`manual-account/`).then((res) => res.data),
  });

  return { midSettingsManualAccount, error, isLoading, refetch };
};

export const useMidSettingManualMerchantAccount = () => {
  const {
    data: midSettingsManualMerchantAccount,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["MidSettingManualMerchantAccount"],
    queryFn: () => apiClient.get(`manual-merchant-id/`).then((res) => res.data),
  });

  return { midSettingsManualMerchantAccount, error, isLoading, refetch };
};


export const useMidSettingUpiGateway = () => {
  const {
    data: midSettingsUpiGateway,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["MidSettingUpiGateway"],
    queryFn: () => apiClient.get(`upi-gateway-id/`).then((res) => res.data),
  });

  return { midSettingsUpiGateway, error, isLoading, refetch };
};


export const useFrontSettings = () => {
  const {
    data: forntSettings,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["Front Settings"],
    queryFn: () =>
      apiClient.get(`front/`).then((res) => res.data),
  });

  return { forntSettings, error, isLoading, refetch };
};
