import React from "react";
import apiClient from "../constants/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useUser } from "../constants/UserContext";

const fetchGamePosting = () =>apiClient.get("game-post-list/").then((res) => res?.data);

const useGamePosting = () => {
  const {data: gamePosting ,error, isLoding, refetch} = useQuery({
    queryKey: ["GamePosting"],
    queryFn: () => fetchGamePosting(),
  });
  return { gamePosting, error, isLoding, refetch };
};

export default useGamePosting;

export const useGamePostSeen = () => {
return useMutation ({
  mutationKey : ["GamePostSeen"],
  mutationFn : (mobileNumber) => apiClient.post(`game-post-seen/` , { mobile : mobileNumber }).then((res) => res.data).catch(err => console.log(err)),
  
}) 
}
