import React from "react";

const Footer = () => {
  return (
    <div className="footer-ft">
      <div className="container-fluid">2023 © Thebgmgame.com</div>
    </div>
  );
};

export default Footer;
