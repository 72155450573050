import React, { useEffect, useRef, useState } from "react";
import InsufficientBalance from "./InsufficientBalance";
import useCopyPasteGame from "../hooks/useCopyPasteGame";
import usePlaceBet from "../hooks/usePlaceBet";
import useWallet from "../hooks/useWallet";
import NotificationPopup from "./NotificationPopup";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const CopyPasteGame = ({ market }) => {
  const [sufficientBalance, setSufficientBalance] = useState(true);
  const [showNoBalance, setShowNoBalance] = useState(!sufficientBalance);

  const [isPalti, setIsPalti] = useState(null);
  const [inputNumber, setInputNumber] = useState(null);
  const [points, setPoints] = useState(null);

  const [error, setError] = useState("");

  const {
    jodis,
    createPairsAndReverse,
    deleteJodi,
    calculatCopyPastePointsTotal,
  } = useCopyPasteGame();

  const { wallet } = useWallet();
  const placeBet = usePlaceBet();

  useEffect(() => {
    setSufficientBalance(
      wallet?.total_amount >= calculatCopyPastePointsTotal(points)
    );
  }, [wallet, calculatCopyPastePointsTotal(points)]);

  const navigateTo = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (placeBet.isSuccess) {
        navigateTo(`/AllGames`);
      }
    }, 1000);
  }, [placeBet]);

  const copyPasteBets = () =>
    jodis.map((jodi) => ({
      betKey: jodi.pair,
      points: jodi.points,
      betType: "Jodi",
      jodiType: "Copy Paste",
      market: market?.market,
    }));

  const handleAddCpJodis = () => {
    const isSuccess = createPairsAndReverse(inputNumber, points, isPalti);
    setError(isSuccess);
    inputNumber && points
      ? createPairsAndReverse(inputNumber, points, isPalti)
      : console.log("Invalid Inputs");
  };

  return (
    <>
      <div
        className="tab-pane fade"
        id="pills-copy-paste"
        role="tabpanel"
        aria-labelledby="pills-copy-paste-tab"
      >
        <div className="black-line"></div>

        <div className="point-count">
          <span>Points Remaining: {wallet?.total_amount}</span>
          <span>Points Added: {calculatCopyPastePointsTotal(points)}</span>
        </div>

        <form>
          <div className="form-row input-input input-bottom">
            <div className="col">
              <p>Number</p>
              <input
                type="number"
                maxLength={9}
                className="form-control"
                placeholder="Number"
                value={inputNumber}
                onChange={(e) => setInputNumber(e.target.value)}
              />
            </div>
          </div>
        </form>

        <div className="form-check form-check-inline radio-btn">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value="true"
            onClick={() => setIsPalti(true)}
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            With Palti
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            value="false"
            onClick={() => setIsPalti(false)}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            Without Palti
          </label>
        </div>

        <form>
          <div className="form-row input-input input-bottom">
            <div className="col">
              <p>Amount</p>
              <input
                type="number"
                className="form-control"
                placeholder="Number"
                value={points}
                onChange={(e) => setPoints(e.target.value)}
              />
            </div>
          </div>
        </form>

        {error && <NotificationPopup message={error} />}

        <div className="col">
          <button
            type="button"
            className="btn btn-primary btn-blcok copy-paste-add-btn mb-3"
            onClick={handleAddCpJodis}
          >
            Add
          </button>
        </div>

        {jodis.length !== 0 && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Number Type</th>
                <th scope="col">Number</th>
                <th scope="col">Points</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {jodis?.map((jodi) => (
                <tr>
                  <th>Jodi</th>
                  <td>{jodi.pair}</td>
                  <td>{jodi.points}</td>
                  <td>
                    <span
                      className="material-symbols-outlined"
                      onClick={() => deleteJodi(jodi)}
                    >
                      delete_forever
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <div className="black-line black-line-black"></div>

        {placeBet.isSuccess ? (
          <NotificationPopup isSuccess message={"Bet Placed Successfully!"} />
        ) : (
          placeBet.isError && (
            <NotificationPopup
              message={`Could not place bet..  ${
                placeBet.error?.response?.data?.error || ""
              }`}
            />
          )
        )}

        <div className="point-count">
          <span>Total Points</span>
          <span>{points * jodis.length}</span>
        </div>

        {jodis.length !== 0 && (
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                disabled={
                  placeBet.isPending || placeBet.isSuccess || jodis.length === 0
                }
                className="btn btn-primary btn-blcok width-change"
                onClick={() => {
                  return sufficientBalance
                    ? placeBet.mutate(copyPasteBets())
                    : setShowNoBalance(!showNoBalance);
                }}
              >
                Place Bet
              </button>
            </div>
          </div>
        )}

        <InsufficientBalance
          isShow={showNoBalance}
          showNow={setShowNoBalance}
        />
      </div>
    </>
  );
};

export default CopyPasteGame;
