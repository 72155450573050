// Header.js
import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";






function HeaderInner() {
  return (
 
      <div className="inner-header">
      <div className="container">
      
      <Link to="/" className="back-ic">
        <IoArrowBack  style={{ fontSize: '20px' }}/>
        Back
      </Link>

      </div>
      </div>
	
    
  );
}

export default HeaderInner;
