import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavFooter from "../components/NavFooter";
import HeaderMain from "../components/HeaderMain";
import search from "../images/search.png";
import useBonusReport, { useCreateBonus } from "../hooks/useBonusReport";
import ConvertTime from "../hooks/useConvertTime";
import useCreateBonu from "../hooks/useCreateBonus";
import { useForm } from "react-hook-form";
import { useUser } from "../constants/UserContext";
import PaginationController from "./Pagination";
import usePagination from "../hooks/usePagination";
import useMarkets from "../hooks/useMarkets";
import TopTitle from "../components/TopTitle";
import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import NotificationPopup from "./NotificationPopup";

export function separateDateAndTime(dateTimeString) {
  let datePart, timePart;
  if (dateTimeString?.includes("T")) {
    [datePart, timePart] = dateTimeString?.split("T");
  } else {
    [datePart, timePart] = dateTimeString?.split(" ");
  }
  // const [year, month, day] = datePart?.split("-");
  // const formattedDate = `${year}-${month}-${day}`;
  return { date: datePart, time: timePart };
}

function BonusReport() {
  const [market, setMarket] = useState(null);
  const [totalCommission, setTotalCommission] = useState(0);
  const [apiPopupMsg, setApiPopupMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');


  const { bonusReport, refetch } = useBonusReport({ market });
  const { markets } = useMarkets();

  const withdrawlBonus = useCreateBonu();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField
  } = useForm();
  const { mobileNumber } = useUser();

  useEffect(() => {
    if (bonusReport && bonusReport.length > 0) {
      const totalComm = bonusReport.reduce(
        (acc, item) => !(item.purpose === "Withdraw") ? acc + item.refer_amount : 0,
        0
      );
      setTotalCommission(totalComm);
    } else {
      setTotalCommission(0);
    }
  }, [bonusReport]);

  const onSubmit = async (data) => {
    await withdrawlBonus.mutate({ mobile: mobileNumber, amount: data.amount });



  };

  useEffect(() => {
    if (withdrawlBonus.isSuccess) {
      setApiPopupMsg(withdrawlBonus?.data?.data?.message)
      setTimeout(() => {
        window.location.reload();
      }, 2000)
    }
    else {
      setErrorMsg(withdrawlBonus?.error?.response?.data?.message)


    }

  }, [withdrawlBonus]);

  return (
    <>
      <HeaderMain pageTitle={<Link to="/">Home</Link>} />
      <TopTitle />

      <div className="top-heading-top">
        <h6>Bonus Report</h6>
      </div>
      <div className="container bonus-report-container">
        <p>Total Commission: {totalCommission}</p>
        <p>
          Remaining Commission:{" "}
          {bonusReport && bonusReport[0]?.closing_balance}
        </p>
        <h6 style={{ marginBottom: 15 }}>
          Enter Redeem Amount(Min - 50 And Max- 2000 Can Withdraw)
        </h6>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div class="form-row input-input input-bottom">
            <div class="col">
              <input
                type="number"
                class="form-control"
                placeholder="Enter Amount"
                {...register("amount", { required: true })}
              />
            </div>
            <button type="submit" class="btn btn-primary mt-3 mt-md-0">
              submit
            </button>
          </div>
        </form>
        <div className="btns-area">
          {/* <div className="btn-area-salect">
            <span>Select Game</span>
            <div className="select-area">
              <select
                className="form-select select-btn"
                aria-label="Default select example"
                value={market}
                onChange={(e) => setMarket(e.target.value)}
              >
                <option value>Select Game</option>
                {markets?.map((market) => (
                  <option key={market.market} value={market.market}>
                    {market.market}
                  </option>
                ))}
              </select>
            </div>
            <span>Entries</span>
          </div> */}
          {/* <form class="example">
            <input
              type="text"
              className="form-control"
              placeholder="Search.."
              name="search"
            />
            <button type="submit">
              <img src={search} alt="" />
            </button>
          </form> */}
        </div>
        <div className="table-responsive white-table">
          <table className="table table-green">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Bet Amount</th>
                <th scope="col">Commission Recieved</th>
                <th scope="col">Commission Redeemed</th>
                <th scope="col">Redeem Status</th>
              </tr>
            </thead>

            <BonusTableBody bonusReport={bonusReport} />
          </table>
        </div>
        {/* <div className="showing-btn">
          <div>
            <span>Showing 0 To 0 Of 0 Entries</span>
          </div>
          <div className="btns">
            <div className="previous">
              <button>Previous</button>
            </div>
            <div className="next">
              <button>Next</button>
            </div>
          </div>
        </div> */}
      </div>

      {apiPopupMsg && <NotificationPopup message={apiPopupMsg} isSuccess />}
      {errorMsg && <NotificationPopup message={errorMsg} isSuccess={false} />}
      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>
      <NavFooter />
      <Footer />
    </>
  );
}

const BonusTableBody = ({ bonusReport }) => {
  return (
    <tbody>
      {bonusReport && bonusReport?.length > 0 ? (
        bonusReport.map((item) => (
          <tr className="text-center">
            {/* <td>{item.created_at}</td> */}
            <td>
              {" "}
              ( Date:
              {` ${separateDateAndTime(item.created_at).date} ${ConvertTime(
                separateDateAndTime(item.created_at).time
              )}`}{" "}
              )
            </td>

            {/* <td>
              {" "}
              ( Date:
              {` ${separateDateAndTime(item.player_data.created_at).date} ${ConvertTime(
                separateDateAndTime(item.player_data.created_at).time
              )}`}{" "}
              )
            </td> */}
            <td>{!(item.purpose === "Withdraw") && item.bet_amount}</td>
            <td>{!(item.purpose === "Withdraw") && item.refer_amount}</td>
            <td>{item.purpose === "Withdraw" && item.refer_amount}</td>
            <td>
              <span
                className={`badge st-${String(
                  item.action
                ).toLowerCase()}`}
              >
                {item.action}
              </span>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <th scope="row" colSpan={5} className="text-center bg-light-sec">
            No Data Available.
          </th>
        </tr>
      )}
    </tbody>
  );
};

export default BonusReport;
