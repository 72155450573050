// Header.js
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NavFooter from "../components/NavFooter";
// import BabaJi from "../images/baba-ji.png";
import BabaJi from "../images/com-logo.svg";
import { Button, Modal } from "react-bootstrap";
import Profile from "../images/Profile-pik.png";
import { TfiClose } from "react-icons/tfi";
import { MdOutlinePhotoCamera } from "react-icons/md";
import React, { useEffect, useState } from "react";
import "../App.css";
import HeaderMain from "../components/HeaderMain";
import useAddPoints, {
  useFrontSettings,
  useMidSetting,
  useMidSettingManualAccount,
  useMidSettingManualMerchantAccount,
  useMidSettingUpiGateway,
} from "../hooks/useAddPoints";
import useWallet from "../hooks/useWallet";
import useWithdrawPoints from "../hooks/useWithdrawPoints";
import useWithdrawHistory from "../hooks/useWithdrawHistory";
import useTransactionHistory from "../hooks/useDepositHistory";
import NotificationPopup from "./NotificationPopup";
import KYCModal from "./KYCModal";
import { useGameSettings, usePlayerData } from "../hooks/useHome";
import { useUser } from "../constants/UserContext";
import ConvertTime from "../hooks/useConvertTime";
import TopTitle from "../components/TopTitle";
import Footer from "../components/Footer";
import Dropdown from 'react-bootstrap/Dropdown';
import { FiCopy } from 'react-icons/fi';
import moment from "moment/moment";
import { baseURL, gatewayBaseUrl, upiGatewayKey, upiGatewayUrl } from "../constants/api-client";
import axios from "axios";
import GooglePayIcon from '../images/gpay.png';
import PaytmIcon from '../images/paytm.png';
import PhonePeIcon from '../images/phone_pay.png';
import BhimUpiIcon from '../images/bhim_upi.png';
import { FaTimes } from 'react-icons/fa'; // Import cross icon from react-icons
import UpiGatewayPage from "./UpiGatewayPage ";



export function separateDateAndTime(dateTimeString) {
  let datePart, timePart;
  if (dateTimeString?.includes("T")) {
    [datePart, timePart] = dateTimeString?.split("T");
  } else {
    [datePart, timePart] = dateTimeString?.split(" ");
  }
  // const [year, month, day] = datePart?.split("-");
  // const formattedDate = `${year}-${month}-${day}`;
  return { date: datePart, time: timePart };
}

function WalletAddAmount() {
  const navigate = useNavigate();
  const location = useLocation();

  const { type, client_txn_id } = useParams()

  console.log(type)

  const [navWidth, setNavWidth] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showFirstDiv, setShowFirstDiv] = useState(true);
  const [showSecondDiv, setShowSecondDiv] = useState(false);
  const [showMyFirstDiv, setMyShowFirstDiv] = useState(true);
  const [showMySecondDiv, setMyShowSecondDiv] = useState(false);
  const [inputText, setInputText] = useState("");
  const [amountError, setAmountError] = useState("");
  const [showNewModal, setNewShowModal] = useState(false);
  const [manualAccountModel, setManualAccountModel] = useState(false);
  const [manualAccountText, setManualAccountText] = useState({ accountNumber: "", accountName: "", ifsc: "", admin: '', type: "", tranceId: '' });
  const [manualTrancId, setManualTrancId] = useState('');
  const [manualUpiBtn, setManualUpiBtn] = useState(false);
  const [manualAccountBtn, setManualAccountBtn] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImages, setSelectedImages] = useState([null, null]);
  // const NodePolyfillPlugin = require('node-polyfill-webpack-plugin');


  const [showUpiGateway, setShowUpiGateway] = useState(false);
  const [qrUrl, setQrUrl] = useState("");
  const [orderId, setOrderId] = useState("");
  const [paymentAppUrl, setPaymentAppUrl] = useState({ gpay: '', paytm: '', phonepe: '', bhimUpi: '' });

  const { midSettingsUpiGateway } = useMidSettingUpiGateway()
  const addPoints = useAddPoints();
  // const gameSetting = useGameSettings();
  const { mobileNumber } = useUser();
  const withdrawPoints = useWithdrawPoints();
  const { refetchWallet } = useWallet();
  const { midSettings } = useMidSetting();
  const { midSettingsManualAccount } = useMidSettingManualAccount()
  const { midSettingsManualMerchantAccount } = useMidSettingManualMerchantAccount()
  const { forntSettings } = useFrontSettings();
  const {
    data: transactions,
    isLoading,
    error,
    refetch,
  } = useTransactionHistory();
  const playerData = usePlayerData();

  // useEffect(() => {
  //   if (addPoints.isSuccess) {
  //     setNewShowModal(false);
  //   }
  //   else if (addPoints.isError) {


  //     setNewShowModal(false);
  //   }

  // }, [addPoints]);




  const copyToClipboard = async (text) => {
    if (navigator.clipboard && window.isSecureContext) {
      try {
        await navigator.clipboard.writeText(text);
        alert('Copied to clipboard');
      } catch (err) {
      }
    } else {
      try {
        // textAreaRef.current.select();
        document.execCommand('copy');
        alert('Copied to clipboard');
      } catch (err) {
      }
    };
  }


  useEffect(() => {

    forntSettings?.map((item) => {

      if (item.gateway == 'ManualUpi') {
        if (item.status) {
          setManualUpiBtn(true);
        }
        else {
          setManualUpiBtn(false);
        }

      }
      else if (item.gateway == 'ManualAccount') {
        if (item.status) {
          setManualAccountBtn(true);
        }
        else {
          setManualAccountBtn(false);
        }
      }
    })

  }, [forntSettings]);



  let isRedirected = false;

  useEffect(() => {
    playerData.mutate({ mobile: mobileNumber });
  }, []);

  const handleVisibilityChange = async (merchant_id, tid) => {
    const matchingAccount = findMatchingAccount(midSettings, inputText);
    console.log("data :- ", matchingAccount)
    setManualAccountText({ type: matchingAccount.type })
    if (document.visibilityState === "visible" && isRedirected) {
      await addPoints.mutateAsync({
        admin: merchant_id.admin,
        merchant_transaction_id: merchant_id.merchant_id,
        amount: parseInt(inputText),
        transaction_id: tid,
        gateway: matchingAccount.type,
      }).catch((error) => {
        console.log("addPoints.error :- ", error)
        setManualAccountModel(false)
        setNewShowModal(false);
      });
      //document.removeEventListener("visibilitychange", handleVisibilityChange);
      // window.location.reload();
    }
  };


  const handleAddPoints = async (url) => {

    try {
      if (!inputText) {
        <NotificationPopup message="Add Point!" />;
        return; // Exit the function if inputText is empty
      }

      const minAmount = forntSettings?.min_deposit;
      const maxAmount = forntSettings?.max_deposit;

      const amountToAdd = parseInt(inputText);

      if (amountToAdd < minAmount) {
        setNewShowModal(false);
        setAmountError("Amount too low!");
        return;
      } else if (amountToAdd > maxAmount) {
        setNewShowModal(false);
        setAmountError("Amount too High!");
        return;
      }

      const merchant_ids = midSettings?.filter(
        (mid) =>
          amountToAdd >= mid.min_deposit && amountToAdd <= mid.max_deposit
      );

      const random = Math.floor(Math.random() * merchant_ids?.length);

      const tid = Date.now();

      // Set isRedirected to true before redirecting
      isRedirected = true;

      document.addEventListener("visibilitychange", () =>
        handleVisibilityChange(merchant_ids[random], tid)
      );

      const upiUrl = `${url}pay/?ver=${`01`}&mode=${`15`}&am=${inputText}&mam=${inputText}&cu=INR&pa=${merchant_ids[random].merchant_id
        }&pn=${merchant_ids[random].company
        }&mc=${`5816`}&tr=${Date.now()}&tn=${Date.now()}&tid=${tid}`;

      window.location.href = upiUrl;

      // Consider where to put refetchWallet based on your application logic
      setInputText("");
      setNewShowModal(false);
      refetch();
    } catch (error) {
      // console.error("Error adding points:", error);
    }
  };

  useEffect(() => {
    // Clear the error message when inputText changes
    setAmountError('');
  }, [inputText]);


  const findMatchingAccount = (accounts, amount) => {

    const minDeposit = Math.min(...accounts.map(account => account.min_deposit));
    const maxDeposit = Math.max(...accounts.map(account => account.max_deposit));

    if (amount < minDeposit) {
      // setAmountError('The amount is lower than the minimum deposit');
      // setNewShowModal(false);
      return null;
    } else if (amount > maxDeposit) {
      // setAmountError('The amount is higher than the maximum deposit');
      // setNewShowModal(false);
      return null;
    } else {
      return accounts.find(account => amount >= account.min_deposit && amount <= account.max_deposit) || null;
    }
  };

  const onAddManualAccountClick = async () => {
    try {
      if (!inputText) {
        <NotificationPopup message="Add Point!" />;
        return; // Exit the function if inputText is empty
      }

      const amount = parseFloat(inputText); // Ensure inputText is a number
      // Clear previous errors

      const matchingAccount = findMatchingAccount(midSettingsManualAccount, amount);
      if (matchingAccount) {
        setManualAccountText({
          accountName: matchingAccount.account_holder_name,
          accountNumber: matchingAccount.account_number,
          ifsc: matchingAccount.ifsc,
          admin: matchingAccount.admin,
          type: matchingAccount.type
        });
        setManualAccountModel(true);
        setNewShowModal(false);
      } else {
        setManualAccountText({
          accountName: '',
          accountNumber: '',
          ifsc: '',
          admin: null,
          type: ''
        });
        setManualAccountModel(false);
        setNewShowModal(false);
      }
    } catch (error) {
      console.error("Error adding points:", error);
    }
  };

  const onSubmitManual = async () => {
    setAmountError('');
    console.log(manualTrancId)

    if (manualTrancId) {
      await addPoints.mutateAsync({
        amount: inputText,
        admin: manualAccountText.admin,
        transaction_id: manualTrancId,
        account_number: manualAccountText.accountNumber,
        account_holder_name: manualAccountText.type == 'ManualMerchantId' ? '' : manualAccountText.accountName,
        ifsc: manualAccountText.ifsc,
        gateway: manualAccountText.type,
        merchant_transaction_id: manualAccountText.type == 'ManualMerchantId' ? manualAccountText.tranceId : '',

      }).catch((error) => {
        setManualAccountModel(false)
        setNewShowModal(false);
        setManualTrancId('')
      });
      setManualAccountModel(false)
      setNewShowModal(false);
      setManualTrancId('')
      document.removeEventListener("visibilitychange", onSubmitManual);
      // window.location.reload();

    }
    else {
      setAmountError("Please enter UTR ID");
    }




  }




  const handleWithdrawPoints = async () => {
    try {
      await withdrawPoints.mutateAsync(parseInt(inputText));
      refetchWallet();
      setInputText("");
      refetch();
    } catch (error) {
      // console.error("Error withdrawing points:", error);
    }
  };

  const openNav = () => {
    setNavWidth(300);
  };

  const closeNav = () => {
    setNavWidth(0);
  };

  // const [showModal, setShowModal] = useState(false);

  // const handleOpen = () => {
  //   setShowModal(true);
  // };

  // const handleClose = () => {
  //   setShowModal(false);
  // };


  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleVerifyClick = () => {
    setShowFirstDiv(false);
    setShowSecondDiv(true);
  };

  const handleMyVerifyClick = () => {
    setMyShowFirstDiv(false);
    setMyShowSecondDiv(true);
  };

  const handleNewOpen = () => {

    if (inputText) {
      const amount = inputText // Assuming inputText is a string and needs to be converted to a number
      let matchFound = false;
      let amountInRange = false;

      for (let index = 0; index < forntSettings.length; index++) {
        const item = forntSettings[index];
        if (item.status == true) {
          if (amount >= item.min_deposit && amount <= item.max_deposit) {
            amountInRange = true;
            if (item.gateway == 'ManualUpi') {
              const matchingAccount = midSettings.find(midItem =>
                midItem.type == 'ManualUpi' &&
                midItem.status == true &&
                amount >= midItem.min_deposit &&
                amount <= midItem.max_deposit
              );

              if (matchingAccount) {
                handleAddPoints("upi://");
                matchFound = true;
                break; // Exit the loop if a match is found
              }
            }
            else if (item.gateway == 'ManualAccount') {
              const matchingAccount = midSettingsManualAccount.find(midItem =>
                midItem.type == 'ManualAccount' &&
                midItem.status == true &&
                amount >= midItem.min_deposit &&
                amount <= midItem.max_deposit
              );

              if (matchingAccount) {
                setManualAccountText({
                  accountName: matchingAccount.account_holder_name,
                  accountNumber: matchingAccount.account_number,
                  ifsc: matchingAccount.ifsc,
                  admin: matchingAccount.admin,
                  type: matchingAccount.type
                });
                setManualAccountModel(true);
                matchFound = true;
                break; // Exit the loop if a match is found
              }
            }
            else if (item.gateway == 'ManualMerchantId') {
              console.log('midSettingsManualMerchantAccount :- ', midSettingsManualMerchantAccount)
              const matchingAccount = midSettingsManualMerchantAccount.find(midItem =>
                midItem.type == 'ManualMerchantId' &&
                midItem.status == true &&
                amount >= midItem.min_deposit &&
                amount <= midItem.max_deposit
              );

              if (matchingAccount) {

                console.log(" matchingAccount.merchant_id :- ", matchingAccount.merchant_id)
                setManualAccountText({
                  accountName: matchingAccount.company,
                  tranceId: matchingAccount.merchant_id,
                  admin: matchingAccount.admin,
                  type: matchingAccount.type
                });
                setManualAccountModel(true);
                matchFound = true;
                break; // Exit the loop if a match is found
              }
            }
            else if (item.gateway == 'UpiGateway') {
              const matchingAccount = midSettingsUpiGateway.find(midItem =>
                midItem.type == 'UpiGateway' &&
                midItem.status == true &&
                amount >= midItem.min_deposit &&
                amount <= midItem.max_deposit
              );



              if (matchingAccount) {

                onUpiGatewayCreate()
                matchFound = true;
                break; // Exit the loop if a match is found
              }
            }
          }
        }
      }

      if (!amountInRange) {
        setAmountError("Amount is out of the allowed range");
      }
      else if (!matchFound) {
        setAmountError("Please try after some time");
      }
    } else {
      setAmountError("Please enter amount");
    }
  };


  const onUpiGatewayCreate = (adminType) => {





    if (playerData?.data?.email) {




      const timestamp = Date.now();
      const random_number = Math.floor(Math.random() * 1000000);
      const transaction_id = `${timestamp}-${random_number}`;

      // const params = JSON.stringify({
      //   key: upiGatewayKey,
      //   client_txn_id: transaction_id,
      //   amount: inputText,
      //   p_info: 'Deposit',
      //   customer_name: playerData?.data?.name,
      //   customer_email: playerData?.data?.email,
      //   customer_mobile: playerData?.data?.mobile,
      //   redirect_url: "https://www.thebgmgame.com/WalletAddAmount/payment",
      // })




      const params = JSON.stringify({
        transaction_id: transaction_id,
        amount: inputText,
        name: playerData?.data?.name,
        email: playerData?.data?.email,
        mobile: playerData?.data?.mobile,
      })






      axios.post(`${baseURL}/create-payment-gateway/`, params, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.data.status === true) {
            // Handle successful response here
            console.log('Order created successfully:', res.data);
            const data = res.data.data
            setPaymentAppUrl({
              gpay: data.upi_intent.gpay_link,
              paytm: data.upi_intent.paytm_link,
              bhimUpi: data.upi_intent.bhim_link,
              phonepe: data.upi_intent.phonepe_link,
            })
            setOrderId(transaction_id)
            setQrUrl(data.payment_url)
            window.location.href = data.payment_url
            console.log(data.payment_url)
            setShowUpiGateway(true)
            //checkPaymentStatus(transaction_id)




          } else {
            setAmountError(res.data.msg);
          }
        })
        .catch((err) => {
          console.error('Error creating order:', err);
          return
          console.error('Response data:', err.response.data); // Log the response data to get more information
          setAmountError("Failed to create order. Please try again.");
        });
    } else {
      setAmountError("Please add your email in edit profile");
    }
  };




  useEffect(() => {
    if (type == 'payment') {
      const params = new URLSearchParams(location.search);
      const clientTxnId = params.get('client_txn_id');
      const txnId = params.get('txn_id');
      console.log('Client Transaction ID:', clientTxnId);
      const amount = inputText
      const matchingAccount = midSettingsUpiGateway?.find(midItem =>
        midItem.type == 'UpiGateway' &&
        midItem.status == true &&
        amount >= midItem.min_deposit &&
        amount <= midItem.max_deposit
      );
      if (clientTxnId) {

        // You can now use clientTxnId in your component
        checkPaymentStatus(clientTxnId, matchingAccount?.admin)
      }

    }

  }, [location]);


  const checkPaymentStatus = (order_id, adminType) => {

    const params = JSON.stringify({
      "transaction_id": order_id,
      "date": moment().format('DD-MM-YYYY')//"01-08-2024"
    })

    var headers = {
      'Content-Type': 'application/json',
    }

    axios.post(`${baseURL}/status-check-upi-gateway/`, params, { headers })
      .then((res) => {
        if (res.data.status === true) {
          // Handle successful response here
          console.log('Order created successfully:', res.data);
          const data = res.data
          if (data.data.status == 'success') {




            addPoints.mutateAsync({
              admin: adminType,
              amount: res.data.data.amount,
              transaction_id: order_id,
              gateway: 'UpiGateway',
            }).catch((error) => {
              console.log("addPoints.error :- ", error)
              setManualAccountModel(false)
              setNewShowModal(false);
            });

          }
          else if (data.data.status == 'Failure') {
            setOrderId('')
            setQrUrl('')
            setShowUpiGateway(false)
            setAmountError(res.data.msg);
          }


        }
        else {
          // setAmountError(res.data.msg);
        }
      })
      .catch((err) => {
        console.error('Error creating order:', err);
        return
        console.error('Response data:', err.response.data); // Log the response data to get more information
        setAmountError("Failed to create order. Please try again.");
      });
  }


  useEffect(() => {
    if (addPoints.isSuccess) {
      window.location.replace("https://www.thebgmgame.com/WalletAddAmount/home");
    }
  }, [addPoints])


  const handleCloseUpiGateway = () => {
    setShowUpiGateway(false)
    setOrderId('')
    setQrUrl('')
    setShowUpiGateway(false)
  }


  const addPointFromUpiGateway = async (order_id, data) => {




  }



  const handleNewClose = () => {
    setNewShowModal(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleImageChange = (event, index) => {
    const image = event.target.files[0];

    // Create a copy of the array to avoid directly modifying state
    const newSelectedImages = [...selectedImages];
    newSelectedImages[index] = image;

    setSelectedImages(newSelectedImages);
  };


  const redirectToApp = (app) => {
    const url = app == 'gpay' ? paymentAppUrl.gpay : app == 'phonepe' ? paymentAppUrl.phonepe : app == 'bhimUpi' ? paymentAppUrl.bhimUpi : app == 'paytm' ? paymentAppUrl.paytm : '';
    if (url) {
      window.location.href = url;
    } else {
      console.error('No URL found for the selected app');
    }
  };


  const onChangeText = (text) => {
    setInputText(text)
  }

  return (
    <>

      <HeaderMain pageTitle={"Wallet"} />

      <TopTitle />

      <div className="tabs-container">
        <div className="tabs tabs-green-tabs">
          <div className="container ">
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Add Point
                </button>
              </li>
              <li class="nav-item right-border" role="presentation">
                <button
                  class="nav-link "
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Withdraw
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="container ">
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="add-pont-container">
                <span>Add Point</span>

                <div class="col" style={{ marginTop: 10 }}>
                  <input
                    type="number"
                    value={inputText}
                    onChange={(e) => onChangeText(e.target.value)}
                    placeholder="Enter Point"
                    class="form-control"
                  />
                </div>

                <div className="payment-btn">
                  <button onClick={() => setInputText("500")}>500</button>
                  <button onClick={() => setInputText("1000")}>1000</button>
                  <button onClick={() => setInputText("1500")}>1500</button>
                </div>

                <div className="payment-btn">
                  <button onClick={() => setInputText("2000")}>2000</button>
                  <button onClick={() => setInputText("2500")}>2500</button>
                  <button onClick={() => setInputText("3000")}>3000</button>
                </div>

                <div className="offer-of-any">
                  <span>आपका पैसा 5 से 10 मिनट मैं एड हो जाएगा</span>
                </div>
                {addPoints.isError && (
                  <NotificationPopup
                    message={`Could not process payment..  ${addPoints.error?.response?.data?.error || ""
                      }`}
                  />
                )}
                {addPoints.isSuccess && (
                  <NotificationPopup message={`Deposit Succesful`} isSuccess />
                )}

                {amountError && <NotificationPopup message={amountError} />}

                <div className="points-btn">
                  <a
                    // href={`upi://pay?pn=TheBGMgame;pa=${gameSetting?.data?.game_setting_data?.merchant_id};cu=INR;am=${inputText}`}
                    class="btn btn-primary btn-blcok"
                    // onClick={() => setSubmit(true)}
                    // onClick={() => handleAddPoints()}
                    //onClick={handleNewOpen}
                    onClick={() =>
                      playerData.isSuccess && playerData?.data?.kyc
                        ? handleNewOpen()
                        : handleShow()
                    }
                  >
                    Add Points
                  </a>

                  {/* <a href="upi://pay?ver=01&amp;mode=15&amp;am=500.00&amp;mam=500.00&amp;cu=INR&amp;pa=skywalk.endeavourgaming@timecosmos&amp;pn=ENDEAVOUR+GAMING+SOLUTION+PRIVATE+LIMITED&amp;mc=5816&amp;tr=SKYWA782445103339&amp;tn=782445103339&amp;mid=SKYWA0700&amp;msid=ENDEA-3435&amp;mtid=SKYWA-0700">
                    <button id="clickButton" class="rounded bg-none">
                      all
                    </button>
                  </a> */}

                  {/* <button type="button" class="btn btn-primary btn-blcok" onClick={handleNewOpen}>Transfer Point</button> */}
                </div>

                {/* <div className="row">
                  <div className="col">
                    <img
                      src="https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=https%3A%2F%2Fphon.pe%2Fyour-phonepe-id-here"
                      alt="PhonePe QR Code"
                    />
                  </div>
                  <div className="col"></div>
                </div> */}

                {/* --------Pop up start------- */}
                <div>

                  <Modal show={showNewModal} onHide={handleNewClose} centered>
                    <div className="transfar-heding">
                      Welcome to The BGM Game
                    </div>
                    <Modal.Header closeButton>
                      <div
                        className="custom-close-button"
                        onClick={handleNewClose}
                      >
                        <TfiClose />
                      </div>
                    </Modal.Header>

                    <Modal.Body className="p-0">
                      <div className="add-point-profile">
                        <img src={BabaJi} alt="" />
                      </div>



                      <div className="add-pont-title mt-0">
                        नीचे दिए हुए आइकॉन पे क्लिक करे ( ANDROID USER) 👇
                      </div>

                      <div className="p-3">
                        <div class="row" style={{ justifyContent: 'center' }}>

                          {manualUpiBtn &&
                            <div className="col-md-6 text-center">
                              <button
                                type="button"
                                class="btn btn-primary w-100 mt-3 mt-md-0"
                                onClick={() => handleAddPoints("upi://")}
                              >
                                Manual UPI
                              </button>
                            </div>
                          }


                          {manualAccountBtn &&
                            <div className="col-md-6 text-center">
                              <button
                                type="button"
                                class="btn btn-primary w-100 mt-3 mt-md-0"
                                onClick={() => {

                                  onAddManualAccountClick()
                                }}
                              >
                                Manual Account
                              </button>

                            </div>
                          }



                        </div>
                      </div>



                      <div className="add-pont-title mt-0">
                        नीचे दिए हुए आइकॉन पे क्लिक करे ( IPHONE USER) 👇
                      </div>

                      <div className="p-3">
                        <div class="row">
                          <div className=" col-md-4 text-center">
                            <button
                              type="button"
                              class="btn btn-primary w-100 mt-3 mt-md-0"
                              onClick={() => handleAddPoints("upi://")}
                            >
                              PAYTM
                            </button>
                          </div>
                          <div className=" col-md-4 text-center">
                            <button
                              type="button"
                              class="btn btn-primary w-100 mt-3 mt-md-0"
                              onClick={() => handleAddPoints("gpay://upi/")}
                            >
                              Gpay
                            </button>
                          </div>

                          <div className=" col-md-4 text-center">
                            <button
                              type="button"
                              class="btn btn-primary w-100 mt-3 mt-md-0"
                              onClick={() => handleAddPoints("upi://")}
                            >
                              PhonePe
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="add-pont-title mt-0">
                        भुगतान करने के बाद बैक बटन दबाये 👇
                      </div>

                      <div className="text-center p-3">
                        <button
                          type="submit"
                          className="btn Btn-change w-100"
                          onClick={handleNewClose}
                        >
                          Back
                        </button>
                      </div>
                    </Modal.Body>
                  </Modal>




                  <Modal
                    show={manualAccountModel}
                    onHide={() => setManualAccountModel(false)}
                    centered
                  >
                    <div className="transfar-heding">Welcome to The BGM Game</div>
                    <Modal.Header closeButton>
                      <div
                        className="custom-close-button"
                        onClick={() => setManualAccountModel(false)}
                      >
                        <TfiClose />
                      </div>
                    </Modal.Header>
                    <Modal.Body>
                      {amountError && <NotificationPopup message={amountError} />}

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              {manualAccountText.type === 'ManualAccount' ? 'Account Holder Name' : 'UPI Name'}
                            </label>
                            <div style={{ position: 'relative' }}>
                              <input
                                type="text"
                                value={manualAccountText.accountName}
                                className="form-control"
                                disabled
                                style={{ paddingRight: '30px' }} // Adjust padding to make space for the icon
                              />
                              <FiCopy
                                style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: '#15842f' }}
                                onClick={() => copyToClipboard(manualAccountText.accountName)}
                              />
                            </div>
                          </div>
                        </div>

                        {manualAccountText.type === 'ManualMerchantId' && (
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                UPI ID
                              </label>
                              <div style={{ position: 'relative' }}>
                                <input
                                  type="text"
                                  value={manualAccountText.tranceId}
                                  className="form-control"
                                  disabled
                                  style={{ paddingRight: '30px' }} // Adjust padding to make space for the icon
                                />
                                <FiCopy
                                  style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: '#15842f' }}
                                  onClick={() => copyToClipboard(manualAccountText.tranceId)}
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {manualAccountText.type === 'ManualAccount' && (
                          <>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                  Account Number
                                </label>
                                <div style={{ position: 'relative' }}>
                                  <input
                                    type="number"
                                    value={manualAccountText.accountNumber}
                                    className="form-control"
                                    disabled
                                    style={{ paddingRight: '30px' }} // Adjust padding to make space for the icon
                                  />
                                  <FiCopy
                                    style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: '#15842f' }}
                                    onClick={() => copyToClipboard(manualAccountText.accountNumber)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <label htmlFor="exampleFormControlInput1" className="form-label">
                                  IFSC
                                </label>
                                <div style={{ position: 'relative' }}>
                                  <input
                                    type="text"
                                    value={manualAccountText.ifsc}
                                    className="form-control"
                                    disabled
                                    style={{ paddingRight: '30px' }} // Adjust padding to make space for the icon
                                  />
                                  <FiCopy
                                    style={{ cursor: 'pointer', position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', color: '#15842f' }}
                                    onClick={() => copyToClipboard(manualAccountText.ifsc)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-md-8">
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="UTR ID"
                              className="form-control"
                              onChange={(e) => setManualTrancId(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="col-md-4 text-center">
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={onSubmitManual}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>



                </div>

                {/* --------Pop up end---------- */}

                <div className="offer-of-any">
                  <span>Wallet History</span>
                </div>

                <div className="table-responsive white-table">
                  <table class="table table-green">
                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Pay Mode</th>
                        <th scope="col">Transaction Id</th>
                        <th scope="col">Date</th>
                        <th scope="col">Points</th>
                        <th scope="col">Closing Balance</th>
                        <th scope="col">Deposit by</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <th
                            scope="row"
                            colSpan={7}
                            className="text-center bg-light-sec"
                          >
                            Loading..
                          </th>
                        </tr>
                      ) : error ? (
                        <tr>
                          <th
                            scope="row"
                            colSpan={7}
                            className="text-center bg-light-sec"
                          >
                            No Data Available Or Something Went Wrong.
                          </th>
                        </tr>
                      ) : (
                        transactions
                          ?.filter(
                            (transaction) =>
                              transaction.type === "Credit" &&
                              transaction.action !== "Pending"
                          )
                          .map((history, index) => (
                            <tr
                              key={index}
                              className="text-center bg-light-sec"
                            >
                              <th>{index + 1}</th>
                              <td>{history.purpose}</td>
                              <td>{history.transaction_id}</td>
                              <td>
                                ( Date:
                                {`${moment(history.created_at, "DD-MM-YYYY hh:mm:ss A").format('DD-MM-YYYY hh:mm A')}`}
                                )
                              </td>

                              <td>{history.amount}</td>
                              <td>{history.closing_balance || 0}</td>
                              <td>{history.remark}</td>
                              <td>
                                <span
                                  className={`badge st-${String(
                                    history.action
                                  ).toLowerCase()}`}
                                >
                                  {history.action}
                                </span>
                              </td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="add-pont-container">
                <span>Withdraw Point</span>

                <div class="col" style={{ marginTop: 10 }}>
                  <input
                    type="number"
                    value={inputText}
                    onChange={(e) => onChangeText(e.target.value)}
                    placeholder="Enter Point"
                    class="form-control"
                  />
                </div>

                <div className="payment-btn">
                  <button onClick={() => setInputText("500")}>500</button>
                  <button onClick={() => setInputText("1000")}>1000</button>
                  <button onClick={() => setInputText("1500")}>1500</button>
                </div>

                <div className="payment-btn">
                  <button onClick={() => setInputText("2000")}>2000</button>
                  <button onClick={() => setInputText("2500")}>2500</button>
                  <button onClick={() => setInputText("3000")}>3000</button>
                </div>

                <div className="offer-of-any">
                  {/* <span>आपका पैसा 5 से 10 मिनट मैं एड हो </span> */}
                </div>

                {withdrawPoints.error && (
                  <NotificationPopup
                    message={`Could not process payment..  ${withdrawPoints.error?.response?.data?.error || ""
                      }`}
                  />
                )}

                <div className="points-btn">
                  <button
                    type="button"
                    disabled={
                      withdrawPoints.isPending || withdrawPoints.isSuccess
                    }
                    class="btn btn-primary btn-blcok "
                    variant="primary"
                    onClick={() =>
                      playerData.isSuccess && playerData?.data?.kyc
                        ? handleWithdrawPoints()
                        : handleShow()
                    }
                  // onClick={() =>  handleWithdrawPoints() }
                  >
                    Withdrawal
                  </button>
                </div>

                <div className="offer-of-any">
                  <span>Wallet History</span>
                </div>

                <div className="table-responsive white-table">
                  <table class="table table-green">
                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Pay Mode</th>
                        <th scope="col">Date</th>
                        <th scope="col">Points</th>
                        <th scope="col">Closing Balance</th>
                        <th scope="col">Withdraw By</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <th
                            scope="row"
                            colSpan={7}
                            className="text-center bg-light-sec"
                          >
                            Loading..
                          </th>
                        </tr>
                      ) : error ? (
                        <tr>
                          <th
                            scope="row"
                            colSpan={7}
                            className="text-center bg-light-sec"
                          >
                            No Data Available Or Something Went Wrong.
                          </th>
                        </tr>
                      ) : (
                        transactions
                          ?.filter(
                            (transaction) => transaction.type === "Debit"
                          )
                          .map((history, index) => (
                            <tr
                              key={index}
                              className="text-center bg-light-sec"
                            >
                              <th>{index + 1}</th>
                              <td>{history.purpose}</td>
                              <td>
                                {/* ( Date:
                                {` ${separateDateAndTime(history.created_at).date
                                  } ${ConvertTime(
                                    separateDateAndTime(history.created_at).time
                                  )}`}
                                ) */}
                                {`(Date: ${history.created_at})`}
                              </td>

                              <td>{history.amount}</td>
                              <td>{history.closing_balance || 0}</td>
                              <td>{history.remark}</td>

                              <td>
                                <span
                                  className={`badge st-${String(
                                    history.action
                                  ).toLowerCase()}`}
                                >
                                  {history.action}
                                </span>
                              </td>
                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* --------Pop up start------- */}
      <KYCModal showModal={showModal} handleClose={handleClose} />
      {/* --------Pop up end---------- */}

      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />
      <Footer />

    </>
  );
}

export default WalletAddAmount;
