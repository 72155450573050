import { useQuery } from "@tanstack/react-query";
import { useUser } from "../constants/UserContext";
import { adminApiClient } from "../constants/api-client";

const useTransactionHistory = () => {
  const { mobileNumber } = useUser();

  return useQuery({
    queryKey: ['Transaction History'],
    queryFn: () => adminApiClient.get('transaction-list/', { params: { mobile: mobileNumber } }).then(res => res.data)
  })
};


export default useTransactionHistory; 