// src/pages/UpiGatewayPage.js

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import GooglePayIcon from '../images/gpay.png';
import PaytmIcon from '../images/paytm.png';
import PhonePeIcon from '../images/phone_pay.png';
import BhimUpiIcon from '../images/bhim_upi.png';

const UpiGatewayPage = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const { qrUrl, appUrls } = location.state || {};

  // Function to handle the close action
  const handleClose = () => {
    // if (handleCloseUpiGateway) {
    //   handleCloseUpiGateway();
    // }
    navigate(-1); // Navigate back to the previous page
  };


  const redirectToApp = (app) => {
    const url = app == 'gpay' ? appUrls.gpay_link : app == 'phonepe' ? appUrls.phonepe_link : app == 'bhimUpi' ? appUrls.bhim_link : app == 'paytm' ? appUrls.paytm_link : '';
    if (url) {
      window.location.href = url;
    } else {
      console.error('No URL found for the selected app');
    }
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <div style={{ position: 'relative', marginBottom: '20px' }}>
        <h1 style={{ margin: '0 auto' }}>Pay via UPI</h1>
        {/* <button
          onClick={handleClose}
          style={{ position: 'absolute', right: '15px', top: '15px', fontSize: '24px', color: '#148323', border: 'none', background: 'none' }}
        >
          <FaTimes />
        </button> */}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
        {/* QR Code Display Using qrcode.react */}

        {/* Payment Icons */}
        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <div style={{ margin: '10px', textAlign: 'center' }}>
            <img
              src={GooglePayIcon}
              alt="Google Pay"
              width="40px"
              onClick={() => redirectToApp('gpay')}
              style={{ cursor: 'pointer' }}
            />
            <div>Google Pay</div>
          </div>
          <div style={{ margin: '10px', textAlign: 'center' }}>
            <img
              src={PaytmIcon}
              alt="Paytm"
              width="40px"
              onClick={() => redirectToApp('paytm')}
              style={{ cursor: 'pointer' }}
            />
            <div>Paytm</div>
          </div>
          <div style={{ margin: '10px', textAlign: 'center' }}>
            <img
              src={PhonePeIcon}
              alt="PhonePe"
              width="40px"
              onClick={() => redirectToApp('phonepe')}
              style={{ cursor: 'pointer' }}
            />
            <div>PhonePe</div>
          </div>
          <div style={{ margin: '10px', textAlign: 'center' }}>
            <img
              src={BhimUpiIcon}
              alt="BHIM UPI"
              width="40px"
              onClick={() => redirectToApp('bhimUpi')}
              style={{ cursor: 'pointer' }}
            />
            <div>BHIM UPI</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpiGatewayPage;
