import React from "react";
import useWallet from "../hooks/useWallet";

const TopTitle = () => {
  const { wallet } = useWallet();
  return (
    <div className="top-title">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-3 text-start">
            Win Point : <span>{wallet?.winning}</span>
          </div>
          <div className="col-6 bgm-game-title">Welcome To BGM Game</div>
          <div className="col-3 text-end">
            Total Point : <span>{wallet?.total_amount}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopTitle;
