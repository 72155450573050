import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "./screens/HomePage";
import AllGames from "./screens/AllGames";
import PlayingGame from "./screens/PlayingGame";
import AppDetails from "./screens/AppDetails";
import MyPalyHistory from "./screens/MyPlayHistory";
import GamePosting from "./screens/GamePosting";
import BonusReport from "./screens/BonusReport";
import DecemberMonthResults from "./screens/DecemberMonthResults";
import ResultHistory from "./screens/ResultHistory";
import TermsAndCondition from "./screens/TermsAndCondition";
import WalletAddAmount from "./screens/WalletAddAmount";
import Help from "./screens/Help";
import Notification from "./screens/Notification";
import EditProfile from "./screens/EditProfile";
import LoginPage from "./screens/LoginPage";
import DepositChat from "./screens/ChatScreen";
import WithdrawChat from "./screens/WithdrawChat";
import GamePlaySuccessfully from "./components/GamePlaySuccesfully";
import Chat from "./screens/chatBoat";
import { useUser } from "./constants/UserContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ChatScreen from "./screens/ChatScreen";
import ChatScreenWithdraw from "./screens/ChatScreenWithdraw";
import Report from "./screens/Report";
import RefundPolicy from "./screens/RefundPolicy";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsAndConditions from "./screens/TermansAndCondition";
import UpiGatewayPage from "./screens/UpiGatewayPage ";

function App() {
  const { mobileNumber } = useUser();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // console.log = function no_console() { };
    }

  }, [])

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={mobileNumber ? <Navigate to="/HomePage" /> : <LoginPage />}
        />
        <Route path="/login/:referCode?" element={<LoginPage />} />
        <Route
          path="withdraw-chat-screen/"
          element={
            mobileNumber ? <ChatScreenWithdraw /> : <Navigate to="/login" />
          }
        />
        <Route
          path="chat-screen/"
          element={mobileNumber ? <ChatScreen /> : <Navigate to="/login" />}
        />
        <Route
          path="HomePage"
          element={mobileNumber ? <HomePage /> : <Navigate to="/login" />}
        />
        <Route
          path="AllGames"
          element={mobileNumber ? <AllGames /> : <Navigate to="/login" />}
        />
        <Route
          path="PlayingGame/:marketId"
          element={mobileNumber ? <PlayingGame /> : <Navigate to="/login" />}
        />
        <Route
          path="AppDetails"
          element={mobileNumber ? <AppDetails /> : <Navigate to="/login" />}
        />
        <Route
          path="MyPlayHistroy"
          element={mobileNumber ? <MyPalyHistory /> : <Navigate to="/login" />}
        />
        <Route
          path="GamePosting"
          element={mobileNumber ? <GamePosting /> : <Navigate to="/login" />}
        />
        <Route
          path="BonusReport"
          element={mobileNumber ? <BonusReport /> : <Navigate to="/login" />}
        />
        <Route
          path="DecemberMonthResults"
          element={
            mobileNumber ? <DecemberMonthResults /> : <Navigate to="/login" />
          }
        />
        <Route
          path="ResultHistory"
          element={mobileNumber ? <ResultHistory /> : <Navigate to="/login" />}
        />
        <Route
          path="TermsAndCondition"
          element={
            mobileNumber ? <TermsAndCondition /> : <Navigate to="/login" />
          }
        />
        <Route
          path="WalletAddAmount/:type"
          element={
            mobileNumber ? <WalletAddAmount /> : <Navigate to="/login" />
          }
        />
        <Route
          path="Help"
          element={mobileNumber ? <Help /> : <Navigate to="/login" />}
        />
        <Route
          path="Notification"
          element={mobileNumber ? <Notification /> : <Navigate to="/login" />}
        />
        <Route
          path="EditProfile"
          element={mobileNumber ? <EditProfile /> : <Navigate to="/login" />}
        />
        <Route
          path="DepositChat"
          element={mobileNumber ? <DepositChat /> : <Navigate to="/login" />}
        />
        <Route
          path="WithdrawChat"
          element={mobileNumber ? <WithdrawChat /> : <Navigate to="/login" />}
        />
        <Route
          path="Chat"
          element={mobileNumber ? <Chat /> : <Navigate to="/login" />}
        />
        <Route
          path="GamePlaySuccessfully"
          element={
            mobileNumber ? <GamePlaySuccessfully /> : <Navigate to="/login" />
          }
        />
        <Route
          path="Report" element=
          {<Report />}
        >
        </Route>
        <Route
          path="RefundPolicy" element=
          {<RefundPolicy />}
        >
        </Route>
        <Route
          path="PrivacyPolicy" element=
          {<PrivacyPolicy />}
        >
        </Route>
        <Route
          path="TermsAndConditions" element=
          {<TermsAndConditions />}
        >
        </Route>
        <Route
          path="UpiGatewayPage" element=
          {<UpiGatewayPage />}
        >
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
