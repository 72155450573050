import { useQuery } from "@tanstack/react-query";
import apiClient from "../constants/api-client";

const fetchPrivacyPolicy = () => apiClient.get(`privacy-policy/`).then(res => res.data);

const usePrivacyPolicy = () => {
  const { data: PrivacyPolicy, error, isLoading, } = useQuery({
    queryKey: ['PrivacyPolicy'],
    queryFn: () => fetchPrivacyPolicy(),
  });

  return { PrivacyPolicy,error, isLoading, };
};

export default usePrivacyPolicy;
