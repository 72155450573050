import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDepositChat, useWithdrawChat } from "../hooks/useChat";
import { Link, useParams } from "react-router-dom";
import Notification from "./Notification";
import TableSkeleton from "./TableSkeleton";
import ConvertTime from "../hooks/useConvertTime";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { imageApiClient } from "../constants/api-client";
import { useUser } from "../constants/UserContext";
import { separateDateAndTime } from "./ChatScreen";
import { IoArrowBack } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChatScreenWithdraw = () => {
  const { mobileNumber } = useUser();
  const { register, handleSubmit, reset } = useForm();
  const [messages, setMessages] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const messagesEndRef = useRef(null);

  // const [fileSelected, setFileSelected] = useState(false);
  const { chatScreen, sendMessage } = useWithdrawChat({
    user: mobileNumber,
  });

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;

    const ogg = new Blob([blob], { type: "audio/ogg; codecs=opus" });

    setAudioFile(ogg);
    document.getElementById("audioRec").appendChild(audio);
  };

  useEffect(() => {
    if (chatScreen && chatScreen.data) {
      setMessages(chatScreen.data);
    }
  }, [chatScreen]);

  const onSubmit = async (data) => {
    if (!data.text && !audioFile && !selectedFile) {
      // setError("message", {
      //   type: "manual",
      //   message: "Please enter a message, select an image, or record an audio.",
      // });
      toast.error(
        "Please enter a message, select an image, or record an audio."
      );
      return;
    }

    const formData = new FormData();
    formData.append("message_by", "User");
    formData.append("mobile", mobileNumber);
    formData.append("text", data.text);
    formData.append("seen", false);
    if (audioFile) {
      formData.append("audio", audioFile);
    }
    if (selectedFile) {
      console.log("s;kflsaflsafl", selectedFile);

      formData.append("file", selectedFile, selectedFile.name); // Ensure filename is included
    }
    await sendMessage.mutateAsync(formData);
    chatScreen.refetch();
    reset();
    setSelectedFile(null);

    setAudioFile(null);
  };

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]); // Update selected file state
  };

  const handleRemoveFile = () => {
    setSelectedFile(null); // Clear selected file state
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ animation: false });
    }
  }, [messages]);

  const renderMessages = () => {
    return (
      messages &&
      messages.map((message, index) => (
        <div
          key={index}
          style={{
            padding: "5px",
            textAlign: message.message_by === "Admin" ? "left" : "right",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              backgroundColor:
                message.message_by === "Admin"
                  ? "rgb(54, 158, 58)"
                  : "rgb(236, 236, 236)",
              color: message.message_by === "Admin" ? "white" : "black",
              padding: "15px",
              borderRadius: "10px",
              display: "inline-block",
              boxShadow:
                "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
              textAlign: message.message_by === "Admin" ? "left" : "right",
            }}
          >
            {message.file && (
              <div>
                {(message.file.endsWith(".png") ||
                  message.file.endsWith(".jpg") ||
                  message.file.endsWith(".jpeg") ||
                  message.file.endsWith(".gif")) && (
                  <img
                    src={imageApiClient + message.file}
                    alt="File Attachment"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                )}
                {(message.file.endsWith(".mp4") ||
                  message.file.endsWith(".webm") ||
                  message.file.endsWith(".ogg")) && (
                  <video
                    controls
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  >
                    <source
                      src={imageApiClient + message.file}
                      type="video/mp4"
                    />
                    <source
                      src={imageApiClient + message.file}
                      type="video/webm"
                    />
                    <source
                      src={imageApiClient + message.file}
                      type="video/ogg"
                    />
                    Your browser does not support the video element.
                  </video>
                )}
              </div>
            )}

            {message.audio && (
              <audio controls>
                <source src={imageApiClient + message.audio} type="audio/ogg" />
                Your browser does not support the audio element.
              </audio>
            )}

            {message.text && <p>{message.text}</p>}
            <div
              style={{
                fontSize: "10px",
                color: message.message_by === "Admin" ? "white" : "gray",
              }}
            >{`${separateDateAndTime(message?.created_at).date} ${ConvertTime(
              separateDateAndTime(message?.created_at).time
            )}`}</div>
          </div>
        </div>
      ))
    );
  };

  return (
    <>
      <ToastContainer />

      <div id="chat-screen-css">
        <div className="add-pont-title diposit-chat-title">
          <div className="container">
            <Link to="/HomePage" className="back-ic">
              <IoArrowBack style={{ fontSize: "20px" }} />
              <span>Back</span>
            </Link>
            Withdraw Chat
          </div>
        </div>

        <div className="deposit-content scroll-container">
          <div className="container">
            <div>
              {chatScreen.isLoading ? (
                <p>Loading...</p>
              ) : // <TableSkeleton />
              chatScreen.isError ? (
                <Notification message={`Failed to load chats.`} />
              ) : (
                <>
                  {renderMessages()}
                  <div ref={messagesEndRef} />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="deposit-chat-message">
          {selectedFile && (
            <div className="container">
              <div className="imgsend-block">
                {selectedFile.type.startsWith("image/") && (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="File Attachment"
                  />
                )}
                {selectedFile.type.startsWith("video/") && (
                  <video controls>
                    <source
                      src={URL.createObjectURL(selectedFile)}
                      type={selectedFile.type}
                    />
                    Your browser does not support the video element.
                  </video>
                )}
                <button type="button" onClick={handleRemoveFile}>
                  X
                </button>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-changes container">
              <div className="col input-changes-btn deposit-message">
                <AudioRecorder
                  onRecordingComplete={addAudioElement}
                  audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                  }}
                  // downloadOnSavePress={true}
                  downloadFileExtension="webm"
                />
              </div>
              <div className="col input-changes-btn deposit-message">
                <label htmlFor="fileInput">
                  <span
                    className="material-symbols-outlined"
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      marginRight: "10px",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    attach_file
                  </span>
                </label>
                <input
                  type="file"
                  {...register("file")}
                  id="fileInput"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <div id="audioRec"></div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Type Message"}
                  {...register("text")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
                <button type="submit" className="btn btn-primary">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChatScreenWithdraw;
