import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDepositChat } from "../hooks/useChat";
import { Link } from "react-router-dom";
import Notification from "./Notification";
import ConvertTime from "../hooks/useConvertTime";
import { AudioRecorder } from "react-audio-voice-recorder";
import { imageApiClient } from "../constants/api-client";
import { useUser } from "../constants/UserContext";
import { IoArrowBack } from "react-icons/io5";
import { usePlayerData } from "../hooks/useHome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function separateDateAndTime(dateTimeString) {
  let datePart, timePart;
  if (dateTimeString.includes("T")) {
    [datePart, timePart] = dateTimeString.split("T");
  } else {
    [datePart, timePart] = dateTimeString.split(" ");
  }
  const [year, month, day] = datePart.split("-");
  const formattedDate = `${day}-${month}-${year}`;
  return { date: formattedDate, time: timePart };
}

const ChatScreen = () => {
  const { mobileNumber } = useUser();
  const playerInfo = usePlayerData();
  const { register, handleSubmit, reset } = useForm();
  const [messages, setMessages] = useState(null);
  const [playerData, setPlayerData] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  // const [fileSelected, setFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const { chatScreen, sendMessage } = useDepositChat({ user: mobileNumber });
  const messagesEndRef = useRef(null);

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    console.log("Url", url);
    console.log("blob", blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;

    const ogg = new Blob([blob], { type: "audio/ogg; codecs=opus" });
    console.log("hello ", ogg);

    setAudioFile(ogg);
    document.getElementById("audioRec").appendChild(audio);
  };

  useEffect(() => {
    if (chatScreen && chatScreen.data) {
      setMessages(chatScreen.data);
    }
  }, [chatScreen]);

  useEffect(() => {
    playerInfo.mutate({ mobile: mobileNumber });
  }, []);
  useEffect(() => {
    playerInfo.isSuccess && setPlayerData(playerInfo.data);
  }, [playerInfo]);

  const onSubmit = async (data) => {
    if (!data.text && !audioFile && !selectedFile) {
      // setError("message", {
      //   type: "manual",
      //   message: "Please enter a message, select an image, or record an audio.",
      // });
      toast.error(
        "Please enter a message, select an image, or record an audio."
      );
      return;
    }

    const formData = new FormData();
    formData.append("message_by", "User");
    formData.append("seen", false);
    formData.append("name", playerData?.name || " ");
    formData.append("mobile", mobileNumber);
    formData.append("text", data.text);
    if (audioFile) {
      console.log("hello");
      console.log("hii", audioFile);
      formData.append("audio", audioFile);
    }
    if (selectedFile) {
      console.log("s;kflsaflsafl", selectedFile);

      formData.append("file", selectedFile, selectedFile.name); // Ensure filename is included
    }
    await sendMessage.mutateAsync(formData);
    chatScreen.refetch();
    reset();
    setSelectedFile(null);
    setAudioFile(null);
  };

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]); // Update selected file state
  };

  const handleRemoveFile = () => {
    setSelectedFile(null); // Clear selected file state
  };

  const renderMessages = () => {
    return (
      messages &&
      messages.map((message, index) => (
        <div
          key={index}
          style={{
            padding: "5px",
            textAlign: message.message_by === "Admin" ? "left" : "right",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              backgroundColor:
                message.message_by === "Admin"
                  ? "rgb(54, 158, 58)"
                  : "rgb(236, 236, 236)",
              color: message.message_by === "Admin" ? "white" : "black",
              padding: "15px",
              borderRadius: "10px",
              display: "inline-block",
              boxShadow:
                "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
              textAlign: message.message_by === "Admin" ? "left" : "right",
            }}
          >
            {message.file && (
              <div>
                {(message.file.endsWith(".png") ||
                  message.file.endsWith(".jpg") ||
                  message.file.endsWith(".jpeg") ||
                  message.file.endsWith(".gif")) && (
                  <img
                    src={imageApiClient + message.file}
                    alt="File Attachment"
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  />
                )}
                {(message.file.endsWith(".mp4") ||
                  message.file.endsWith(".webm") ||
                  message.file.endsWith(".ogg")) && (
                  <video
                    controls
                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                  >
                    <source
                      src={imageApiClient + message.file}
                      type="video/mp4"
                    />
                    <source
                      src={imageApiClient + message.file}
                      type="video/webm"
                    />
                    <source
                      src={imageApiClient + message.file}
                      type="video/ogg"
                    />
                    Your browser does not support the video element.
                  </video>
                )}
              </div>
            )}

            {message.audio && (
              <audio controls>
                <source src={imageApiClient + message.audio} type="audio/ogg" />
                Your browser does not support the audio element.
              </audio>
            )}

            {message.text && <p>{message.text}</p>}
            <div
              style={{
                fontSize: "10px",
                color: message.message_by === "Admin" ? "white" : "gray",
              }}
            >{`${separateDateAndTime(message?.created_at).date} ${ConvertTime(
              separateDateAndTime(message?.created_at).time
            )}`}</div>
          </div>
        </div>
      ))
    );
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ animation: false });
    }
  }, [messages]);

  return (
    <>
      <ToastContainer />
      <div id="chat-screen-css">
        <div className="add-pont-title diposit-chat-title">
          <div className="container">
            <Link to="/HomePage" className="back-ic">
              <IoArrowBack style={{ fontSize: "20px" }} />
              <span>Back</span>
            </Link>
            Deposit Chat
          </div>
        </div>

        <div className="deposit-content scroll-container">
          <div className="container ">
            <div className="chat-sc">
              {chatScreen.isLoading ? (
                <p>Loading...</p>
              ) : // <TableSkeleton />
              chatScreen.isError ? (
                <Notification message={`Failed to load chats.`} />
              ) : (
                <>
                  {renderMessages()}
                  <div ref={messagesEndRef} />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="deposit-chat-message">
          {selectedFile && (
            <div className="container">
              <div className="imgsend-block">
                {selectedFile.type.startsWith("image/") && (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="File Attachment"
                  />
                )}
                {selectedFile.type.startsWith("video/") && (
                  <video controls>
                    <source
                      src={URL.createObjectURL(selectedFile)}
                      type={selectedFile.type}
                    />
                    Your browser does not support the video element.
                  </video>
                )}
                <button type="button" onClick={handleRemoveFile}>
                  X
                </button>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-changes container">
              <div className="col input-changes-btn deposit-message">
                <AudioRecorder
                  onRecordingComplete={addAudioElement}
                  audioTrackConstraints={{
                    noiseSuppression: true,
                    echoCancellation: true,
                  }}
                  // downloadOnSavePress={true}
                  downloadFileExtension="webm"
                />
              </div>
              <div className="col input-changes-btn deposit-message">
                <label htmlFor="fileInput">
                  <span
                    className="material-symbols-outlined"
                    style={{
                      display: "flex",
                      cursor: "pointer",
                      marginRight: "10px",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    attach_file
                  </span>
                </label>
                <input
                  type="file"
                  {...register("file")}
                  id="fileInput"
                  onChange={handleFileChange}
                  // onChange={() => setFileSelected(!fileSelected)}
                  style={{ display: "none" }}
                />
                <div id="audioRec"></div>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Type Message"}
                  {...register("text")}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
                <button type="submit" className="btn btn-primary">
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChatScreen;
