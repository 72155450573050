import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useUser } from "../constants/UserContext";
import apiClient from "../constants/api-client";
import { queryClient } from "..";

const fetchWalletAmount = (mobileNumber) => apiClient.get(`player-wallet/${mobileNumber}/`).then(res => res.data);

const useWallet = () => {
  const { mobileNumber } = useUser();

  const { data: wallet, error, isLoading } = useQuery({
    queryKey: ['walletAmount', mobileNumber],
    queryFn: () => fetchWalletAmount(mobileNumber),
  });

  const refetchWallet = () => {
    queryClient.invalidateQueries(['walletAmount', mobileNumber]);
  };

  return { wallet, error, isLoading, refetchWallet };
};

export default useWallet;
