import { useQuery } from "@tanstack/react-query";
import { useUser } from "../constants/UserContext";
import apiClient, { adminApiClient } from "../constants/api-client";

const postMobileNumber = (params) =>
  adminApiClient
    .get("bet-details/", { params })
    .then((res) => res.data);

const useMyPlayHistory = ({ market = "",date = "" } = {}) => {
  const { mobileNumber } = useUser();

  const params = {
    ...(market && {market}),
    ...(date && { date }),
    mobile: mobileNumber 
  };

  const {
    data: myPlayHistory,
    error,
    isLoding,
    refetch
  } = useQuery({
    queryKey: ["MyPlayHistory", params],
    queryFn: () => postMobileNumber(params),
  });
  return { myPlayHistory, error, isLoding, refetch };
};

export default useMyPlayHistory;
