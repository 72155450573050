import { useQuery } from "@tanstack/react-query";
import  { adminApiClient } from "../constants/api-client";
import { useUser } from "../constants/UserContext";

const fetchSpinUser = (mobileNumber) => adminApiClient.get(`player-refered-list/`, { params: { mobile: mobileNumber } }).then(res => res.data);

const useSpinUser = () => {
  const { mobileNumber } = useUser();
  
  const { data: spinUser, error, isLoading ,refetch } = useQuery({
    queryKey: [' spin user '],
    queryFn: () => fetchSpinUser(mobileNumber),
  });

  return { spinUser, error, isLoading, refetch }
};

export default useSpinUser

