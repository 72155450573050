import { useMutation } from "@tanstack/react-query";
import { useUser } from "../constants/UserContext";
import apiClient from "../constants/api-client";

const postBetInfo = (betInfo) => {console.log(betInfo); return apiClient.post('create-bet/', betInfo)}

const usePlaceBet = () => {
  const { mobileNumber } = useUser();

  return useMutation({
    mutationKey: ['Place Bet'],
    mutationFn: (bets) => postBetInfo({ "mobile": mobileNumber, "bets": bets }),
    onSuccess: (data) => console.log(data),
  })
}

export default usePlaceBet