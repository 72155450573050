// Header.js
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavFooter from "../components/NavFooter";
import HeaderMain from "../components/HeaderMain";
import BabaJi from "../images/baba-ji.png";
import useMyPlayHistory from "../hooks/useMyPlayHistory";
import useMarkets from "../hooks/useMarkets";
import useCountdownTimer from "../hooks/useCountdownTimer";
import { separateDateAndTime } from "./ChatScreen";
import ConvertTime from "../hooks/useConvertTime";
import useBetsAction from "../hooks/useBetsAction";
import DeleteModal from "./DeleteModal";
import { useUser } from "../constants/UserContext";
import TopTitle from "../components/TopTitle";
import Footer from "../components/Footer";

export function getTodayDate() {
  const today = new Date();
  let month = String(today.getMonth() + 1);
  let day = String(today.getDate());
  const year = today.getFullYear();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return [year, month, day].join("-");
}

function MyPalyHistory() {
  const [date, setDate] = useState("");
  const [betsToDelete, setBetsToDelete] = useState({ bets: [] });
  const { markets } = useMarkets();
  const { id } = useUser();
  const navigate = useNavigate();
  const [market, setMarket] = useState("");
  const { myPlayHistory, isLoading, refetch } = useMyPlayHistory({
    market,
    date,
  });

  const { timeRemaining, setTargetTime } = useCountdownTimer();

  const isDeletable = (createdAt) => {
    const createTime = new Date(createdAt);
    const currentTime = new Date();
    const difference = (currentTime - createTime) / (1000 * 60);
    return difference <= 5;
  };

  return (
    <>
      <HeaderMain pageTitle={<Link to="/">Home</Link>} />

      <TopTitle />

      <div className="top-heading-top">
        <h6>History</h6>
      </div>

      <div className="container play-history-container">
        <div className="game-inputs">
          <div className="left-content-area">
            <div className="select-area">
              <select
                className="form-select select-btn"
                aria-label="Default select example"
                value={market}
                onChange={(e) => setMarket(e.target.value)}
              >
                <option value>Select Game</option>
                {markets?.map((market) => (
                  <option key={market.market} value={market.market}>
                    {market.market}
                  </option>
                ))}
              </select>
            </div>

            <div className="date-area">
              <div class="form-group select-input">
                <input
                  type="date"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="Select Date"
                  placeholder="Select Date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="btn-area-center">
            <div className=" refresh-btn ">
              <button
                type="button"
                class="btn btn-primary btn-blcok"
                onClick={() => navigate(0)}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>

        <div className="table-responsive white-table">
          <table class="table table-green">
            <thead>
              <tr>
                <th scope="col">S. No.</th>
                <th scope="col">Date</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Number</th>
                <th scope="col">Points</th>
                <th scope="col">Win Amount</th>
                <th scope="col">TDS</th>
                <th scope="col">Earned</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {myPlayHistory?.length !== 0 ? (
                myPlayHistory?.map((history, index) => (
                  <tr key={index} className={history.winAmount > 0 ? "text-center bg-cl-ch" : "text-center bg-light-sec"}>
                    <th>{index + 1}</th>
                    <td>{`${separateDateAndTime(history.createdAt).date
                      } ${ConvertTime(
                        separateDateAndTime(history.createdAt).time
                      )}`}</td>
                    <td>{history.market}</td>
                    <td>{history.harrafType || history.betType}</td>
                    <td>{history.betKey}</td>
                    <td>{history.betAmount}</td>
                    <td>{history.winAmount}</td>
                    <td>{history.tds}</td>
                    <td>{history.totalWinAmount}</td>
                    {/* <td className={history.winAmount > 0 ? "badge st-success" : ""}>{history.winAmount}</td> */}



                    <td
                      data-bs-toggle={
                        isDeletable(history.createdAt) ? "modal" : ""
                      }
                      data-bs-target={
                        isDeletable(history.createdAt) ? `#deleteModal` : ""
                      }
                      onClick={() => {
                        if (isDeletable(history.createdAt)) {
                          setBetsToDelete((prevBets) => ({
                            ...prevBets,
                            bets: [
                              ...prevBets.bets,
                              {
                                id: history.id,
                                points: history.betAmount,
                                bet_key: history.betKey,
                              },
                            ],
                          }));
                        }
                      }}
                    >
                      {isDeletable(history.createdAt) && history.declare == 'undeclared' ? (
                        <i className="material-symbols-outlined">delete</i>
                      ) : (
                        <span>Not Deletable</span>
                      )}
                    </td>
                    <DeleteModal
                      id={`deleteModal${index}`}
                      bets={betsToDelete}
                      refetch={refetch}
                      setBetsToDelete={setBetsToDelete}
                    />
                  </tr>
                ))
              ) : isLoading ? (
                <tr>
                  <th
                    scope="row"
                    colSpan={8}
                    className="text-center bg-light-sec"
                  >
                    Loading..
                  </th>
                </tr>
              ) : (
                <tr>
                  <th
                    scope="row"
                    colSpan={8}
                    className="text-center bg-light-sec"
                  >
                    No Data Available.
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />
      <Footer />
    </>
  );
}

export default MyPalyHistory;
