// Header.js
import React, { useEffect, useState } from "react";
import "../App.css";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaBell } from "react-icons/fa";
import Background from "../images/background-profile.png";
import BabaJi from "../images/baba-ji.png";
import Phone from "../images/smartphone-call.png";
import swords from "../images/swords.png";
import socialMedia from "../images/social-media.png";
import Bonus from "../images/bonus.png";
import economy from "../images/economy.png";
import TermsAndCondition from "../images/terms-and-conditions.png";
import rating from "../images/rating.png";
import Exit from "../images/exit.png";
import instagram from "../images/instagram.png";
import facebook from "../images/facebook.png";
import chat from "../images/chat-icon.png";
import { TfiClose } from "react-icons/tfi";
import NavFooter from "../components/NavFooter";
import HeaderMain from "../components/HeaderMain";
import useMarkets from "../hooks/useMarkets";
import { isTimeNotPassed } from "./HomePage";
import TopTitle from "../components/TopTitle";
import Footer from "../components/Footer";
import KYCModal from "./KYCModal";
import { usePlayerData } from "../hooks/useHome";
import { useUser } from "../constants/UserContext";

function AllGames() {
  const { markets } = useMarkets();
  const navigateTo = useNavigate();
  const playerData = usePlayerData();
  const { mobileNumber } = useUser();



  // useEffect(() => { console.log(markets) }, [markets]);

  const [navWidth, setNavWidth] = useState(0);


  const openNav = () => {
    setNavWidth(300);
  };

  const closeNav = () => {
    setNavWidth(0);
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };


  const onPlay = (id) => {
    if (playerData?.data?.kyc == true) {
      navigateTo(`/PlayingGame/${id}`)
    }
    else {
      handleOpen()
    }
  }

  useEffect(() => {
    playerData.mutate({ mobile: mobileNumber });
  }, []);


  return (
    <div>
      <HeaderMain pageTitle={"Games"} />

      {/* ----------Content section start --------- */}

      <TopTitle />

      <div className="container all-games-container">
        <div className="top-content">
          <span>All Game</span>
        </div>

        <div className="market-section content-header">
          <span>Market Name</span>
          <span>Action</span>
        </div>

        {markets?.map(
          (market) =>
            market.status && (
              <div key={market.id} className="market-value">
                {isTimeNotPassed(market.close_time) &&
                  !isTimeNotPassed(market.open_time) ? (
                  <>
                    <h6>{market.market}</h6>
                    {/* <Link onP to={`/PlayingGame/${market.id}`}> */}
                    <button onClick={() =>
                      playerData.isSuccess && playerData?.data?.kyc
                        ? onPlay(market.id)
                        : handleOpen()
                    } type="button" className="btn btn-primary btn-set">
                      {"Play Game"}
                    </button>
                    {/* </Link> */}
                  </>
                ) : (
                  <>
                    <h6>{market.market}</h6>
                    <button type="button" className="btn Btn-change btn-set">
                      Time Out
                    </button>
                  </>
                )}
              </div>
            )
        )}
      </div>

      {/* ----------Content section end --------- */}

      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <KYCModal showModal={showModal} handleClose={handleClose} />


      <NavFooter />
      <Footer />
    </div>
  );
}

export default AllGames;
