// Header.js
import React, { useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { Modal } from 'react-bootstrap';
import BabaJi from '../images/baba-ji.png'
import { TfiClose } from "react-icons/tfi";
import { IoCheckmark } from "react-icons/io5";  






function GamePlaySuccesfully() {
    const [showNewModal, setNewShowModal] = useState(false);

    const handleNewOpen = () => {
      setNewShowModal(true);
    };
  
    const handleNewClose = () => {
      setNewShowModal(false);
    };


    const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  return (
 <>

<div className="points-btn">
     <button type="button" class="btn btn-primary btn-blcok">Add Points</button>
     <button type="button" class="btn btn-primary btn-blcok" onClick={handleNewOpen}>Transfer Point</button>
     </div>
 
  <div>


<Modal show={showNewModal} onHide={handleNewClose} centered>
{/* <div className="transfar-heding">
Transfer
</div> */}

<div class="modal-header game-play-header">
<div className="custom-close-button" onClick={handleNewClose}>
<TfiClose />
</div>
      </div>

<div class="modal-body game-play-body">
<div className="gamePlayContainer">

<div className="circle">
<IoCheckmark />
</div>
  <p>Game Played Successfully <br /> Check in history</p>

  <div className="gameplay-content">
      <div className="gameplay">
        <p>Market : </p>
        <span>Morning Star</span>
      </div>

      <div className="gameplay">
        <p>Game : </p>
        <span>Jodi</span>
      </div>
  </div>

  



</div>
      </div>

  <div class="modal-footer game-play-footer">
  <div class="text-center button-width">
  <button type="button" class="btn btn-primary">Print</button>
</div>
      </div>
</Modal>
</div> 


 
 
 
 
 
 
 </>
      
	
    
  );
}

export default GamePlaySuccesfully;