import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";

const NotificationPopup = ({
  message = "",
  isSuccess = false,
  noReload = false,
}) => {
  const [show, setShow] = useState(true);

  // Function to handle close
  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => setShow(false), isSuccess ? 3000 : 5000);
    return () => clearTimeout(timer);
  }, []);

  setTimeout(() => {
    show && setShow(false);
    // !noReload && window.location.reload();
  }, 3000);

  // CSS styles for modal and backdrop
  const modalStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // backgroundColor: "#fff",
    zIndex: 1000,
    // padding: "20px",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
  };

  const backdropStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: 999,
  };

  return (
    <>
      {show && (
        <>
          <div style={backdropStyles} onClick={handleClose}></div>
          <div style={modalStyles}>
            <Alert
              severity={isSuccess ? "success" : "error"}
              onClose={handleClose}
              className="alert-miui"
            >
              {message}
            </Alert>
          </div>
        </>
      )}
    </>
  );
};

export default NotificationPopup;
