import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const InsufficientBalance = ({isShow, showNow, game}) => {

  return (
    <div>
      <Modal show={isShow} onHide={showNow} centered>
        <Modal.Header className="your-custom-class">
          <div onClick={() => showNow(false)}>X</div>
        </Modal.Header>
        <Modal.Body>
          <div className="popup-body-Playing-game">
            <p>You Don't Have Sufficient Balance</p>

            <div className="add-bet">
              <Link to="#" className="back-ic">
                <button
                  type="button"
                  class="btn Btn-change btn-set"
                  onClick={() => showNow(false)}
                >
                  Ok
                </button>
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InsufficientBalance;
