// Header.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavFooter from "../components/NavFooter";
import HeaderMain from "../components/HeaderMain";
import BabaJi from "../images/com-logo.svg";
import Profile from "../images/Profile-pik.png";
import { MdOutlinePhotoCamera } from "react-icons/md";
import { useForm } from "react-hook-form";
import useEditProfile from "../hooks/useEditProfile";
import { useUser } from "../constants/UserContext";
import usePlayerProfile from "../hooks/usePlayerProfile";
import useWalletAmount from "../hooks/useWalletAmount";
import { usePlayerData } from "../hooks/useHome";
import { imageApiClient } from "../constants/api-client";
import Notification from "./Notification";
import NotificationPopup from "./NotificationPopup";
import TopTitle from "../components/TopTitle";
import Footer from "../components/Footer";

function EditProfile() {
  const [selectedFile, setSelectedFile] = useState(null);
  const { editProfile } = useEditProfile();
  const { playerDetails } = usePlayerProfile();
  const { walletAmount } = useWalletAmount();
  const { mobileNumber } = useUser();
  const playerInfo = usePlayerData();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const [image, setImage] = useState(null);

  const onSubmit = (data) => {
    const formData = new FormData();

    image && formData.append("image", image);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("dob", data.dob);
    formData.append("bank", data.bank);
    formData.append("account_number", data.account_number);
    formData.append("ifsc", data.ifsc);

    editProfile.mutate({ id: mobileNumber, data: formData });
    console.log(formData)
  };

  useEffect(() => {
    playerInfo.mutate({ mobile: mobileNumber });
  }, []);

  return (
    playerInfo?.isSuccess && (
      <div>
        <HeaderMain pageTitle={<Link to="/">Home</Link>} />
        {editProfile.isSuccess ? (
          <NotificationPopup
            message={"Updated Profile Successfully!"}
            isSuccess
          />
        ) : (
          editProfile.isError && (
            <NotificationPopup
              message={"Failed to update profile " + editProfile.error}
            />
          )
        )}
        <TopTitle />
        <div className="baba-ji">
          <img src={BabaJi} alt="" />
        </div>

        <div className="container edit-profile-container">
          <div className="edit-profile-box">
            <div className="edit-boxses">
              Balance : <span className="text-success-am">{walletAmount && walletAmount?.total_amount}</span>
            </div>

            <div className="edit-boxses">
              Bonus : <span className="text-success-am">{(playerDetails && (playerDetails[0]?.commission_amount)) || 0}</span>
            </div>
          </div>

          <div className="profile-pik-popup" style={{ marginTop: 40 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                id="fileInput"
                onChange={(e) => setImage(e.target.files[0])}
              />

              <label htmlFor="fileInput" className="cursor">
                {image ? (
                  <img src={URL.createObjectURL(image)} alt="" />
                ) : playerInfo?.data?.image ? (
                  <img src={imageApiClient + playerInfo?.data?.image} />
                ) : (
                  <>
                    <MdOutlinePhotoCamera style={{ fontSize: "36px" }} />
                  </>
                )}
              </label>
            </form>
          </div>

          <div style={{ marginTop: 40 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div class="form-row input-input input-bottom">
                <div class="col">
                  <p>Name</p>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Your Name"
                    defaultValue={playerInfo?.data?.name}
                    {...register("name", { required: true })}
                  />
                  {errors.name && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
                <div class="col">
                  <p>Email Address</p>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter Email Address"
                    defaultValue={playerInfo?.data?.email}
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </div>

              <div class="form-row input-input input-bottom">
                <div class="col">
                  <p>Mobile Number</p>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text"> +91 </div>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      id="inlineFormInputGroupUsername"
                      placeholder="Mobile Number"
                      Value={mobileNumber}
                      readOnly
                    // {...register("mobile", { required: true })}
                    />
                  </div>
                  {/* {errors.mobileNumber && (
                  <span className="text-danger">This field is required</span>
                )} */}
                </div>
                <div class="col">
                  <p>Date Of Birth</p>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="dd/mm/yyyy"
                    defaultValue={playerInfo?.data?.dob}
                    {...register("dob", { required: true })}
                  />
                  {errors.dateOfBirth && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </div>

              <div class="form-row input-input input-bottom">
                <div class="col">
                  <p>Aadhaar</p>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="inlineFormInputGroupUsername"
                      placeholder="Aadhaar Number"
                      Value={playerInfo?.data?.aadhar}
                      readOnly
                    />
                  </div>
                </div>
                <div class="col">
                  <p>PAN</p>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="inlineFormInputGroupUsername"
                      placeholder="PAN Number"
                      Value={playerInfo?.data?.pan}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div class="form-row input-input input-bottom">
                <div class="col">
                  <p>Bank</p>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="inlineFormInputGroupUsername"
                      placeholder="Bank"
                      Value={playerInfo?.data?.bank}
                      {...register("bank")}
                      // readOnly
                    />
                  </div>
                </div>
                <div class="col">
                  <p>Account Number</p>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="inlineFormInputGroupUsername"
                      placeholder="Account Number"
                      Value={playerInfo?.data?.account_number}
                      {...register("account_number")}
                      // readOnly
                    />
                  </div>
                </div>
              </div>

              <div class="form-row input-input input-bottom">
                <div class="col">
                  <p>IFSC Code</p>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="inlineFormInputGroupUsername"
                      placeholder="IFSC"
                      Value={playerInfo?.data?.ifsc}
                      {...register("ifsc")}
                      // readOnly
                    />
                  </div>
                </div>
                <div class="col">
                  <p>Account Holder</p>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="inlineFormInputGroupUsername"
                      placeholder="Account Holder"
                      Value={playerInfo?.data?.account_holder}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <button
                type="submit"
                class="btn btn-primary btn-blcok margin-in-extra-top"
              >
                Save
              </button>
            </form>
          </div>

          {/* <Link to="/HomePage">
          <button
            type="button"
            class="btn btn-primary btn-blcok margin-in-extra-top"
          >
            Add
          </button>
        </Link> */}
        </div>

        <div className="bootom-footer">
          <span>Copyright © Dec 2023, All Right Reserved By </span>
          <a href="https://www.litpix-studio.com/">
            <span id="green-color">Litpix Studio</span>
          </a>
        </div>

        <NavFooter />
        <Footer/>
      </div>
    )
  );
}

export default EditProfile;
