import React, { useEffect, useState } from "react";
import { Modal, Placeholder } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import BabaJi from "../images/com-logo.svg";
import { Link } from "react-router-dom";
import { usePlayerData } from "../hooks/useHome";
import { useUser } from "../constants/UserContext";
import NotificationPopup from "./NotificationPopup";

const ReferAndEarn = ({ showModal, setShowModal }) => {
  const { mobileNumber } = useUser();
  const [message, setMessage] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);

  const playerInfo = usePlayerData();

  useEffect(() => {
    playerInfo.mutate({ mobile: mobileNumber });
  }, []);

  useEffect(() => {
    if (playerInfo.isSuccess) {
      const textMessage =
        "Play BGM game and earn Rs10000 daily." +
        "\nLife Time Earning \n24x7 Live Chat Support \nWithdrawal Via UPI/Bank \n👇👇 " +
        "\nRegister Now, on \nwww.thebgmgame.com " +
        "\nMy refer code is " +
        playerInfo.data.refer_code +
        ".";

      setMessage(textMessage);
    }
  }, [playerInfo]);

  const shareViaSms = () => {
    const smsUrl = `sms:?body=${encodeURIComponent(message)}`;
    window.location.href = smsUrl;
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(message)
      .then(() => {
        setCopySuccess(true); // Copy operation is successful
        setTimeout(() => {
          setCopySuccess(false); // Hide the success message after a delay
        }, 1200);
      })
      .catch((error) => {
        console.error("Unable to copy text: ", error);
      });
  };

  const copyToRefferalClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      className="modal-scrollable"
    >
      <div className="transfar-heding">Welcome to The BGM Game</div>
      <Modal.Header closeButton>
        <div
          className="custom-close-button"
          onClick={() => setShowModal(false)}
        >
          <TfiClose />
        </div>
      </Modal.Header>
      {copySuccess && (
        <NotificationPopup noReload message="Refer link coppied!" isSuccess />
      )}

      <Modal.Body>
        <div className="add-point-profile mt-0">
          <img src={BabaJi} alt="" />
        </div>

        <div className="add-pont-title m-0 pb-0 ">
          <span className="me-1"> Share Application: </span>
          <a
            href="www.thebgmgame.com"
            className="text-success"
            style={{ pointerEvents: "none" }}
          >
            www.thebgmgame.com
          </a>
        </div>
        <div className="add-pont-title m-0 ">
          <span className="me-1"> My Referal Code: </span>
          <a className="text-success">
            {playerInfo?.data?.refer_code || "Generating referal code.."}
          </a>
        </div>
        {/* <div className="add-pont-title mt-0">
          रेफर लिंक को कॉपी करने के लिए नीचे दिए गए आइकन पर क्लिक करें 👇👇👇
        </div> */}

        <div className="text-center p-3">
          <button
            type="button"
            class=" col-12 btn btn-primary "
            onClick={() =>
              copyToRefferalClipboard(playerInfo?.data?.refer_code)
            }
          >
            🔥COPY REFERRAL CODE🔥
          </button>
        </div>

        <div className="add-pont-title mt-0">एप्लीकेशन को शेयर करें 👇</div>

        <div className=" p-3">
          <div class="row">
            <div className=" col-md-6 text-center">
              <Link
                to={"https://api.whatsapp.com/send?text=" + message}
                target="_blank"
              >
                <button
                  type="button"
                  class="btn btn-primary w-100 mt-3 mt-md-0"
                >
                  Share Whatsapp
                </button>
              </Link>
            </div>

            {/* <div className=" col-md-4 text-center">
              <button type="button" class="btn btn-primary w-100 mt-3 mt-md-0">
                Instagram
              </button>
            </div> */}

            <div className="col-md-6 text-center">
              <button
                type="button"
                class="btn btn-primary w-100 mt-3 mt-md-0"
                onClick={() => shareViaSms()}
              >
                Share SMS
              </button>
            </div>
          </div>
        </div>

        <div className="add-pont-title mt-0">
          👆 एप्लीकेशन शेयर करने पर आपको हर यूजर का 1 फ्री स्पिन मिलेगा, जिससे
          आप इनाम जीत सकते हैं| 👆
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReferAndEarn;

// #referandearn
