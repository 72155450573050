import React, { useEffect, useState } from "react";
import Nocopy from "../images/com-logo.svg";
import { useForm } from "react-hook-form";
import { usePostMobile, useVerifyOTP } from "../hooks/useAddLogin";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../constants/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import useLoginContent from "../hooks/useLoginContent";
import { imageApiClient } from "../constants/api-client";

const LoginPage = () => {
  const navigateTo = useNavigate();
  const { user, login } = useUser();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [alertMessage, setAlertMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const { referCode } = useParams();

  const postMobile = usePostMobile();
  const verifyOTP = useVerifyOTP();
  const { loginContent } = useLoginContent();

  const mobilePattern = /^[0-9]{10}$/; // 10-digit mobile number pattern
  const otpPattern = /^[0-9]{6}$/; // 6-digit OTP pattern

  const onSubmit = async (data) => {
    if (!otpSent) {
      postMobile.mutate(
        {
          mobile: data.mobile,
          ...(data.refered_by && { refered_by: data.refered_by }),
        },
        {
          onSuccess: (responseData) => {
            if (responseData.data.message.includes("blocked.")) {
              setAlertMessage(responseData.data.message);
            } else {
              setOtpSent(true);
              setAlertMessage("OTP Sent");
              setMobileNumber(data.mobile);
              reset();
              setTimeout(() => setAlertMessage(""), 2000);
            }
          },
          onError: () =>
            setAlertMessage("Error sending OTP. Please try again."),
          onLoading: () => setAlertMessage("Sending OTP..."),
        }
      );
    } else {
      verifyOTP.mutate(
        {
          mobileNumber,
          otp: data.otp,
          ...(data.refered_by && { refered_by: data.refered_by }),
        },
        {
          onSuccess: () => {
            setAlertMessage("OTP verified successfully");
            setTimeout(() => setAlertMessage(""), 2000);
            login(mobileNumber);
            navigateTo("/");
          },
          onError: () => {
            setAlertMessage("Invalid OTP. Please try again.");
            setTimeout(() => setAlertMessage(""), 4000);
          },
          onLoading: () => setAlertMessage("Verifying OTP..."),
        }
      );
    }
  };

  const handleButtonClick = (url) => {
    window.open(`${imageApiClient}/${url}`, "_blank");
  };

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    });
  }, []);

  const showCustomInstallPrompt = () => {
    console.log("deferredPrompt :- ", deferredPrompt)
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <>
      <div className="container login-page-conatiner">
        <div className="login-page ">
          <div
            style={{
              width: "100%",
              backgroundColor: "#000",
              justifyContent: "center",
              padding: 10,
            }}
            className="text-center"
          >
            <text style={{ textAlign: "center", color: "#FFF", fontSize: 16 }}>
              {loginContent?.heading}
            </text>
          </div>

          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <button className="btn-vid" onClick={() =>
                  handleButtonClick(loginContent?.android_video)
                }>
                  <FontAwesomeIcon icon={faVideo} />
                  <span>iPhone मैं डाउनलोड करने के लिए वीडियो देखें</span>
                </button>
              </div>

              <div className="col-6">
                <button className="btn-vid" onClick={() =>
                  handleButtonClick(loginContent?.iphone_video)
                }>
                  <FontAwesomeIcon icon={faVideo} />
                  <span>ANDROID मैं डाउनलोड करने के लिए वीडियो देखें</span>
                </button>
              </div>
            </div>
          </div>

          <div className="logo-image">
            <img src={Nocopy} alt="" />
          </div>

          <div className="login-page-content ">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row input-input input-bottom login-content">
                <div className="col">
                  <p>{otpSent ? "Enter OTP" : "Mobile Number"}</p>
                  <div className="form-group input-group">
                    {!otpSent && (
                      <div className="input-group-prepend">
                        <div className="input-group-text"> +91 </div>
                      </div>
                    )}
                    <input
                      type="tel"
                      className={`form-control ${errors.mobile ? "is-invalid" : ""}`}
                      id="inlineFormInputGroupUsername"
                      placeholder={otpSent ? "Enter OTP" : "Mobile Number"}
                      {...register(otpSent ? "otp" : "mobile", {
                        required: "This field is required",
                        pattern: {
                          value: otpSent ? otpPattern : mobilePattern,
                          message: otpSent ? "Invalid OTP" : "Invalid mobile number",
                        },
                      })}
                    />
                  </div>
                  {errors.mobile && (
                    <div className="invalid-feedback">
                      {errors.mobile.message}
                    </div>
                  )}
                  <div className="form-group">
                    {!otpSent && (
                      <input
                        type="number"
                        className={`form-control`}
                        id="referCode"
                        placeholder={"Enter Referal Code"}
                        {...register("refered_by")}
                      />
                    )}
                    {otpSent ? "" : (
                      <span className="optional">(Optional)</span>
                    )}
                  </div>
                  {alertMessage && (
                    <div
                      className={`alert ${alertMessage.includes("Error") ||
                        alertMessage.includes("Invalid") ||
                        alertMessage.includes("blocked")
                        ? "alert-danger"
                        : alertMessage.includes("Logging")
                          ? "alert-secondary"
                          : "alert-success"
                        } mt-3`}
                      role="alert"
                    >
                      {alertMessage}
                    </div>
                  )}
                </div>

                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block w-100"
                    disabled={postMobile.isLoading || verifyOTP.isLoading}
                  >
                    {otpSent ? "Verify OTP" : "Send OTP"}
                  </button>
                </div>

                <div className="row align-items-center">
                  <div className="col-md-6 text-center form-group">
                    <button
                      type="button"
                      className="btn btn-primary btn-block btn-lg w-100"
                      onClick={showCustomInstallPrompt}
                    >
                      Install Web Application
                    </button>
                  </div>

                  <div className="col-md-6 text-center form-group">
                    <button
                      type="button"
                      className="btn btn-primary btn-block btn-lg w-100"
                      onClick={() => navigateTo("/")}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;











// import React, { useEffect, useState } from 'react';
// import Nocopy from '../images/com-logo.svg';
// import { useForm } from 'react-hook-form';
// import { usePostMobile, useVerifyOTP } from '../hooks/useAddLogin';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useUser } from '../constants/UserContext';
// import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import useLoginContent from '../hooks/useLoginContent';
// import { imageApiClient } from '../constants/api-client';


// const LoginPage = () => {
//   const navigateTo = useNavigate();
//   const { user, login } = useUser();
//   const { register, handleSubmit, reset, formState: { errors } } = useForm();
//   const [alertMessage, setAlertMessage] = useState("");
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [otpSent, setOtpSent] = useState(false);

//   const { referCode } = useParams()



//   const postMobile = usePostMobile();
//   const verifyOTP = useVerifyOTP();
//   const { loginContent } = useLoginContent();


//   const mobilePattern = /^[0-9]{10}$/; // 10-digit mobile number pattern
//   const otpPattern = /^[0-9]{6}$/; // 6-digit OTP pattern

//   const onSubmit = async (data) => {
//     // login(data.mobile);
//     // navigateTo("/");
//     if (!otpSent) {
//       // Step 1: Fetch OTP
//       postMobile.mutate({ mobile: data.mobile, ...(data.refered_by && { refered_by: data.refered_by }) }, {
//         onSuccess: (responseData) => {
//           if (responseData.data.message.includes("blocked.")) {
//             setAlertMessage(responseData.data.message)
//           }
//           else {
//             setOtpSent(true);
//             setAlertMessage('OTP Sent');
//             setMobileNumber(data.mobile);
//             reset();
//             setTimeout(() => setAlertMessage(""), 2000);
//           }
//         },
//         onError: () => setAlertMessage('Error sending OTP. Please try again.')
//         ,
//         onLoading: () => setAlertMessage("Sending OTP..."),
//       });
//     } else {
//       verifyOTP.mutate({
//         mobileNumber,
//         otp: data.otp,
//         ...(data.refered_by && { refered_by: data.refered_by })
//       }, {
//         onSuccess: () => {
//           setAlertMessage('OTP verified successfully');
//           setTimeout(() => setAlertMessage(""), 2000);
//           login(mobileNumber);
//           navigateTo("/");

//         },
//         onError: () => {
//           setAlertMessage('Invalid OTP. Please try again.');
//           setTimeout(() => setAlertMessage(""), 4000);
//         },
//         onLoading: () => setAlertMessage("Verifying OTP..."),
//       });

//     }
//   };


//   const handleButtonClick = (url) => {
//     // Replace 'https://example.com' with your desired URL
//     window.open(`${imageApiClient}/${url}`, '_blank');
//   };



//   return (
//     <>
//       <div className="container login-page-conatiner">
//         <div className="login-page">
//           <div className="logo-image">
//             <img src={Nocopy} alt="" />
//           </div>
//           <div style={{ width: '100%', backgroundColor: '#000', justifyContent: 'center', padding: 10, }} className="col text-center">
//             <text style={{ textAlign: 'center', color: "#FFF", fontSize: 16, }}>{loginContent?.heading}</text>
//           </div>

//           <div className="login-page-content mb-5">
//             <form onSubmit={handleSubmit(onSubmit)}>
//               <div className="form-row input-input input-bottom login-content">
//                 <div className="col">
//                   <p>{otpSent ? 'Enter OTP' : 'Mobile Number'}</p>
//                   <div className="form-group input-group">
//                     {!otpSent && <div className="input-group-prepend">
//                       <div className="input-group-text"> +91 </div>
//                     </div>}
//                     <input
//                       type="tel"  // Use type "tel" for mobile number input
//                       className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
//                       id="inlineFormInputGroupUsername"
//                       placeholder={otpSent ? 'Enter OTP' : 'Mobile Number'}
//                       {...register(otpSent ? 'otp' : 'mobile', {
//                         required: 'This field is required',
//                         pattern: {
//                           value: otpSent ? otpPattern : mobilePattern,
//                           message: otpSent ? 'Invalid OTP' : 'Invalid mobile number',
//                         },
//                       })}
//                     />
//                   </div>
//                   {errors.mobile && (
//                     <div className="invalid-feedback">
//                       {errors.mobile.message}
//                     </div>
//                   )}
//                   <div className="form-group">
//                     {!otpSent &&
//                       <input
//                         type="number"
//                         className={`form-control`}
//                         id="referCode"
//                         placeholder={"Enter Referal Code"}
//                         {...register('refered_by')}
//                       />}
//                     {otpSent ? ('') : (<span className='optional'>(Optional)</span>)}
//                   </div>
//                   {alertMessage && (
//                     <div className={`alert ${alertMessage.includes("Error") || alertMessage.includes("Invalid") || alertMessage.includes("blocked") ? "alert-danger" : alertMessage.includes("Logging") ? "alert-secondary" : "alert-success"} mt-3`} role="alert">
//                       {alertMessage}
//                     </div>)}
//                 </div>

//                 <div className="form-group text-center">
//                   <button type="submit" className="btn btn-primary btn-block w-100" disabled={postMobile.isLoading || verifyOTP.isLoading}>
//                     {otpSent ? 'Verify OTP' : 'Send OTP'}
//                   </button>
//                 </div>


//                 <div className="row align-items-center">
//                   <div className="col-md-6 text-center form-group">
//                     <button onClick={() => handleButtonClick(loginContent?.android_video)} type="button" className="btn btn-primary btn-block btn-lg w-100">
//                       <FontAwesomeIcon icon={faAndroid} className="me-2" />
//                       {loginContent?.android_heading}
//                     </button>
//                   </div>



//                   <div className="col-md-6 text-center form-group">
//                     <button onClick={() => handleButtonClick(loginContent?.iphone_video)} type="button" className="btn btn-primary btn-block btn-lg  w-100">
//                       <FontAwesomeIcon icon={faApple} className="me-2" />
//                       {loginContent?.iphone_heading}
//                     </button>
//                   </div>
//                 </div>

//               </div>
//             </form>
//             <div className="ft-link">
//               <a href='/Report'>Contacting Policy</a>
//               <a href='/RefundPolicy'>Refund Policy</a>
//               <a href='/PrivacyPolicy'>Privacy Policy</a>
//               <a href='/TermsAndConditions'>Term and conditions</a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default LoginPage;
