import React, { useState } from "react";
import NavFooter from "../components/NavFooter";
import HeaderMain from "../components/HeaderMain";
import useHelp from "../hooks/useHelp";
import ChatIcon from "../images/chat-icon.png";
import { TfiClose } from "react-icons/tfi";
import TopTitle from "../components/TopTitle";
import { imageApiClient } from "../constants/api-client";
import Footer from "../components/Footer";

function Help() {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const { help } = useHelp();

  return (
    <>
      <HeaderMain pageTitle={"Help"} />
      {/* <TopTitle/> */}
      <div class="top-heading-top gap-extra">
        <div>
          <h6>💥BGM HELP & SUPPORT💥</h6>
        </div>
      </div>

      {help && help.content && (
        <div className="container tearms-and-condition-container">
          <div>
            <div dangerouslySetInnerHTML={{ __html: help.content }} />
          </div>

          <div className="row">
            {[1, 2, 3, 4, 5].map((num) => (
              <div className="col-md-4 col-lg-3" key={num}>
                {help.video[`video${num}`] && (
                  <>
                    <h1>{help.video[`video_title${num}`]}</h1>
                    <video
                      controls
                      style={{ height: "auto", width: "auto" }}
                    >
                      <source
                        src={imageApiClient + help.video[`video${num}`]}
                        type="video/mp4"
                      />
                    </video>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="chat-icon text-center">
        <span onClick={handleShow}>Chat</span>
      </div>

      <div
        className={`modal ${showModal ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" id="seprate-model">
          <div className="modal-content">
            <div className="modal-header">
              <div className="custom-close-button" onClick={handleClose}>
                <TfiClose />
              </div>
            </div>
            <div className="row">
              <div className="modal-body d-flex col-12 ">
                <div className="col-6 text-center ">
                  <a
                    href="/withdraw-chat-screen"
                    class="btn btn-primary model-popup-btns"
                  >
                    Withdraw Chat
                  </a>
                  <p>
                    पैसे निकालने मैं अगर कोई समस्या है तो withdraw chat पे क्लिक
                    करे।
                  </p>
                </div>
                <div className="col-6 text-center ">
                  <a
                    href="/chat-screen"
                    class="btn btn-primary model-popup-btns"
                  >
                    Deposit Chat
                  </a>
                  <p>
                    पैसे ऐड करने मैं अगर आपको समस्या है तो deposit chat पे क्लिक
                    करे।
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />
      <Footer />
    </>
  );
}



export default Help;

{
  /* <div className="container-title">Welcome To Baba Ji Mataka</div>

      <div className="top-heading-top gap-extra">
        <span>BABA JI MATKA HELP & SUPPORT</span>
      </div>

      <div className="container tearms-and-condition-container">

        <div className="terms-condition">
          <img src={fire} alt="" />
          <span>Dark Mode</span>
          <img src={fire} alt="" />
          <span> मैं एप्लीकेशन को यूज न करे</span>
        </div>


        <div className="terms-condition">
          <img src={fire} alt="" />
          <span>Min Deposit: Rs. 100</span>
          <img src={fire} alt="" />
        </div>


        <div className="terms-condition">
          <img src={fire} alt="" />
          <span>Min Withdraw: Rs. 475</span>
          <img src={fire} alt="" />
        </div>

        <div className="terms-condition">
          <img src={fire} alt="" />
          <span>रेट 10के 980</span>
          <img src={fire} alt="" />
        </div>


        <div className="terms-condition">
          <img src={fire} alt="" />
          <span>भरोसे का एक ही नाम</span>
          <img src={fire} alt="" />
        </div>


        <div className="terms-condition">
          <img src={namste} alt="" />
          <span>बाबा जी खैवाल</span>
          <img src={namste} alt="" />
        </div>


        <div className="orange-color-text"><span>NOTE:- अगर आपको गेम खेलने मैं या अगर कोई भी समस्या होती है तो आप हमारे हेल्पलाइन नंबर पर कॉल कर सकते हैं</span></div>

        <div className="game-stayle"><span>👽गेम कैसे खेलनी है जानिए👽</span></div>

        <div className="terms-condition">
          <img src={fire} alt="" />
          <span>सभी गेम मैं 1 से 100 नंबर मैं से कोई एक नंबर आता है अगर आपने वही लगाया हुआ है तो आपको 98 गुणा पैसे मिलेंगे</span>
        </div>

        <div className="terms-condition">
          <img src={fire} alt="" />
          <span>जैसे आपने 15 पर 10 रुपए लगाए हैं किसी गेम मैं और उसमे 15 रिजल्ट आता है तो आपको 980 रुपए मिलेंगे</span>
        </div>


        <div className="terms-condition ">

          <img src={fire} alt="" />
          <span>आप कितने भी नंबर लगा सकते हो बस आपका पास होना चाइए और पास होते ही पैसा आपके वॉलेट मैं आ जायेगा</span>


        </div>


        <div className="game-stayle"><span>👽गेम खेलना सीखने के लिए Video आइकन पे क्लिक करें👽</span> </div>


        <div className="multimedia-content">
          <div className="multimedia">
            <div className="media-video">
              <a href="#"><img src={multimedia} alt="" /></a>
              <span>Deposit</span>
            </div>

            <div className="media-video">
              <a href="#"><img src={multimedia} alt="" /></a>
              <span>Withdraw</span>
            </div>


            <div className="media-video">
              <a href="#"><img src={multimedia} alt="" /></a>
              <span>Game Play</span>
            </div>




          </div>
          <div className="multimedia-text">
            <span>चैट पर क्लिक करके</span>
            <span>हमसे सम्पर्क करे।</span>

          </div>
        </div>



        <div className="social-cl-fix">

          <div className="media-icons-icon-first">
          </div>


          <div className="media-icons-icon-second">
            <div className="whatsapp-icon">
              <a href="/Chat"><img src={ChatIcon} alt="" /></a>
            </div>




          </div>



        </div>



      </div> */
}
