import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';

function WithdrawChat() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesContainerRef = useRef(null);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours() % 12 || 12; // Convert to 12-hour format
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const amPm = now.getHours() >= 12 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${amPm}`;
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;

    const currentTime = getCurrentTime();
    const userMessage = { text: newMessage, sender: 'user', time: currentTime };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);

    setTimeout(() => {
      const autoReply = {
        text: 'Thank you for your message! How can I assist you?',
        sender: 'bot',
        time: getCurrentTime(),
      };
      const updatedMessagesWithReply = [...updatedMessages, autoReply];
      setMessages(updatedMessagesWithReply);
    }, 500);

    setNewMessage('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    // Scroll to the bottom when messages change
    scrollToBottom();
  }, [messages]);

  return (
    <>
      <div className="inner-header">
        <div className="container deposit-container">
          <Link to="/HomePage" className="back-ic">
            <IoArrowBack style={{ fontSize: '20px' }} />
            <span>Back</span>
          </Link>
        </div>
      </div>

      <div className="add-pont-title diposit-chat-title">Withdraw Chat</div>

      <div className="deposit-content scroll-container" ref={messagesContainerRef}>
        <div className="container">
          <div style={{ height: '100%' }}>
            {messages.map((message, index) => (
              <div
                key={index}
                style={{
                  padding: '5px',
                  textAlign: message.sender === 'user' ? 'right' : 'left',
                  marginBottom: '10px',
                }}
              >
                <div
                  style={{
                    backgroundColor: message.sender === 'user' ? '#ececec' : '#369e3a',
                    color: message.sender === 'user' ? 'black' : 'white',
                    padding: '15px',
                    borderRadius: '10px',
                    display: 'inline-block',
                    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
                    textAlign: message.sender === 'user' ? 'right' : 'left',
                  }}
                >
                  {message.text}
                  <div style={{ fontSize: '10px', color: message.sender === 'user' ? 'gray' : '#eee' }}>{message.time}</div>

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="deposit-chat-message">
        <div className="input-changes container">
          <div className="col input-changes-btn deposit-message">
            <input
              type="text"
              className="form-control"
              value={newMessage}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              placeholder="Type Message"
            />
            <button type="button" className="btn btn-primary" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default WithdrawChat;
