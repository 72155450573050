// Header.js
import React from "react";
import { Link } from "react-router-dom";
import HeaderMain from "../components/HeaderMain";
import NavFooter from "../components/NavFooter";
import namste from "../images/namaste.png";
import fire from "../images/fire.png";
import point from "../images/point.png";
import Facebook from "../images/facebook.png";
import Instagram from "../images/instagram.png";
import Whatsapp from "../images/whatsapp.png";
import BabaLogo from "../images/baba-logo.png";
import PlayStore from "../images/google-playstore.png";
import useResultHistory from "../hooks/useResultHistory";


function ResultHistory() {
  const {result} = useResultHistory();
  return (
    <div>
      <HeaderMain pageTitle={"Home"} />
      {/* ----------Content Section start------- */}

      <div className="top-title">
        Babji Satta, Satta King, Satta Result, Gali Satta, Matka Result, Disawar
        Satta, Satta, Satta Matka, Faridabad Satta, Gaziyabad Satta, Baba Ji
        Satta, Baba Satta
      </div>

      <div className="profile-container bg-light">
        <div className="container profile-content">
          <div className="profile-img">
            <a href="#">WWW.Babajiisatta.Com</a>
          </div>

          <div className="babaji-name">
            <div className="name">
              <img src={fire} alt="" />
              <span>भरोसे का एक ही नाम</span>
              <img src={fire} alt="" />
            </div>
            <div className="name">
              <img src={namste} alt="" />
              <span>बाबा जी खैवाल</span>
              <img src={namste} alt="" />
            </div>
          </div>

          <div className="time-date-day">
            <span>TOTAL VISITORS: 950824</span>
          </div>
        </div>
      </div>

      <div className="container-content result-history-container">
        <div className="babaji-introduction">
          <div className="different-content">
            <div className="intro">
              <div>
                <img src={point} alt="" />
              </div>
              <span>अब आप ऑनलाइन गेम हमारी एप्लिकेशन पे भी खेल सकते हो ।</span>
            </div>

            <div className="intro">
              <div>
                <img src={point} alt="" />
              </div>
              <span>
                फरीदाबाद ,गाजियाबाद ,गली और दिसावर खेलने वाले डाउनलोड करे
                एप्लीकेशन !
              </span>
            </div>

            <div className="intro">
              <div>
                <img src={point} alt="" />
              </div>
              <span>सबसे जायदा रेट 10 के 980</span>
            </div>

            <div className="intro">
              <div>
                {" "}
                <img src={point} alt="" />
              </div>
              <span>हेल्पलाइन नंबर 6367529290</span>
            </div>

            <div className="intro">
              <div>
                <img src={point} alt="" />
              </div>
              <span>
                ऑनलाइन गेम प्ले करने के लिए PLAY STORE से हमारी एप्लिकेशन
                डाउनलोड करे
              </span>
            </div>
          </div>

          <div className="google-play-store">
            <a href="#">
              <img src={PlayStore} alt="" />
            </a>
          </div>

          <div className="intro">
            <span>
              नोट : गली दिसावर गेम प्ले करने वाले क्लिक करके डाउनलोड करे
            </span>
          </div>

          <div className="green-white-line"></div>

          <div className="intro font-size-increes">
            <span>lIVE RESULT</span>
          </div>

          <div className="intro font-size-increes">
            <span>SILVER CITY</span>
          </div>

          <div className="intro font-size-increes">
            <span>WAIT</span>
          </div>

          <div className="intro font-size-increes">
            <span>MORNING STAR</span>
          </div>

          <div className="intro font-size-increes">
            <span>20</span>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>
              यह साइट सिर्फ मनोरंजन के लिए बनाई गई है।यह साइट सट्टे से जुड़ी
              किसी भी गतिविधि को बढ़ावा नहीं देती।सट्टा और जुआं जिस देश या राज्य
              में प्रतिबंधित है वहां के लोग हमारी साइट को ब्लॉक कर दें |
            </span>
          </div>

          <div className="intro">
            <span>चेतावनी (WARNING)</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>
              आप अगर किसी भी टाइप की कोई भी साइट या एप्लीकेशन बनवाना चाहते हैं
              तो हमसे संपर्क करे
            </span>
          </div>

          <div className="intro">
            <span>SOFTWARE COMPANY</span>
          </div>

          <div className="intro">
            <span>Manager</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>
              हमारी वेबसाइट पर अपनी गेम का रिजल्ट डलवाने के लिए संपर्क करे
            </span>
          </div>

          <div className="intro">
            <span>ANMOL RATTAN</span>
          </div>

          <div className="intro">
            <span>DIRECTOR</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="big-table">
            <div className="result-of">
              <div className="two-boxses">
                <div className="boxes">
                  <span>MORNING STAR</span>
                  <span id="orange-color">( 11:15:00 )</span>
                  <div className="new-box">
                    <span>[33]</span>
                    <button>NEW</button>
                    <span>[20]</span>
                  </div>
                </div>

                <div className="boxes">
                  <span>SILVER CITY</span>
                  <span id="orange-color">( 12:00:00 )</span>
                  <div className="new-box">
                    <span>[02]</span>
                    <button>NEW</button>
                    <span>[75]</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-of">
              <div className="two-boxses">
                <div className="boxes">
                  <span>LONDON BAZAR</span>
                  <span id="orange-color">( 14:15:00 )</span>
                  <div className="new-box">
                    <span>[51]</span>
                    <button>NEW</button>
                    <span>[92]</span>
                  </div>
                </div>

                <div className="boxes">
                  <span>DELHI BAZAAR</span>
                  <span id="orange-color">( 15:10:00 )</span>
                  <div className="new-box">
                    <span>[63]</span>
                    <button>NEW</button>
                    <span>[86]</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-of">
              <div className="two-boxses">
                <div className="boxes">
                  <span>DEV DARSHAN</span>
                  <span id="orange-color">( 15:10:00 )</span>
                  <div className="new-box">
                    <span>[79]</span>
                    <button>NEW</button>
                    <span>[60]</span>
                  </div>
                </div>

                <div className="boxes">
                  <span>SHRI GANESH</span>
                  <span id="orange-color">( 16:45:00 )</span>
                  <div className="new-box">
                    <span>[53]</span>
                    <button>NEW</button>
                    <span>[64]</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-of">
              <div className="two-boxses">
                <div className="boxes">
                  <span>HR // 20</span>
                  <span id="orange-color">( 05:15:00 )</span>
                  <div className="new-box">
                    <span>[37]</span>
                    <button>NEW</button>
                    <span>[69]</span>
                  </div>
                </div>

                <div className="boxes">
                  <span>FARIDABAAD</span>
                  <span id="orange-color">( 18:00:00 )</span>
                  <div className="new-box">
                    <span>[47]</span>
                    <button>NEW</button>
                    <span>[63]</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-of">
              <div className="two-boxses">
                <div className="boxes">
                  <span>INDIA CLUB</span>
                  <span id="orange-color">( 07:15:00 )</span>
                  <div className="new-box">
                    <span>[83]</span>
                    <button>NEW</button>
                    <span>[60]</span>
                  </div>
                </div>

                <div className="boxes">
                  <span>GAZIABAAD</span>
                  <span id="orange-color">( 20:55:00 )</span>
                  <div className="new-box">
                    <span>[19]</span>
                    <button>NEW</button>
                    <span>[60]</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-of">
              <div className="two-boxses">
                <div className="boxes">
                  <span>NEPAL BORDER</span>
                  <span id="orange-color">( 22:00:00 )</span>
                  <div className="new-box">
                    <span>[71]</span>
                    <button>NEW</button>
                    <span>[98]</span>
                  </div>
                </div>

                <div className="boxes">
                  <span>GALI</span>
                  <span id="orange-color">( 23:50:00 )</span>
                  <div className="new-box">
                    <span>[52]</span>
                    <button>NEW</button>
                    <span>[88]</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-of">
              <div className="two-boxses">
                <div className="boxes">
                  <span>SHIV SHAKTI</span>
                  <span id="orange-color">( 01:15:00 )</span>
                  <div className="new-box">
                    <span>[70]</span>
                    <button>NEW</button>
                    <span>[10]</span>
                  </div>
                </div>

                <div className="boxes">
                  <span>MAFIYA NIGHT</span>
                  <span id="orange-color">( 03:00:00 )</span>
                  <div className="new-box">
                    <span>[86]</span>
                    <button>NEW</button>
                    <span>[51]</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-of">
              <div className="two-boxses">
                <div className="boxes">
                  <span>DISAWAR</span>
                  <span id="orange-color">( 05:05:00 )</span>
                  <div className="new-box">
                    <span>[73]</span>
                    <button>NEW</button>
                    <span>[87]</span>
                  </div>
                </div>

                <div className="boxes">
                  <span>ADD GAME</span>
                  <span id="orange-color">( 00:00 )</span>
                  <div className="new-box">
                    <span>(00.00)</span>
                    <button>NEW</button>
                    <span>(00.00)</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="result-of">
              <div className="two-boxses">
                <div className="boxes">
                  <span>ADD GAME</span>
                  <span id="orange-color">( 00:00 )</span>
                  <div className="new-box">
                    <span>( 00:00 )</span>
                    <button>NEW</button>
                    <span>( 00:00 )</span>
                  </div>
                </div>

                <div className="boxes">
                  <span>ADD GAME</span>
                  <span id="orange-color">( 00:00 )</span>
                  <div className="new-box">
                    <span>( 00:00 )</span>
                    <button>NEW</button>
                    <span>( 00:00 )</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="intro">
            <span>
              हमारी वेबसाइट पर अपनी गेम का रिजल्ट डलवाने के लिए संपर्क करे
            </span>
          </div>

          <div className="intro">
            <span>ANMOL RATTAN</span>
          </div>

          <div className="intro">
            <span>DIRECTOR</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>सबसे पहले रिजल्ट यही आता है सीधा कंपनी से । </span>
          </div>

          <div className="intro">
            <span>
              सबसे पहले रिजल्ट देखने के लिए साइट को बुकमार्क करें दोस्तो
            </span>
          </div>

          <div className="intro">
            <span>BABA JII SATTA KING</span>
          </div>

          <div className="intro">
            <span>HEAD OF COMPANY</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>हमारी साइट पर गैसिंग करने के लिए हमसे संपर्क करे । </span>
          </div>

          <div className="intro">
            <span>TINDI SHARMA</span>
          </div>

          <div className="intro">
            <span>GUESSER MANAGER</span>
          </div>

          <div className="intro">
            <span id="orange-color">919351865037</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>
              अगर आप लॉस कवर करना चाहते हैं तो हमारे फेसबुक ग्रुप से जुड़े । वहा
              पर फ्री गेम दी जाती है
            </span>
          </div>

          <div className="intro">
            <span>ग्रुप मैं जुड़ने के लिए व्हाट्स ऐप करे</span>
          </div>

          <div className="intro">
            <span>ASHA SHARMA</span>
          </div>

          <div className="intro">
            <span>COMPANY MD</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>
              गली दिसावर की सॉलिड जोड़ी के लिए हमसे संपर्क करे । एडवांस नही देना
              लेकिन आफ्टर पास देना पड़ेगा
            </span>
          </div>

          <div className="intro">
            <span>ANMOL RATTAN</span>
          </div>

          <div className="intro">
            <span>SATTA COMPANY KA BAAP</span>
          </div>

          <div className="intro">
            <span>COMPANY MD</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>
              सट्टा कभी भी लीक और फिक्स नहीं होता है सिर्फ एक अनुमान होता है
              भाईयो इसलिए फ्रॉड लोगो से सावधान रहे
            </span>
          </div>

          <div className="intro">
            <span>BABA KHAIWAL</span>
          </div>

          <div className="intro">
            <span>COMPANY KHAIWAL</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>हमारे पास गेम खेलने के लिए संपर्क करें सब भाई </span>
          </div>

          <div className="intro">
            <img src={fire} alt="" />
            <span>भरोसे का एक ही नाम</span>
            <img src={fire} alt="" />
          </div>

          <div className="intro">
            <img src={namste} alt="" />
            <span>बाबा जी खैवाल</span>
            <img src={namste} alt="" />
          </div>

          <div className="intro">
            <span>ANMOL RATTAN</span>
          </div>

          <div className="intro">
            <span>BABA JI KHAIWAL</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>
              अगर आप लॉस कवर करना चाहते हैं तो हमारे फेसबुक ग्रुप से जुड़े । वहा
              पर फ्री गेम दी जाती है
            </span>
          </div>

          <div className="intro">
            <span>ग्रुप मैं जुड़ने के लिए व्हाट्स ऐप करे</span>
          </div>

          <div className="intro">
            <span>BABA JI SATTA KING</span>
          </div>

          <div className="intro">
            <span>COMPANY MANAGER</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span>
              हमारी वेबसाइट पर अपनी गेम का रिजल्ट डलवाने के लिए संपर्क करे
            </span>
          </div>

          <div className="intro">
            <span>ANMOL RATTAN</span>
          </div>

          <div className="intro">
            <span>SITE MANAGER</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>
        </div>

        <div className="all-game-chart-title">All Game Yearly Charts</div>

        <div className="container-full">
          <div className="table-responsive">
            <table class="table table-bordered table-green-table table-hover ">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">DISAWER</th>
                  <th scope="col">FARIDABAD</th>
                  <th scope="col">GAZIYABAD</th>
                  <th scope="col">GALI</th>
                  <th scope="col">INDIA CLUB</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-responsive">
            <table class="table table-bordered table-green-table  table-hover">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">LONDON BAZAR</th>
                  <th scope="col">DELHI BAZAR</th>
                  <th scope="col">DEV DARSHAN</th>
                  <th scope="col">SHRI GANESH</th>
                  <th scope="col">HR // 20</th>
                  <th scope="col">NEPAL BORDER</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="table-responsive">
            <table class="table table-bordered table-green-table  table-hover">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">SILVER CITY</th>
                  <th scope="col">MAFIYA NIGHT</th>
                  <th scope="col">SHIV SHAKTI</th>
                  <th scope="col">MORNING STAR</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
                <tr>
                  <th scope="row">01-12-2023</th>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                  <td>95</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="babaji-introduction">
          <div className="green-white-line"></div>

          <div className="intro">
            <span>
              हमारी वेबसाइट पर अपनी गेम का रिजल्ट डलवाने के लिए संपर्क करे
            </span>
          </div>

          <div className="intro">
            <span>ANMOL RATTAN</span>
          </div>

          <div className="intro">
            <span>SITE MANAGER</span>
          </div>

          <div className="intro">
            <span id="orange-color">919100417723</span>
          </div>

          <div className="intro">
            <button type="button" class="btn btn-primary btn-reset">
              Call Now
            </button>
            <button type="button" class="btn Btn-change btn-reset">
              Whatsapp Now
            </button>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span id="orange-color">TINDI SHARMA</span>
          </div>

          <div className="intro container">
            <span>
              Babaji satta <span id="lite-green">(सट्टा किंग)</span> is a sort
              of lottery game in view of numbers from 00 to 99 which goes under
              "Betting". The genuine name of this game is Babaji satta Matka, in
              which "Babaji satta" signifies wagering or betting and "Matka"
              signifies a pot through which a number is somewhat long. In the
              Babaji satta Matka game, individuals bet cash on their picked
              numbers from between 00 to 99. After which, a number is
              excessively long of the pot. Whichever individual's number was
              excessively long, he would win the award and individuals called
              him as the Babaji satta. Babaji satta(सट्टा किंग) isn't the
              situation, it was the title used to respect the champ of the
              Babaji satta Matka. However, as this game became well known,
              individuals began knowing it by the name of Babaji satta.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">History Of Babaji Satta Matka Game</span>
          </div>

          <div className="intro container">
            <span>
              In the event that you also are keen on being familiar with the
              historical backdrop of Babaji satta Matka, then you have come to
              the ideal location. Babaji satta Matka satta result began during
              the 1950s, while the vast majority bet on the opening and shutting
              costs of cotton, which was then sent from the Bombay Cotton Trade
              to the New York Cotton Trade, by means of teleprinters. 1. In
              1961, when the New York Cotton Trade prohibited this sort of
              wagering, then, at that point, these punters/players needed to
              consider alternate ways of keeping this Babaji satta Matka
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">How Many Types Of Babaji Satta Game ?</span>
          </div>

          <div className="intro container">
            <span>
              business alive. 2. In 1962, a subsequent head, Kalyanji Bhagat,
              who possessed a staple shop from Worli, began Kalyan Worli Matka
              in which, as per his new principles, even the most unfortunate of
              the poor could stake it. 1 rupee in particular. 3. Simultaneously
              following two years, Ratan Khatri continued the New Worli Matka in
              1964, in which he rolled out certain improvements in the
              guidelines of the game. 4. Where Kalyanji Bhagat's Matka used to
              run the entire days of the week, Ratan Khatri's Matka used to run
              just six days every week. 5. At the point when material factories
              turned out to be more famous in Mumbai, a large portion of the
              factory laborers began playing matka more, because of which more
              bookies began opening their shops around these plant regions, and
              in this manner Focal Mumbai is a great deal Matka business has
              turned into a major center in Mumbai. 6. Sometime in the past the
              matka business arrived at its top between the 1980s and 1990s,
              around then there was a business of about Rs 500 crore each month.
              7. After the rehashed strike of Mumbai Police, satta king was a
              great deal of harm at the foundations of Babaji satta Matka. So
              bookies had to remove these bases from the city. Simultaneously,
              some must be taken to different states additionally like Gujarat,
              Rajasthan, and so forth. 8. At the point when there are no more
              choices of wagering or Babaji satta left, then, at that point,
              bookies have begun to focus on another betting like internet-based
              lotteries. Simultaneously, A few speculators began playing
              wagering on cricket matches. Similarly, as the impedance of the
              police began expanding, this business likewise began to endure a
              great deal. Simultaneously when Kalyanji Bhagat's child "Suresh
              Bhagat" was killed, then in 2008, the Babaji satta was practically
              shut. Indeed, even after this, other Babaji satta Matka is more
              well known today in Gali, Disawar, Faridabad, Delhi Marketplace,
              Kuber, JD Durga, New Faridabad, and so on.). Next, you will get
              total data connected with Babaji satta Matka in this article.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">How Many Types Of Babaji Satta Game ?</span>
          </div>

          <div className="intro container">
            <span>
              The Babaji satta has no sort of itself. At the point when
              individuals start another game in Babaji satta, individuals name
              the game with the name of various Urban communities, states, and
              places. Like Gali, Peshawar, Ghaziabad, Faridabad, Mumbai Morning,
              Delhi Ruler, Dubai Lord, and so forth. In spite of the fact that
              there are many sorts of Babaji satta games played in India,
              however out of every one of them, 4 games (Gali, Peshawar,
              Ghaziabad, satta king,Faridabad) are played the most in the entire
              of India. The Peshawar game is generally well-known. This game is
              from India and is worked from that point.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">How To Play Babaji Satta Game ?</span>
          </div>

          <div className="intro container">
            <span>
              In the Babaji satta game, individuals bet wagers on their picked
              numbers somewhere in the range of 0 and 99. For this, the bettors
              contact their region's Khaiwal. Khaiwal functions as an
              arbiter/center man between the bettors and the game
              administrators. Each Khaiwal gathers the cash and the number from
              the players in his area and sends them to the organization.
              Furthermore, when a champ is proclaimed he gathers the rewards
              from the organization and conveys them to the triumphant bettor.
              At a predefined time, the Babaji satta organization opens an
              irregular number. The triumphant bettor gets multiple times the
              cash he bet.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">
              How Much Profit You Can Earn From Babaji Satta ?
            </span>
          </div>

          <div className="intro container">
            <span>
              Assume in the event that somebody has wagered 10 rupees on a
              number, on the off chance that that number is opened, the client
              will get 10 x 90 = 900 Rupees. Also, clients will get 1800 rupees
              for 20 rupees, 2700 rupees for 30 rupees, 3600 rupees for 40
              rupees and 4500 rupees for 50 rupees. The client can put away as
              much cash as he needs on one number and can play many numbers as
              he needs.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">
              Is It Legal To Play Babaji Satta Game ?
            </span>
          </div>

          <div className="intro container">
            <span>
              The lawfulness of the{" "}
              <span id="lite-green">Babaji satta online</span> is not entirely
              settled by the nearby government where the game is being played.
              On the off chance that we discuss India, Wagering is unlawful, and
              assuming that discovered playing you might need to pay a weighty
              fine or serve a prison term. Still many individuals play Babaji
              satta in stowing away from the specialists. Babaji satta is very
              famous in India.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">
              How To Play Babaji Satta Online And Why ?
            </span>
          </div>

          <div className="intro container">
            <span>
              For what reason would it be advisable for us to play Babaji satta
              on the web? On the off chance that we discuss this, the basic
              response is that it is a lot simpler to play Babaji satta online.
              If you have any desire to play Babaji satta and remain protected
              from the police then, at that point, playing Babaji satta online
              could be a protected and better other option. In the realm of the
              web, getting on the web players is certainly not a simple
              assignment. However, we need to unequivocally explain here that as
              indicated by the Indian regulations wagering is unlawful, and
              assuming found wagering you might need to pay a strong fine.
              Assuming that you want you might find numerous applications on the
              Google Play store, which can help you to play the Babaji satta
              game on the web. All you have to do is to download the application
              and introduce it. Then, at that point, you can play the Babaji
              satta game at the solace of your home.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">
              What Happen When You Play Babaji Satta ?
            </span>
          </div>

          <div className="intro container">
            <span>
              Most people have the misconception that if they play{" "}
              <span id="lite-green">Babaji satta</span>, they will win and they
              will earn good money. But this happens quite the opposite. Those
              people get trapped in such games so badly that they lose
              everything and get ruined. As you know, only one number from 00 to
              99 opens in this game. This means that in this game, out of 100
              people only one man gets a lottery. The remaining 99 people only
              lose. And the whole money of the 99 losers is given to the winner.
              The chances of winning in this game are 1% out of 100. People know
              about this but still, they play this game till they are completely
              ruined.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">
              What Is The Reality Of Babaji Satta Game ?
            </span>
          </div>

          <div className="intro container">
            <span>
              Many individuals have various perspectives about Babaji satta
              Game. Some say it is a toss of the dice, some call it a round of
              Satta Lord <span id="lite-green">Babaji satta result</span>. As a
              matter of fact, the slip that should be eliminated from Matka
              ought to be finished before everybody. That is, it ought to be
              finished within the sight of the relative multitude of
              individuals. In any case, this isn't finished in the present time,
              rather the Babaji satta as per his own slip emerges. Which truly
              builds my doubts over this game much more. Since I think he
              pronounces the slip wherein not very many individuals are putting
              their cash. Because of this, the vast majority lose cash, which
              makes them more beneficial. A great many people call it a toss of
              the dice however as a general rule the story is something
              different. Along these lines, as we would see it, you ought to
              avoid this kind of betting or Babaji satta Matka in Hindi. It is
              really great for every one of you. Try sincerely and don't bring
              in cash and continue to sit on karma.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">
              What Is Babaji Satta? Complete Information ?
            </span>
          </div>

          <div className="intro container">
            <span>
              Discover Gali Faridabad Ghaziabad, Gurgaon, and so forth, Babaji
              satta these times are likewise unique. In any case, the level of
              the installment sum will be something similar. This is the way you
              can play wagering, in each city, there are a few wagering dealers,
              who take the bet, they can give you a little slip, all things
              considered, you will pay the equivalent whenever the opportunity
              comes, and you will obtain the outcome You can see, there are a
              great many destinations on the Web on which you can see the
              outcomes, on every one of the spots you will get a wide range of
              speculative outcomes .
            </span>
          </div>

          <div className="intro container">
            <span>
              , on every one of the spots you will get a wide range of
              speculative outcomes, on the knowledge you will likewise track
              down speculative graphs, for example, which day in which day
              Number came, is there speculative legitimate in India, Babaji
              satta no speculative lawful in India, dangerous in India is played
              covertly and furtively on the off chance that any sort of
              legitimate activity was on you Land, you might be condemned to a
              half year to 3 years.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">
              Why Babaji Satta Game Is So Famous In India ?
            </span>
          </div>

          <div className="intro container">
            <span>
              Hi companions today we will discuss{" "}
              <span id="lite-green">Babaji satta game</span>, as may be obvious,
              companions India has more populace, and India's work rate is
              pitiful, India has more individuals and the vast majority Private
              ventures do their business by carrying on with work, meanwhile
              comes Babaji satta, Babaji satta Game the vast majority play in
              the eagerness to bring in speedy cash, a person of Babaji satta
              Game There is no standard that you can play just with a standard
              sum, to that end individuals of India like the Babaji satta game,
              that is the reason the Babaji satta game is viral in India, and it
              is played a great deal.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">
              Where To Get Fast Babaji Satta Result ?
            </span>
          </div>

          <div className="intro container">
            <span>
              Bunches of individuals play Babaji satta every day because they
              are extremely dependent on it. They couldn't care less about their
              cash they simply need to play Babaji satta. They bet every day on
              various rounds of Babaji satta like Gali, Dishawar, Faridabad,
              Ghaziabad, and so on. The outcome timing of these Babaji satta
              games is fixed by the organization. For instance Dishawar Babaji
              satta result open at 5:00 AM, Faridabad satta result open at 6:15
              PM, Gaziyabad <span id="lite-green">Babaji satta result</span>{" "}
              open at 8:00 PM and Gali satta result open at 11:00 PM. So People
              groups hang tight for each game outcome where they have put away
              their cash. There are two different ways of getting Babaji satta
              Results. Assuming you need quick Babaji satta results you ought to
              remain in contact with your Dhaliwal. Because Dhaliwal is the main
              individual who can give you the quickest{" "}
              <span id="lite-green">Babaji satta result</span>. There is one
              more approach to getting satta results. You can Look through
              Babaji satta on google. You will find numerous sites which give
              Babaji satta results. Yet, this site update result is Somewhat
              late. Many run whatsapp gorups for Babaji satta result. You can
              likewise join that gathering for getting satta results.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">How To Get Babaji Satta Leak Number ?</span>
          </div>

          <div className="intro container">
            <span>
              Everybody needs to get the Babaji satta release number of Gali,
              Dishawar, Ghaziabad, and Faridabad game With the goal can create
              tremendous gain by dominating the match. In the event that you
              search on google, you will track down numerous sites on google
              where many individuals have posted their advertisements for giving{" "}
              <span id="lite-green">Babaji satta</span> leak Jodi
              straightforwardly from the organization. Be that as it may, for
              this they charged a weighty sum from you. Assuming you find any
              Such individual on the web If it's not too much trouble,
              reconsider paying him. Most of the individuals are con artists in
              this game. They have no association with the organization. They
              all are People who simply give you arbitrary numbers by saying
              that it is a release number straightforwardly from the
              organization. Some individuals request that you pay ahead of time
              and some individuals request that you pay a level of winning sum
              after you dominate the match. Assuming your individual advised you
              to pay in the wake of passing the game that doesn't imply that you
              will be in benefit or won't lose your cash. Because the individual
              who maintains that you should pay subsequent to passing the game
              will likewise give you a 2 or 4 Jodi of irregular numbers and they
              will advise you to put away large cash on these number. Assuming
              that you contribute a large sum to that numbers and in the event
              that those numbers get out as a triumphant number, you will bring
              in colossal cash and you need to impart a level of winning add up
              to that individual. In short, you can't get Babaji satta's release
              number from any place. It's all a trape to bring in cash from
              clients like you.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">
              Can You Become Rich By Playing Babaji Satta Game.
            </span>
          </div>

          <div className="intro container">
            <span>
              The most straightforward method for bringing in cash without
              accomplishing any difficult work is by betting. It might imply
              facing a lot of challenges yet the prize makes it worth the
              effort. Babaji satta Matka is one of those types of betting in
              which you can crapload of cash in an exceptionally brief time
              frame length. Indeed, you can get rich by playing the Babaji satta
              game. Be that as it may, for this, you ought to know how to play
              the Babaji satta game well indeed. And furthermore, Karma is one
              of the vital variables in the round of betting. In the event that
              you're one of the people who has karma as an afterthought, you
              have an extraordinary opportunity to break the game. place of the
              discussion is about the games that the speculators can win
              reliably when they play with the right system. Just put away that
              lot of cash which could bring back a decent benefit for yourself
              and wouldn't hurt your pocket assuming you're losing it.
            </span>
          </div>

          <div className="intro">
            <span id="orange-color">Disclaimer</span>
          </div>

          <div className="intro container">
            <span>
              This site is being run exclusively through the proclaiming of
              diversion. The site proprietor and we don't have anything to do
              with any speculative organization. Give cash exchanges on your
              obligation. The site proprietor will not be answerable for any
              misrepresentation.
            </span>
          </div>

          <div className="green-white-line"></div>

          <div className="intro">
            <span id="orange-color">BABAJI SATTA RESULT</span>
          </div>

          <div className="intro container">
            <span>
              Satta result, satta king, gali result, satta matka, matka result,
              satta king result, satta club, matka satta, satta king chart,
              satta result, delhi satta, disawar satta king, gali satta, disawar
              satta, satta king faridabad, gali satta king, gali disawar satta
              king, matka indian, satta king online, satta king satta king,
              indian satta, delhi disawar, gali disawar, satta disawar, satta
              game, play india, satta gali, delhi disawar satta,
              sattaresult,Disawar satta, Satta king up, Satta result, Satta king
              result, Satta king online, Gali result, Desawar result, Satta king
              chart, Satta king live, Gali satta, Deshawar live result, Gali
              live result, Satta matka, Satta matka king, Satta king up, Satta
              king 20222 chart, Satta king desawar, Satta king gali, Gali live
              result, Disawar live result, Satta Number, Satta Game, Gali
              Number, Delhi Satta king, Satta Bazar, Black satta king, Gali
              Single Jodi, Black Satta Result, Gali satta king result, Disawar
              satta king result
            </span>
          </div>

          <div className="green-white-line"></div>

          <div className="intro atag">
            <div>
              <a href="HomePage">Home</a>
            </div>
            <div>
              <a href="#">About</a>
            </div>
            <div>
              {" "}
              <a href="#">Contact</a>
            </div>
            <div>
              <a href="#">faq</a>
            </div>
            <div>
              <a href="#">Disclaimer</a>
            </div>
            <div>
              <a href="#">Privacy Policy</a>
            </div>
          </div>

          <div className="intro atag">
            <div>
              <a href="#">sitemap</a>
            </div>
          </div>
        </div>

        <div className="social-cl-fix">
          <div className="media-icons-icon-first">
            <div className="whatsapp-icon">
              <a href="https://chat.whatsapp.com/JyEYMj1pRE31M2eZX6yIK0">
                <img src={Whatsapp} alt="" />
              </a>
            </div>

            <div className="whatsapp-icon">
              <a href="https://www.instagram.com/baba.ji.matka/?igshid=MzRlODBiNWFlZA%3D%3D">
                <img src={Instagram} alt="" />
              </a>
            </div>
          </div>

          <div className="media-icons-icon-second">
            <div className="whatsapp-icon">
              <a href="https://bababooki.in/login">
                <img src={BabaLogo} alt="" />
              </a>
            </div>

            <div className="whatsapp-icon">
              <a href="https://www.facebook.com/groups/918829315482403/?ref=share_group_link">
                {" "}
                <img src={Facebook} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* ----------Content Section end------- */}
      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />
    </div>
  );
}

export default ResultHistory;
