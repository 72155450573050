import { useQuery } from "@tanstack/react-query";
import apiClient from "../constants/api-client";

const fetchReport = () => apiClient.get(`contacting-policy/`).then(res => res.data);

const useReport = () => {
  const { data: Report, error, isLoading, } = useQuery({
    queryKey: ['Report Bgm'],
    queryFn: () => fetchReport(),
  });

  return { Report, error, isLoading, };
};

export default useReport;
