import React, { useEffect, useState } from "react";
import InsufficientBalance from "./InsufficientBalance";
import useHarrafGame from "../hooks/useHarrafGame";
import usePlaceBet from "../hooks/usePlaceBet";
import useWallet from "../hooks/useWallet";
import NotificationPopup from "./NotificationPopup";
import { useNavigate } from "react-router-dom";

const harrafNumbers = [
  "000",
  "111",
  "222",
  "333",
  "444",
  "555",
  "666",
  "777",
  "888",
  "999",
];

const HarrafGame = ({ market }) => {
  const {
    andarNumbers,
    baharNumbers,
    setAndarNumber,
    setBaharNumber,
    allBets,
    calculateTotalPoints,
  } = useHarrafGame(market);
  const placeBet = usePlaceBet();
  const { wallet } = useWallet();

  const [sufficientBalance, setSufficientBalance] = useState(true);
  const [showNoBalance, setShowNoBalance] = useState(!sufficientBalance);

  useEffect(() => {
    setSufficientBalance(wallet?.total_amount >= calculateTotalPoints());
  }, [wallet, calculateTotalPoints()]);

  const navigateTo = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (placeBet.isSuccess) {
        navigateTo(`/AllGames`);
      }
    }, 1000);
  }, [placeBet]);

  return (
    <div
      className="tab-pane fade"
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      <div className="black-line"></div>

      <div className="point-count">
        <span>Points Remaining: {wallet?.total_amount}</span>
        <span>Points Added: {calculateTotalPoints()}</span>
      </div>

      <div className="top-content black-color">
        <span>Andar Harraf</span>
      </div>

      <div>
        <div className="row input-input">
          {harrafNumbers.map((harrafNumber) => (
            <div key={harrafNumber} className="col-3 col-md-3 mb-3 column-5">
              <div className="input-number">
                <span>{harrafNumber}</span>
              </div>
              <input
                type="number"
                className="form-control"
                maxLength={4}
                value={
                  andarNumbers.find(
                    (andarNumberInList) =>
                      andarNumberInList.number === harrafNumber[0]
                  )?.points || ""
                }
                onChange={(e) =>
                  setAndarNumber({
                    number: harrafNumber.charAt(0),
                    points: e.target.value,
                  })
                }
              />
            </div>
          ))}
        </div>
      </div>

      <div className="top-content black-color">
        <span>Bahar Harraf</span>
      </div>

      <div>
        <div className="row  input-input">
          {harrafNumbers.map((harrafNumber) => (
            <div key={harrafNumber} className="col-3 col-md-3 mb-3 column-5">
              <div className="input-number">
                <span>{harrafNumber}</span>
              </div>
              <input
                type="number"
                className="form-control"
                maxLength={4}
                value={
                  baharNumbers.find(
                    (baharNumberInList) =>
                      baharNumberInList.number === harrafNumber[0]
                  )?.points || ""
                }
                onChange={(e) =>
                  setBaharNumber({
                    number: harrafNumber.charAt(0),
                    points: e.target.value,
                  })
                }
              />
            </div>
          ))}
        </div>
      </div>

      {placeBet.isSuccess ? (
        <NotificationPopup isSuccess message={"Bet Placed Successfully!"} />
      ) : (
        placeBet.isError && (
          <NotificationPopup
            message={`Could not place bet..  ${
              placeBet.error?.response?.data?.error || ""
            }`}
          />
        )
      )}

      <div className="row">
        <div className="col-md-12">
          <button
            disabled={
              placeBet.isPending ||
              placeBet.isSuccess ||
              allBets().filter((bet) => bet.points).length === 0
            }
            type="button"
            className="btn btn-primary btn-blcok width-change"
            // onClick={() => setShowNoBalance(!sufficientBalance)}
            onClick={() => {
              return sufficientBalance
                ? placeBet.mutate(allBets().filter((bet) => bet.points))
                : // ? console.log(allBets().filter((bet) => bet.points))
                  setShowNoBalance(!showNoBalance);
            }}
          >
            Place Bet
          </button>
        </div>
      </div>

      <InsufficientBalance isShow={showNoBalance} showNow={setShowNoBalance} />
    </div>
  );
};

export default HarrafGame;

// =============================================================B E T S=====================================================================

// ===============================A N D A R====B E T S===========000===================100=======================================

// [
//   {
//       "betKey": 0,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 1,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 2,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 3,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 4,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 5,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 6,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 7,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 8,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 9,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   }
// ]

// ===============================A N D A R====B E T S===========111===================100=======================================

// [
//   {
//       "betKey": 10,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 11,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 12,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 13,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 14,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 15,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 16,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 17,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 18,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 19,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Andar",
//       "market": "MORNING STAR"
//   }
// ]

// ===============================B A H A R====B E T S===========000===================100=======================================

// [
//   {
//       "betKey": 0,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 10,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 20,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 30,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 40,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 50,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 60,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 70,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 80,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   },
//   {
//       "betKey": 90,
//       "points": 10,
//       "betType": "Harraf",
//       "harrafType": "Bahar",
//       "market": "MORNING STAR"
//   }
// ]

// ===============================B A H A R====B E T S===========111===================100=======================================

// [
//     {
//         "betKey": 1,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     },
//     {
//         "betKey": 11,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     },
//     {
//         "betKey": 21,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     },
//     {
//         "betKey": 31,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     },
//     {
//         "betKey": 41,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     },
//     {
//         "betKey": 51,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     },
//     {
//         "betKey": 61,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     },
//     {
//         "betKey": 71,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     },
//     {
//         "betKey": 81,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     },
//     {
//         "betKey": 91,
//         "points": 10,
//         "betType": "Harraf",
//         "harrafType": "Bahar",
//         "market": "MORNING STAR"
//     }
// ]
