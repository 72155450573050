import { createContext, useContext, useState } from 'react';


const UserContext = createContext();

export const UserProvider = ({ children }) => {

  // const storedUserId = JSON.parse(localStorage.getItem('userId'));
  const storedMobileNumber = JSON.parse(localStorage.getItem('mobileNumber'));
  // const [userId, setUserId] = useState(storedUserId || null);
  const [mobileNumber, setMobileNumber] = useState(storedMobileNumber || null);

  const login = (mobileNumber) => {
    // setUserId(userData);
    setMobileNumber(mobileNumber)
    // localStorage.setItem('userId', JSON.stringify(userData));
    localStorage.setItem('mobileNumber', JSON.stringify(mobileNumber));
  };

  const logout = () => {
    setMobileNumber(null);
    localStorage.removeItem('mobileNumber');
  };

  return (
    <UserContext.Provider value={{ mobileNumber, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
