// Header.js
import React from "react";
import { Link } from "react-router-dom";
import NavFooter from "../components/NavFooter";
import HeaderMain from "../components/HeaderMain";
import point from "../images/point.png";
import BabaJi from "../images/baba-ji.png";
import fire from "../images/fire.png";
import useTermsAndCondition from "../hooks/useTermsAndCondition";
import TopTitle from "../components/TopTitle";
import Footer from "../components/Footer";

function TermsAndCondition() {
  const { TermsAndCondition } = useTermsAndCondition();

  return (
    <>
      <HeaderMain pageTitle={<Link to="/">Home</Link>} />

      <TopTitle />

      <div className="top-heading-top gap-extra">
        <div
          dangerouslySetInnerHTML={{ __html: TermsAndCondition?.heading }}
        ></div>
      </div>

      <div className="container tearms-and-condition-container">
        <div
          dangerouslySetInnerHTML={{ __html: TermsAndCondition?.paragraph }}
        ></div>
      </div> 
      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />
      <Footer/>
    </>
  );
}

export default TermsAndCondition;
