import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavFooter from "../components/NavFooter";
import HeaderMain from "../components/HeaderMain";
import BabaJi from "../images/com-logo.svg";
import useAppDetails from "../hooks/useAppDetails";
import TopTitle from "../components/TopTitle";
import Footer from "../components/Footer";

function AppDetails() {
  const { details } = useAppDetails();
  return (
    <div>
      <HeaderMain pageTitle={"App Details"} />

      <TopTitle/>
      <div className="baba-ji">
        <img src={BabaJi} alt="" />
      </div>

      <div className="container">
        {
          <div className="details-contant">
            <h6 >App Details</h6>
            <div className="details-box">
              TM No : {details?.tm_no}
            </div>
            <div className="details-box">CIN: {details?.cin}</div>
            <div className="details-box">
            ISO Number: {details?.iso}
            </div>
            <div className="details-box">
              TAN : {details?.tan}
            </div>
            <div className="details-box">
              URN : {details?.urn}
            </div>
            <div className="details-box">
              GST: {details?.gst}
            </div>
            <div className="details-box">
              PAN: {details?.pan}
            </div>
          </div>
        }
      </div>

      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />
      <Footer/>
    </div>
  );
}

export default AppDetails;
