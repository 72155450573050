// Header.js
import React, { useEffect, useState } from "react";
import "../App.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaBars, FaBell } from "react-icons/fa";
import Background from "../images/background-profile.png";
import BabaJi from "../images/com-logo.svg";
import Phone from "../images/smartphone-call.png";
import swords from "../images/swords.png";
import socialMedia from "../images/social-media.png";
import Bonus from "../images/bonus.png";
import economy from "../images/economy.png";
import TermsAndCondition from "../images/terms-and-conditions.png";
import rating from "../images/rating.png";
import Exit from "../images/exit.png";
import instagram from "../images/instagram.png";
import facebook from "../images/facebook.png";
import chat from "../images/chat-icon.png";
import { TfiClose } from "react-icons/tfi";
import ShareLink from "../images/link.png";
import Whatsapp from "../images/whatsapp.png";
import Instagram from "../images/instagram.png";
import Twitter from "../images/twitter.png";
import Facebook from "../images/facebook.png";
import Gmail from "../images/gmail.png";
import Linkdin from "../images/linkedin.png";
import { Modal } from "react-bootstrap";
import share from "../images/share.png";
import { IoClose } from "react-icons/io5";
import { useUser } from "../constants/UserContext";
import useWallet from "../hooks/useWallet";
import { BsCheck2 } from "react-icons/bs";

import { useGameSettings, usePlayerData } from "../hooks/useHome";
import { RxColorWheel } from "react-icons/rx";

import useCreateNotificatonSeen from "../hooks/useCreateNotificatonSeen";
import useNotificationSeen, {
  useGameHostBubbleSeen,
} from "../hooks/useNotificationSeen";
import ReferAndEarn from "../screens/ReferAndEarn";
import SpinAndEarn from "../screens/SpinAndEarn";
import KYCModal from "../screens/KYCModal";

function HeaderMain({ pageTitle, isSeen = false }) {
  const { mobileNumber, logout } = useUser();
  const gameSetting = useGameSettings();
  const { notificationSeen, refetch } = useNotificationSeen();
  const { gameHostBubble } = useGameHostBubbleSeen();
  const [navWidth, setNavWidth] = useState(0);
  const [showReferModal, setShowReferModal] = useState(false);
  const [showSpinModal, setShowSpinModal] = useState(false);

  const playerInfo = usePlayerData();
  const { wallet } = useWallet();
  const navigate = useNavigate();
  const handleCloseKyc = () => setShowModalKyc(false);
  const handleShowKyc = () => setShowModalKyc(true);
  const [showModalKyc, setShowModalKyc] = useState(false);


  useEffect(() => {
    playerInfo.mutate({ mobile: mobileNumber });
  }, []);

  const openNav = () => {
    setNavWidth(300);
  };

  const closeNav = () => {
    setNavWidth(0);
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    isSeen && refetch();
  }, []);

  return (
    <div className="main-header">
      <div className="container header-min-width">
        <div className="row align-items-center ">
          <div className="col-md-4 col-2">
            <div
              id="mySidenav"
              style={{ width: `${navWidth}px` }}
              className="sidenav scrollbar-hide"
            >
              <div className="text-end">
                <Link
                  to="javascript:void(0)"
                  className="closebtn"
                  onClick={() => closeNav()}
                >
                  <TfiClose />
                </Link>
              </div>

              <div className="navheader ">
                <div className="main-conatiner">
                  <div className="background-area">
                    <img src={Background} alt="" />
                    <div className="babaji-center-image">
                      <img src={BabaJi} alt="" />
                      <Link to="/EditProfile">
                        <button
                          type="button"
                          className="btn Btn-change btn-set-slid"
                        >
                          Edit Profile
                        </button>
                      </Link>
                      <div className="user-content pt-2 block">
                        User ID: {mobileNumber}
                      </div>
                      <div className="user-content block mt-1" style={{ fontWeight: "bold" }}>

                        <button onClick={handleShowKyc} disabled={playerInfo?.data?.kyc ? true : false} className="btn-vi">
                          {playerInfo && (playerInfo?.data?.kyc ? <span className="verified-btn">   <BsCheck2 />KYC Verified!</span> : <span className="incomplete-btn">   <TfiClose /> Incomplete KYC!</span>)}

                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ul>
                <Link to="/AppDetails">
                  <div className="slid-content">
                    <img src={Phone} alt="" />
                    <p>App Details</p>
                  </div>
                </Link>

                <Link to="/MyPlayHistroy">
                  <div className="slid-content">
                    <div>
                      <img src={swords} alt="" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>My Play History</p>
                      <span>अपनी खेली हुई गेम देखने के लिए यहाँ दबाये।</span>
                    </div>
                  </div>
                </Link>

                <Link to="/GamePosting">
                  <div className="slid-content game-host-bubble">
                    <div>
                      <img src={socialMedia} alt="" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>Game Posting</p>
                      <p
                        className="circle-notification circle-notification-game-host "
                        style={{
                          display:
                            gameHostBubble && gameHostBubble?.game_post
                              ? "block"
                              : "none",
                        }}
                      >
                        {gameHostBubble?.game_post &&
                          gameHostBubble.game_post}
                      </p>
                      <span>गेम की गैसिंग देखने के लिए यहां दबाए।</span>
                    </div>
                  </div>
                </Link>

                <Link to="/BonusReport">
                  <div className="slid-content">
                    <div>
                      <img src={Bonus} alt="" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>Bonus Report</p>
                      <span>अपनी गेम का कमीशन देखने के लिए यहाँ दबाये।</span>
                    </div>
                  </div>
                </Link>

                {/* <Link to="/DecemberMonthResults"> */}
                <div onClick={() => window.open('https://bgmgameresult.in/', '_blank')} className="slid-content">
                  <div>
                    <img src={economy} alt="" />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Result History</p>
                    <span>गेम के रिजल्ट देखने के लिए यहाँ दबाये।</span>
                  </div>
                </div>
                {/* </Link> */}

                <Link to="/TermsAndCondition">
                  <div className="slid-content">
                    <div>
                      <img src={TermsAndCondition} alt="" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>Term and Condition</p>
                      <span>नियम एवं शर्ते।</span>
                    </div>
                  </div>
                </Link>

                {/* </Link> */}

                <div className="slid-content" onClick={() => setShowReferModal(true)}>
                  <div>
                    <img src={economy} alt="" />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Refer and Earn</p>
                    <span>रेफर करें और स्पिन कमाएं।</span>
                  </div>
                </div>
                <ReferAndEarn showModal={showReferModal} setShowModal={setShowReferModal} />

                <div className="slid-content" onClick={() => setShowSpinModal(true)}>
                  <div>
                    <RxColorWheel />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p>Spin and Win</p>
                    <span>स्पिन करें इनाम जीते।</span>
                  </div>
                </div>
                <SpinAndEarn showModal={showSpinModal} setShowModal={setShowSpinModal} />

                <Link to="#">
                  <div className="slid-content">
                    <div>
                      <img src={rating} alt="" />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>Rate Our App</p>
                      <span>हमारी एप्लिकेशन को सुझाव देने के लिए दबाये। </span>
                    </div>
                  </div>
                </Link>

                <Link to="/">
                  <div className="slid-content" onClick={() => logout()}>
                    <div>
                      <img src={Exit} alt="" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p>Logout</p>
                    </div>
                  </div>
                </Link>
              </ul>

              <ul>
                <div className="social-media-icons" style={{ paddingBottom: 100 }}>
                  <div className="media-media">
                    <a href={gameSetting?.data?.contact_links?.instagram}>
                      <img src={instagram} alt="" />
                    </a>
                    <span>इंस्टाग्राम पर फॉलो करे</span>
                  </div>

                  <div className="media-media">
                    <a href={gameSetting?.data?.contact_links?.facebook}>
                      <img src={facebook} alt="" />
                    </a>
                    <span>सॉलिड गेम के लिए हमारा </span>
                    <span>फेसबुक ग्रुप ज्वाइन करे</span>
                  </div>

                  <div className="media-media">
                    <a href="/chat-screen">
                      <img src={chat} alt="" />
                    </a>
                    <span>हमसे बात करने के </span>
                    <span>लिए चैट पे क्लिक करे</span>
                  </div>
                </div>
              </ul>
            </div>

            <span
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={() => openNav()}
            >
              &#9776;
            </span>
          </div>

          <div className="col-md-4 col-8">
            <div className="row align-items-center text-center ">
              <div className="col-md-3 col-3">
                <span className="hd-t">{pageTitle}</span>
              </div>

              <div className="col-md-6 col-6">
                <button
                  type="button"
                  className="btn btn-primary btn-blcok"
                  onClick={() => navigate(0)}
                >
                  Refresh
                </button>
              </div>

              <div className="col-md-3 col-3">
                <span className="hd-t">
                  Points:<small>{wallet?.wallet}</small>
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-4 text-end col-2">
            <Link to="/Notification" className="text-dark">
              <div
                className="circle-notification"
                style={{
                  display:
                    notificationSeen &&
                      notificationSeen.not_selected_notifications
                      ? "block"
                      : "none",
                }}
              >
                {notificationSeen?.not_selected_notifications &&
                  notificationSeen.not_selected_notifications}
              </div>

              <FaBell style={{ fontSize: "24px" }} />
            </Link>
          </div>

          {/* <div className="col-md-4 text-end col-2">
            <Link to="/Notification" className="text-dark">
              <FaBell
                style={{
                  fontSize: "24px",
                  color: hasUnseenNotifications ? "red" : "black",
                }}
              />
            </Link>
          </div> */}
        </div>
      </div>


      <KYCModal showModal={showModalKyc} handleClose={handleCloseKyc} />


      <div id="mySidenav" className="sidenav">
        <a
          href="javascript:void(0)"
          className="closebtn"
          onClick={() => closeNav()}
        >
          &times;
        </a>
        <a href="#">About</a>
        <a href="#">Services</a>
        <a href="#">Clients</a>
        <a href="#">Contact</a>
      </div>
    </div>
  );
}

export default HeaderMain;
