// Header.js
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import NavFooter from '../components/NavFooter';
import HeaderInner from '../components/HeaderInner';
import { IoArrowBack } from 'react-icons/io5';
import JodiGame from './JodiGame';
import ManualGame from './ManualGame';
import HarrafGame from './HarrafGame';
import CrossingGame from './CrossingGame';
import CopyPasteGame from './CopyPasteGame';
import { convertToSlug } from './AllGames';
import useMarkets from '../hooks/useMarkets';
import useReverseTimer from '../hooks/useCountdownTimer';
import useCountdownTimer from '../hooks/useCountdownTimer';
import Footer from '../components/Footer';

function PlayingGame() {
  const [currentMarket, setCurrentMarket] = useState(null)


  const { timeRemaining, setTargetTime } = useCountdownTimer()
  const { marketId } = useParams()
  const { markets } = useMarkets();

  useEffect(() => {
    if (markets) {
      const selectedMarket = markets?.find(market => market.id == marketId);
      setCurrentMarket(selectedMarket);
      setTargetTime(selectedMarket?.close_time);
    }
  }, [markets, currentMarket, marketId]);


  return (
    <>

      <div className="inner-header">
        <div className="container">

          <Link to="/AllGames" className="back-ic">
            <IoArrowBack style={{ fontSize: '20px' }} />
            Back
          </Link>

        </div>
      </div>



      {/* ----------Content section start --------- */}
      <div className="container">
        <span className='top-heading'>{currentMarket?.market}</span>
        {/* <div className="top-content">
        <span>Maximum Bet Will Change After Timer : 00 : 00 : 00</span>
      </div> */}


        <div className="top-content">
          <span> {`${timeRemaining?.hours}:${timeRemaining?.minutes}:${timeRemaining?.seconds}`}</span>
        </div>
        {/* Maximum Bet Will Change After Timer: */}

        <div className="tabs tabs-space ">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Jodi</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Manual</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Harraf</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-crossing-tab" data-bs-toggle="pill" data-bs-target="#pills-crossing" type="button" role="tab" aria-controls="pills-crossing" aria-selected="false">Crossing</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-copy-paste-tab" data-bs-toggle="pill" data-bs-target="#pills-copy-paste" type="button" role="tab" aria-controls="pills-copy-paste" aria-selected="false">Copy Paste</button>
            </li>
          </ul>

        </div>




        {/* ----------Games section start----------- */}

        <div className="tab-content" id="pills-tabContent">

          <JodiGame market={currentMarket} />

          <ManualGame market={currentMarket} />

          <HarrafGame market={currentMarket} />

          <CrossingGame market={currentMarket} />

          <CopyPasteGame market={currentMarket} />

        </div>

        {/* ----------Games section End----------- */}

      </div>

      {/* ----------Content section end --------- */}

      {/* <NavFooter/>  */}
      <Footer />
    </>
  );
}

export default PlayingGame;
