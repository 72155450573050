// Header.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavFooter from "../components/NavFooter";
import send from "../images/send.png";
import BabaJi from "../images/com-logo.svg";
import { IoArrowBack } from "react-icons/io5";
import useGamePosting, { useGamePostSeen } from "../hooks/useGamePosting";
import ConvertTime from "../hooks/useConvertTime";
import useCreateGamePost from "../hooks/useCreateGamePost";
import { useUser } from "../constants/UserContext";
import { usePlayerData } from "../hooks/useHome";
import { separateDateAndTime } from "./BonusReport";
import NotificationPopup from "./NotificationPopup";
import Footer from "../components/Footer";

function GamePosting() {
  const [message, setMessage] = useState("");
  const [playerData, setPlayerData] = useState(null);
  const { gamePosting, refetch } = useGamePosting();
  const { mobileNumber } = useUser();
  const GamePostSeen = useGamePostSeen({ mobileNumber });
  const gamepost = useCreateGamePost();

  const playerInfo = usePlayerData();

  const postGame = () => {
    const formData = new FormData();
    formData.append("mobile", mobileNumber);
    formData.append("text", message);
    formData.append("message_by", "User");
    formData.append("name", playerData?.name || " ");
    if (message) {
      gamepost.mutate(formData);
      setMessage('')
    }
  };

  useEffect(() => {
    playerInfo.mutate({ mobile: mobileNumber });
  }, []);
  useEffect(() => {
    playerInfo.isSuccess && setPlayerData(playerInfo.data);
  }, [playerInfo]);
  useEffect(() => {
    gamepost.isSuccess && refetch();
  }, [gamepost]);

  useEffect(() => {
    GamePostSeen.mutate(mobileNumber);
  }, []);

  return (
    <>
      <div className="inner-header">
        <div className="container game-posting-posting-container">
          <Link to="/HomePage" className="back-ic">
            <IoArrowBack style={{ fontSize: "20px" }} />
            Back
          </Link>

          <div>
            <img src={BabaJi} alt="" style={{ width: 55, cursor: "pointer" }} />
          </div>
        </div>
      </div>

      <div className="top-heading-top">
        <h6>Game Posting</h6>
      </div>

      {gamepost.isError && (
        <NotificationPopup
          message={`Failed! ${gamepost.error.response.data.error || ""}`}
        />
      )}

      <div className="container game-posting-container">
        <div className="boxses">
          {gamePosting?.map((message) => (
            <div className="box-inner">
              <div className="box-left-area">
                <span>{message.name}</span>
              </div>
              <div className="box-right-area">
                <p>{message.text}</p>
                {/* <span id="text-set">{message.created_at}</span> */}( Date:
                {` ${separateDateAndTime(message.created_at).date
                  } ${ConvertTime(
                    separateDateAndTime(message.created_at).time
                  )}`}{" "}
                )
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="message-box">
        <div class=" input-changes container">
          <div class="col input-changes-btn">


            <textarea class="form-control"
              type="text"

              placeholder="Type Messgae"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="3"></textarea>



            <a onClick={() => postGame()} style={{ cursor: "pointer" }}>
              <img src={send} alt="" />{" "}
            </a>
          </div>
        </div>
      </div>

      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />
      <Footer />
    </>
  );
}

export default GamePosting;
