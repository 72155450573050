import React, { useEffect, useState } from "react";
import InsufficientBalance from "./InsufficientBalance";
import useWallet from "../hooks/useWallet";
import useSetJodis from "../hooks/useSetJodis";
import usePlaceBet from "../hooks/usePlaceBet";
import NotificationPopup from "./NotificationPopup";
import { useNavigate } from "react-router-dom";

const JodiGame = ({ market }) => {
  const jodiFields = Array.from({ length: 100 }, (_, index) =>
    index.toString().padStart(2, "0")
  );

  const { wallet } = useWallet();
  const [sufficientBalance, setSufficientBalance] = useState(true);
  const [showNoBalance, setShowNoBalance] = useState(!sufficientBalance);

  const { jodiPoints, setJodiInput, jodis } = useSetJodis();
  const placeBet = usePlaceBet();
  const navigateTo = useNavigate();

  useEffect(() => {
    setSufficientBalance(wallet?.total_amount >= jodiPoints);
  }, [wallet, jodiPoints]);

  useEffect(() => {
    setTimeout(() => {
      if (placeBet.isSuccess) {
        navigateTo(`/AllGames`);
      }
    }, 1000);
  }, [placeBet]);

  // const jodiFields = Array.from({ length: 100 }, (_, index) => index);

  return (
    <div
      className="tab-pane fade show active"
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="black-line"></div>

      <div className="point-count">
        <span>Points Remaining: {wallet?.total_amount}</span>
        <span>Points Added: {jodiPoints}</span>
      </div>

      <form>
        <div className="row  input-input">
          {jodiFields.map((jodiField) => (
            <div className="col-3 col-md-3 column-5 mb-3" key={jodiField}>
              <div className="input-number">
                <span>{jodiField}</span>
              </div>
              <input
                type="Number"
                className="form-control"
                onChange={(e) =>
                  setJodiInput({
                    betKey: jodiField,
                    points: e.target.value,
                    betType: "Jodi",
                    market: market?.market,
                  })
                }
              />
            </div>
          ))}
        </div>
      </form>

      {placeBet.isSuccess ? (
        <NotificationPopup isSuccess message={"Bet Placed Successfully!"} />
      ) : (
        placeBet.isError && (
          <NotificationPopup
            message={`Could not place bet..  ${
              placeBet.error?.response?.data?.error || ""
            }`}
          />
        )
      )}
      {/* {placeBet.isError && console.log(placeBet.error)} */}

      <div className="row">
        <div className="col-md-12">
          <button
            type="button"
            disabled={
              placeBet.isPending || placeBet.isSuccess || jodis.length === 0
            }
            className="btn btn-primary btn-block width-change"
            // onClick={() => setShowNoBalance(!showNoBalance)}
            onClick={() => {
              return sufficientBalance
                ? placeBet.mutate(jodis.filter((bet) => bet.points))
                : setShowNoBalance(!showNoBalance);
            }}
          >
            Submit
          </button>
        </div>
      </div>

      <InsufficientBalance isShow={showNoBalance} showNow={setShowNoBalance} />
    </div>
  );
};

export default JodiGame;
