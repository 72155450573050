// Header.js
import React, { useEffect } from "react";
import NavFooter from "../components/NavFooter";
import HeaderMain from "../components/HeaderMain";
import useNotification from "../hooks/useNotification";
import ConvertTime from "../hooks/useConvertTime";
import { imageApiClient } from "../constants/api-client";
import { useUser } from "../constants/UserContext";
import { Link } from "react-router-dom";
import useCreateNotificatonSeen from "../hooks/useCreateNotificatonSeen";
import useNotificationSeen from "../hooks/useNotificationSeen";
import Footer from "../components/Footer";

function separateDateAndTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const year = dateTime.getUTCFullYear();
  const month = dateTime.getUTCMonth() + 1;
  const day = dateTime.getUTCDate();

  const hours = dateTime.getUTCHours();
  const minutes = dateTime.getUTCMinutes();
  const seconds = dateTime.getUTCSeconds();

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return { date: formattedDate, time: formattedTime };
}

function Notification() {
  const { notifications } = useNotification();
  const { mobileNumber } = useUser();

  const seeNotif = useCreateNotificatonSeen();

  useEffect(() => {
    seeNotif.mutate(mobileNumber);
  }, []);






  return (
    <>
      <HeaderMain pageTitle={<Link to="/">Home</Link>} isSeen />

      <div className="top-heading-top">💥 BGM NOTIFICATION 💥</div>

      <div className="container notification-conatiner">
        <div className="boxses">
          {notifications?.map((notification) => (
            <div
              key={notification.id}
              className="box-inner width-set-inner-box"
            >
              
              <div className="box-left-area">
                <span id="orange-color">{notification.heading}</span>
                <span className="">{notification.message}</span>
                <span>
                  <a href={notification.link}>{notification.link}</a>
                </span>

                <span>
                  {notification.file && (
                    <div>
                      {notification.file.endsWith(".mp3") && (
                        <audio controls>
                          <source
                            src={imageApiClient + notification.file}
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio element.
                        </audio>
                      )}
                      {(notification.file.endsWith(".png") ||
                        notification.file.endsWith(".jpg") ||
                        notification.file.endsWith(".jpeg") ||
                        notification.file.endsWith(".gif")) && (
                          <img
                            src={imageApiClient + notification.file}
                            alt="File Attachment"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100px",
                              borderRadius: 10,
                            }}
                          />
                        )}
                      {(notification.file.endsWith(".mp4") ||
                        notification.file.endsWith(".webm") ||
                        notification.file.endsWith(".ogg")) && (
                          <video
                            controls
                            style={{
                              maxWidth: "100%",
                              maxHeight: "200px",
                            }}
                          >
                            <source
                              src={imageApiClient + notification.file}
                              type="video/mp4"
                            />
                            <source
                              src={imageApiClient + notification.file}
                              type="video/webm"
                            />
                            <source
                              src={imageApiClient + notification.file}
                              type="video/ogg"
                            />
                            Your browser does not support the video element.
                          </video>
                        )}
                    </div>
                  )}
                </span>
              </div>

              <div className="box-right-area right-box-area-set">
                <span id="text-set">
                  ( Date:
                  {` ${separateDateAndTime(notification.created_at).date
                    } ${ConvertTime(
                      separateDateAndTime(notification.created_at).time
                    )}`}{" "}
                  )
                </span>
              </div>

            </div>
          ))}
        </div>
      </div>

      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />
      <Footer />
    </>
  );
}

export default Notification;
