import React, { useEffect, useRef, useState } from "react";
import InsufficientBalance from "./InsufficientBalance";
import useSetJodis from "../hooks/useSetJodis";
import useManualGame from "../hooks/useManualGame";
import usePlaceBet from "../hooks/usePlaceBet";
import useWallet from "../hooks/useWallet";
import NotificationPopup from "./NotificationPopup";
import { useNavigate } from "react-router-dom";

const ManualGame = ({ market }) => {
  const [sufficientBalance, setSufficientBalance] = useState(true);
  const [showNoBalance, setShowNoBalance] = useState(!sufficientBalance);

  const { wallet } = useWallet();
  const placeBet = usePlaceBet();

  const {
    handleNumberChange,
    handlePointsChange,
    calculateTotalPoints,
    calculateTotalPointsAdded,
    setPoints,
    manualInputs,
    inputRefs,
  } = useManualGame();

  const manualBets = () =>
    manualInputs.map((manualInput) => ({
      betKey: manualInput.betKey,
      points: manualInput.points,
      betType: "Jodi",
      jodiType: "Jodi",
      market: market?.market,
    }));

  useEffect(() => {
    setSufficientBalance(wallet?.total_amount >= calculateTotalPointsAdded());
  }, [wallet, calculateTotalPointsAdded()]);

  const navigateTo = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (placeBet.isSuccess) {
        navigateTo(`/AllGames`);
      }
    }, 1000);
  }, [placeBet]);

  const manualFields = Array.from({ length: 10 }, (_, index) => index);

  return (
    <div
      className="tab-pane fade"
      id="pills-profile"
      role="tabpanel"
      aria-labelledby="pills-profile-tab"
    >
      <div className="black-line"></div>

      <div className="point-count">
        <span>Points Remaining: {wallet?.total_amount}</span>
        <span>Points Added: {calculateTotalPointsAdded()}</span>
      </div>

      <div className="row">
        <div className="col-md-8 col-8">
          <div className="top-content black-color">
            <span>Jodi</span>
          </div>

          {manualFields.map((currentManualField) => (
            <div
              key={currentManualField}
              id={currentManualField}
              className="row input-input input-color-change"
            >
              {[1, 2, 3, 4, 5].map((numberIndex) => (
                <div key={numberIndex} className="col column-20">
                  <input
                    type="number"
                    className="form-control"
                    maxLength={2}
                    ref={(input) =>
                      (inputRefs.current[
                        `${currentManualField}-${numberIndex}`
                      ] = input)
                    }
                    onChange={(e) =>
                      handleNumberChange(
                        currentManualField,
                        numberIndex,
                        e.target.value
                      )
                    }
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="col-md-2  col-2">
          <div className="top-content black-color">
            <span>Points</span>
          </div>

          {manualFields.map((currentManualField) => (
            <div
              key={currentManualField}
              id={currentManualField + "points"}
              className="row input-input input-color-change"
            >
              <div className="col">
                <input
                  type="number"
                  className="form-control"
                  // ref={pointsRef}
                  onChange={(e) =>
                    handlePointsChange(currentManualField, e.target.value)
                  }
                />
              </div>
            </div>
          ))}
        </div>

        <div className="col-md-2  col-2">
          <div className="top-content black-color">
            <span>Total</span>
          </div>

          {manualFields.map((currentManualField) => (
            <div
              key={currentManualField}
              id={currentManualField + "total"}
              className="row input-input input-color-change"
            >
              <div className="col">
                <input
                  type="number"
                  className="form-control"
                  readOnly
                  value={calculateTotalPoints(currentManualField)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="point-count">
        <span>Total Points</span>
        <span>{calculateTotalPointsAdded()} </span>
      </div>

      {placeBet.isSuccess ? (
        <NotificationPopup isSuccess message={"Bet Placed Successfully!"} />
      ) : (
        placeBet.isError && (
          <NotificationPopup
            message={`Could not place bet..  ${
              placeBet.error?.response?.data?.error || ""
            }`}
          />
        )
      )}

      <div className="row">
        <div className="col-md-12">
          <button
            type="button"
            disabled={
              placeBet.isPending ||
              placeBet.isSuccess ||
              manualBets().filter((bets) => bets.betKey).length === 0
            }
            className="btn btn-primary btn-blcok width-change"
            onClick={() => {
              return sufficientBalance
                ? placeBet.mutate(manualBets().filter((bets) => bets.betKey))
                : setShowNoBalance(!showNoBalance);
            }}
          >
            Place Bet
          </button>
        </div>
      </div>

      <InsufficientBalance isShow={showNoBalance} showNow={setShowNoBalance} />
    </div>
  );
};

export default ManualGame;
