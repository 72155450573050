import { useMutation } from "@tanstack/react-query";
import apiClient from "../constants/api-client";

const postMobileNumber = (data) => apiClient.post('login/', data);
const postLoginInfo = ({ mobileNumber, otp }) => apiClient.post('player-verify-otp/', { mobile: mobileNumber, otp }).then((res) => {
  localStorage.setItem('authToken', res.data.jwt_token);
});

export const usePostMobile = () => {
  return useMutation({
    mutationKey: ['postMobile'],
    mutationFn: (data) => postMobileNumber(data),
  });
};

export const useVerifyOTP = () => {
  return useMutation({
    mutationKey: ['postLoginInfo'],
    mutationFn: ({ mobileNumber, otp }) => postLoginInfo({ mobileNumber, otp }),
  });
};
