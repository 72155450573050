// Header.js
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderMain from "../components/HeaderMain";
import NavFooter from "../components/NavFooter";
import BabaJi from "../images/com-logo.svg";
import fire from "../images/fire.png";
import Message from "../images/message.png";
import Comment from "../images/comment.png";
import useMarkets from "../hooks/useMarkets";
import useHome, { usePlayerData } from "../hooks/useHome";
import ConvertTime from "../hooks/useConvertTime";
import useLatestResult from "../hooks/useLatestResult";
import useWallet from "../hooks/useWallet";
import TopTitle from "../components/TopTitle";
import { Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import Footer from "../components/Footer";
import KYCModal from "./KYCModal";
import { useUser } from "../constants/UserContext";

export function getTodayDate() {
  const today = new Date();
  let month = String(today.getMonth() + 1); // Adding 1 because January is 0
  let day = String(today.getDate());
  const year = today.getFullYear();

  const authToken = localStorage.getItem('authToken')
  console.log(authToken)

  // Add leading zero if month or day is a single digit
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return [year, month, day].join("-");
}

export const isTimeNotPassed = (receivedTime) => {
  // Get the current time
  const currentTime = new Date();

  // Parse the received time string
  const receivedTimeParts = receivedTime.split(":");
  const receivedHour = parseInt(receivedTimeParts[0], 10);
  const receivedMinute = parseInt(receivedTimeParts[1], 10);
  const receivedSecond = parseInt(receivedTimeParts[2], 10);

  // Set the received time date object with the current date
  const receivedTimeDate = new Date();
  receivedTimeDate.setHours(receivedHour, receivedMinute, receivedSecond, 0);

  // Compare the current time with the received time
  return currentTime < receivedTimeDate;
};

function HomePage() {

  const [showNewModal, setNewShowModal] = useState(false);
  const playerData = usePlayerData();
  const { markets } = useMarkets();
  const { home } = useHome();
  const { latestResult } = useLatestResult();
  const { mobileNumber } = useUser();
  const [showModal, setShowModal] = useState(false);
  const navigateTo = useNavigate();



  const handleOpen = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };



  useEffect(() => {
    playerData.mutate({ mobile: mobileNumber });
  }, []);



  const onPlay = (id) => {
    if (playerData?.data?.kyc == true) {
      navigateTo(`/PlayingGame/${id}`)
    }
    else {
      handleOpen()
    }
  }


  return (
    <div>
      <HeaderMain pageTitle={<Link to="/">Home</Link>} />

      <TopTitle />

      {/* --------Pop up start------- */}
      <div>
        <Modal
          show={showNewModal}
          onHide={() => setNewShowModal(true)}
          centered
        >
          <Modal.Header style={{ backgroundColor: "black", color: "white" }}>
            <div
              className="custom-close-button"
              onClick={() => setNewShowModal(false)}
            >
              <TfiClose />
            </div>
            <div
              className="transfar-heding text-center"
              style={{ textAlign: "center", width: "100%" }}
            >
              Welcome to Babaji Matka
            </div>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "black", color: "white" }}>
            <div className="popup-add-point container">
              <div className="row">
                <div className="col add-pont-title">
                  अगर आपको पैसा एड करने मैं, पैसा निकालने मैं और गेम खेलने मैं
                  कोई समस्या होती है तो आप HELP मैं जाके वीडियो देख सकते हो और
                  हमसे बात भी कर सकते हो
                </div>
              </div>

              <div className="add-point-profile">
                <img src={BabaJi} alt="" />
              </div>

              <div className="row">
                <div className="col add-pont-title"> 🙏जय अघोरी बाबा की🙏</div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/* --------Pop up end---------- */}

      {/* --------Pop up start------- */}
      {/* <div>
        <Modal show={showNewModal} onHide={handleNewClose} centered>
          <Modal.Header style={{ backgroundColor: "black", color: "white" }}>
            <div className="custom-close-button" onClick={handleNewClose}>
              <TfiClose />
            </div>
            <div
              className="transfar-heding text-center"
              style={{ textAlign: "center", width: "100%" }}
            >
              Welcome to Babaji Matka
            </div>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "black", color: "white" }}>
            <div className="popup-add-point container">
              <div className="row">
                <div className="col add-pont-title">
                  अगर आपको पैसा एड करने मैं, पैसा निकालने मैं और गेम खेलने मैं
                  कोई समस्या होती है तो आप HELP मैं जाके वीडियो देख सकते हो और
                  हमसे बात भी कर सकते हो
                </div>
              </div>

              <div className="row">
                <div className="col-4 align-self-center">
                  <Link to="/withdraw-chat-screen">
                    <button
                      type="submit"
                      className="btn Btn-change add-point-submit"
                      onClick={handleNewClose}
                    >
                      Chat
                    </button>
                  </Link>
                </div>
                <div className="col-4 add-point-profile">
                  <img src={BabaJi} alt="" />
                </div>
                <div className="col-4 align-self-center">
                  <Link to="/Help">
                    <button
                      type="submit"
                      className="btn Btn-change add-point-submit"
                      onClick={handleNewClose}
                    >
                      Help
                    </button>
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col add-pont-title"> 🙏जय अघोरी बाबा की🙏</div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div> */}

      {/* --------Pop up end---------- */}

      <div className="top-container">
        <div className="container three-container">
          <div className="cash">
            <div className="section">
              <div className="message">
                <img src={Message} alt="" />
              </div>
              <Link to="/chat-screen">
                <div className="message-content">Deposit Chat</div>
              </Link>
            </div>

            <div className="section">
              <div className="comment message">
                <img src={Comment} alt="" />
              </div>
              <Link to="/withdraw-chat-screen">
                <div className="message-content">Withdraw Chat</div>
              </Link>
            </div>
          </div>
          <div className="babaji-logo">
            <img src={BabaJi} alt="" />
          </div>
          <div className="game-btn">
            <Link to="/AllGames">
              <button type="button" className="btn btn-primary btn-reset">
                Other Game
              </button>
            </Link>
            <button type="button" className="btn Btn-change btn-reset">
              Clear Data
            </button>
          </div>
        </div>
      </div>

      {!(home?.greeting_banner === "<h6></h6>" || home?.greeting_banner === "<h6></h6>\n<p></p>") && <div className="profile-container">
        <div className="container ">
          <div className=" info-container"
            dangerouslySetInnerHTML={{ __html: home?.greeting_banner }}
          ></div>
        </div>
      </div>}

      <div className="home-ticker">
        <marquee dangerouslySetInnerHTML={{ __html: home?.ticker }} className="ticker-content"></marquee>
      </div>

      <div className="top-content latest-result-market">
        <div className="small-box">
          <div className="small-box-bg">
            <h6>{latestResult?.market_name}</h6>
            <h6>Result</h6>
            <h6>{latestResult?.bet_key}</h6>
          </div>
        </div>
      </div>

      <div className="green-content">
        <img src={fire} alt="" />
        <span>सबसे पहले रिजल्ट देखने के लिए क्लिक करें</span>
        <img src={fire} alt="" />
        <div className="btn-area-center">
          <a href="https://bgmgameresult.in/">
            <button
              type="button"
              className="btn btn-primary btn-redesin"
            // onClick={() => setNewShowModal(true)}
            >
              Click Link
            </button>
          </a>
        </div>
        {/* <div className="btn-area-center">
          <Link to="/ResultHistory">
            <button type="button" className="btn btn-primary btn-redesin">Click Link</button></Link>
        </div> */}
      </div>

      <div
        className="offer"
        dangerouslySetInnerHTML={{ __html: home?.note }}
      ></div>

      {/* <CaptureImg /> */}

      <div className="container home-container px-0">
        <div className="top-content">BGM Game Live Result of {getTodayDate()}</div>

        <div className="content">
          <div className="content-header ">
            <span className="homes-content">Market Name</span>
            <span className="homes-content">Open Time</span>
            <span className="homes-content">Close Time</span>
            <span className="homes-content">Result Time</span>
            <span className="homes-content">Previous Result</span>
            <span className="homes-content">Today Result</span>
          </div>
        </div>

        {markets?.map(
          (market) =>
            market.status && (
              <div className="market-stutus">
                {isTimeNotPassed(market.close_time) &&
                  !isTimeNotPassed(market.open_time) ? (
                  // <Link to={`/PlayingGame/${market.id}`}>
                  <a onClick={() =>
                    playerData.isSuccess && playerData?.data?.kyc
                      ? onPlay(market.id)
                      : handleOpen()
                  }>
                    <div className="market-name ">
                      <span className="homes-content">{market.market}</span>
                      <span className="homes-content">
                        {ConvertTime(market.open_time)}
                      </span>
                      <span className="homes-content">
                        {ConvertTime(market.close_time)}
                      </span>
                      <span className="homes-content">
                        {ConvertTime(market.result_time)}
                      </span>
                      <span className="homes-content">
                        {market.previous_result?.bet_key
                          .toString()
                          .padStart(2, "0") || "xx"}
                      </span>
                      <span className="homes-content">
                        {market.latest_result?.bet_key
                          .toString()
                          .padStart(2, "0") || "xx"}
                      </span>

                      {/* <span>
                    Open Time : {ConvertTime(market.open_time)} | Close Time :{" "}
                    {ConvertTime(market.close_time)}{" "}
                  </span>
                  <span className="ms-5">
                    Result At {ConvertTime(market.result_time)}
                  </span> */}
                    </div>


                    {/* <div className="right-content-header">
                  
                </div> 
                  </Link>*/}
                  </a>
                ) : (
                  <a>
                    <div className="market-name ">
                      <span className="homes-content" id="content-table">
                        {market.market}
                      </span>
                      <span className="homes-content">
                        {ConvertTime(market.open_time)}
                      </span>
                      <span className="homes-content">
                        {ConvertTime(market.close_time)}
                      </span>
                      <span className="homes-content">
                        {ConvertTime(market.result_time)}
                      </span>
                      <span className="homes-content">
                        {market.previous_result?.bet_key
                          .toString()
                          .padStart(2, "0") || "xx"}
                      </span>
                      <span className="homes-content">
                        {market.latest_result?.bet_key
                          .toString()
                          .padStart(2, "0") || "xx"}
                      </span>

                      {/* <span>
                    Open Time : {ConvertTime(market.open_time)} | Close Time :{" "}
                    {ConvertTime(market.close_time)}{" "}
                  </span> */}
                      {/* <span className="ms-5">
                    Result At {ConvertTime(market.result_time)}
                  </span> */}
                    </div>
                    {/* <div className="right-content-header"></div> */}
                  </a>
                )}
              </div>
            )
        )}
      </div>

      {/* ----------Content Section end------- */}
      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />


      <KYCModal showModal={showModal} handleClose={handleClose} />


      <Footer />
    </div>
  );
}

export default HomePage;
