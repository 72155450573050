import { useMutation } from "@tanstack/react-query";
import { useUser } from "../constants/UserContext";
import apiClient from "../constants/api-client";

const postMobileNumber = (withdrawlPoints) => apiClient.post('create-withdraw/', withdrawlPoints)

const useWithdrawPoints = () => {
  const { mobileNumber } = useUser();

  return useMutation({
    mutationKey: ['Withdraw Points'],
    mutationFn: (points) => postMobileNumber({ "mobile": mobileNumber, "amount": points }),
    onSuccess: (data) => console.log(data),
  })
};


export default useWithdrawPoints;