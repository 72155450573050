import React, { useEffect, useRef } from 'react'
import TopTitle from '../components/TopTitle'
import useTermsAndCondition from '../hooks/useTermsAndCondition';
import { Link } from 'react-router-dom';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import useReport from '../hooks/Report';
import html2pdf from "html2pdf.js";

const Report = () => {
  const { Report } = useReport();
  const contentRef = useRef();

  const handleDownloadPdf = async () => {
    try {
      const element = contentRef.current;
      if (!element) {
        console.error("Element to convert to PDF is not found.");
        return;
      }

      // Hide the download button before generating the PDF
      const downloadButton = element.querySelector('.download-btn');
      if (downloadButton) {
        downloadButton.style.display = 'none';
      }

      const options = {
        margin: 0.8,
        filename: "Contacting Policy.pdf",
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      await html2pdf().from(element).set(options).save();

      // Show the download button after generating the PDF
      if (downloadButton) {
        downloadButton.style.display = 'block';
      }
    } catch (error) {
      console.error("Failed to generate PDF:", error);
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      console.log("contentRef assigned:", contentRef.current);
    } else {
      console.error("contentRef is not assigned correctly");
    }
  }, [contentRef]);

  return (
    <>
    {/* <HeaderMain pageTitle={<Link to="/">Home</Link>} /> */}

    {/* <TopTitle /> */}
    <div className="container tearms-and-condition-container" ref={contentRef}>
    <div className="top-heading-top gap-extra">
      <div
        dangerouslySetInnerHTML={{ __html: Report?.heading }}
      ></div>
    </div>

    <div className="container tearms-and-condition-container">
      <div
        dangerouslySetInnerHTML={{ __html: Report?.paragraph }}
      ></div>
      <div>
            <button className="btn btn-primary download-btn" onClick={handleDownloadPdf}>
              Download to PDF
            </button>
          </div>
    </div>
    </div>

    <div className="bootom-footer">
      <span>Copyright © Dec 2023, All Right Reserved By </span>
      <a href="https://www.litpix-studio.com/">
        <span id="green-color">Litpix Studio</span>
      </a>
    </div>

    {/* <NavFooter /> */}
    <Footer/>
  </>
  )
}

export default Report