import React, { useEffect, useState } from "react";
import InsufficientBalance from "./InsufficientBalance";
import useCrossingGame from "../hooks/useCrossingGame";
import usePlaceBet from "../hooks/usePlaceBet";
import useWallet from "../hooks/useWallet";
import NotificationPopup from "./NotificationPopup";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

const CrossingGame = ({ market }) => {
  const [sufficientBalance, setSufficientBalance] = useState(true);
  const [showNoBalance, setShowNoBalance] = useState(!sufficientBalance);

  const { wallet } = useWallet();
  const placeBet = usePlaceBet();

  const {
    crossingFirstInput,
    crossingSecondInput,
    setCrossingFirstInput,
    setCrossingSecondInput,
    deleteCrossingJodi,
    crossingPoints,
    setCrossingPoints,
    crossingJodis,
    setCrossingJodis,
    buildCrossingJodi,
    calculateCrossingPointsTotal,
  } = useCrossingGame();

  useEffect(() => {
    setSufficientBalance(
      wallet?.total_amount >= calculateCrossingPointsTotal()
    );
  }, [wallet, calculateCrossingPointsTotal()]);

  const crossingJodiBets = () =>
    crossingJodis.map((crossingJodi) => ({
      betKey: crossingJodi,
      points: crossingPoints,
      betType: "Jodi",
      jodiType: "Jodi",
      market: market?.market,
    }));

  const navigateTo = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (placeBet.isSuccess) {
        navigateTo(`/AllGames`);
      }
    }, 1000);
  }, [placeBet]);

  return (
    <>
      <div
        className="tab-pane fade"
        id="pills-crossing"
        role="tabpanel"
        aria-labelledby="pills-crossing-tab"
      >
        <div className="black-line"></div>

        <div className="point-count">
          <span>Points Remaining: {wallet?.total_amount}</span>
          <span>Points Added: {calculateCrossingPointsTotal()}</span>
        </div>

        <form>
          <div className="form-row input-input input-bottom">
            <div className="col">
              <p>Number</p>
              <input
                type="number"
                className="form-control"
                placeholder="Number"
                maxLength={6}
                value={crossingFirstInput}
                onChange={(e) => {
                  setCrossingFirstInput(e.target.value);
                  crossingFirstInput === crossingSecondInput &&
                    setCrossingSecondInput(e.target.value);
                }}
              />
            </div>
            <div className="col">
              <p>Number</p>
              <input
                type="number"
                className="form-control"
                placeholder="Number"
                maxLength={6}
                value={crossingSecondInput}
                onChange={(e) => setCrossingSecondInput(e.target.value)}
              />
            </div>
          </div>
        </form>

        <form>
          <div className="form-row input-input input-bottom">
            <div className="col">
              <p>Points</p>
              <input
                type="number"
                className="form-control"
                placeholder="Points"
                value={crossingPoints}
                onChange={(e) => setCrossingPoints(e.target.value)}
              />
            </div>
          </div>
        </form>

        <div className="row">
          <div className="col-md-12">
            <button
              type="button"
              className="btn btn-primary btn-blcok width-change"
              onClick={() =>
                setCrossingJodis(
                  buildCrossingJodi(crossingFirstInput, crossingSecondInput)
                )
              }
            >
              Add
            </button>
          </div>
        </div>

        {crossingJodis && (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Number Type</th>
                <th scope="col">Number</th>
                <th scope="col">Points</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {crossingJodis.map((jodi) => (
                <tr key={jodi}>
                  <th>Crossing</th>
                  <td>{jodi}</td>
                  <td>{crossingPoints}</td>
                  <td>
                    <span
                      className="material-symbols-outlined"
                      onClick={() => deleteCrossingJodi(jodi)}
                    >
                      delete_forever
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <div className="black-line black-line-black"></div>

        {placeBet.isSuccess ? (
          <NotificationPopup isSuccess message={"Bet Placed Successfully!"} />
        ) : (
          placeBet.isError && (
            <NotificationPopup
              message={`Could not place bet..  ${
                placeBet.error?.response?.data?.error || ""
              }`}
            />
          )
        )}

        {crossingJodis && (
          <>
            <div className="point-count">
              <span>Total Points</span>
              <span>{crossingJodis?.length * crossingPoints} </span>
            </div>

            <div className="row">
              <div className="col-md-12">
                <button
                  type="button"
                  disabled={placeBet.isPending || placeBet.isSuccess}
                  className="btn btn-primary btn-blcok width-change"
                  onClick={() => {
                    return sufficientBalance
                      ? placeBet.mutate(crossingJodiBets())
                      : setShowNoBalance(!showNoBalance);
                  }}
                >
                  Place Bet
                </button>
              </div>
            </div>
          </>
        )}

        <InsufficientBalance
          isShow={showNoBalance}
          showNow={setShowNoBalance}
        />
      </div>
    </>
  );
};

export default CrossingGame;
