import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavFooter from "../components/NavFooter";
import HeaderMain from "../components/HeaderMain";
import ArrowArrow from "../images/aarow-arrow.png";
import useDecemberMonthResult from "../hooks/useDecemberMonthResult";
import TopTitle from "../components/TopTitle";
import Footer from "../components/Footer";

// Function to separate date and time from a datetime string
function separateDateAndTime(dateTimeString) {
  const dateTime = new Date(dateTimeString);

  const year = dateTime.getUTCFullYear();
  const month = dateTime.getUTCMonth() + 1;
  const day = dateTime.getUTCDate();

  const hours = dateTime.getUTCHours();
  const minutes = dateTime.getUTCMinutes();
  const seconds = dateTime.getUTCSeconds();

  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return { date: formattedDate, time: formattedTime };
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString("en-US", options);
}

function getCurrentMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  return `${year}-${month}`;
}

function DecemberMonthResults() {
  const [date, setDate] = useState(getCurrentMonth());
  const { result, isLoding } = useDecemberMonthResult({ date });

  return (
    <>
      <HeaderMain pageTitle={<Link to="/">Home</Link>} />

      <TopTitle />

      <div className="container-fluid result-heading">
        <div className="row date-and-month align-items-center">
          <div className="col-lg-2 col-md-6">
            <div className="form-inline mob-block">
              <div className="form-group">
                <div className="input-group select-input">
                  <input
                    type="month"
                    id="monthInput"
                    className="form-control"
                    placeholder="MM-YYYY"
                    defaultValue={"MM-YYYY"}
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  {/* <div className="input-group-text">
                    <i className="bi bi-calendar"></i>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-6 result-main-heading">
            <h6>{formatDate(date)} Result</h6>
          </div>
        </div>
      </div>

      <div className="container-fluid december-result-container">
        <div className="table-responsive">
          <table className="table table-bordered table-green-table table-hover">
            {/* <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">MAFIYA NIGHT</th>
                <th scope="col">DISAWER</th>
                <th scope="col">FARIDABAD</th>
                <th scope="col">GAZIYABAD</th>
                <th scope="col">GALI</th>
                <th scope="col">INDIA CLUB</th>
                <th scope="col">SHIV SHAKTI</th>
                <th scope="col">SHREE GANESH</th>
                <th scope="col">DEV DARSHAN</th>
                <th scope="col">DELHI BAZAR</th>
                <th scope="col">SILVER CITY</th>
                <th scope="col">LONDON BAZAR</th>
                <th scope="col">NEPAL BORDER</th>
                <th scope="col">HR // 20</th>
                <th scope="col">MORNING START</th>
              </tr>
            </thead> */}
            <ResultTableHeader result={result} />
            <ResultTablebody result={result} isLoading={isLoding} />
          </table>
        </div>

        <a href="https://www.babajiisatta.com/">
          <div className="more-result">
            <span>For More Result</span>
            <img src={ArrowArrow} alt="" />
          </div>
        </a>
      </div>

      <div className="bootom-footer">
        <span>Copyright © Dec 2023, All Right Reserved By </span>
        <a href="https://www.litpix-studio.com/">
          <span id="green-color">Litpix Studio</span>
        </a>
      </div>

      <NavFooter />
      <Footer/>
    </>
  );
}

const ResultTableHeader = ({ result }) => {
  const marketNames = new Set();

  result?.forEach((item) => {
    marketNames.add(item.market_name);
  });

  const sortedMarketNames = Array.from(marketNames)
    .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
    .sort();

  return (
    <thead>
      <tr>
        {sortedMarketNames?.length > 0 && <th scope="col">Date</th>}
        {sortedMarketNames?.map((marketName, index) => (
          <th key={index} scope="col">
            {marketName}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const ResultTablebody = ({ result, isLoading }) => {
  if (isLoading) {
    return "Loading..";
  }
  const marketData = {};

  result?.forEach((item) => {
    const { created_at, market_name } = item;
    const { date } = separateDateAndTime(created_at);
    if (!marketData[date]) {
      marketData[date] = {};
    }

    // Replace existing items with the new one
    marketData[date][market_name] = [item];
  });

  const dates = Object.keys(marketData).sort();

  const marketNames = new Set();

  Object.values(marketData).forEach((markets) => {
    Object.keys(markets).forEach((marketName) => {
      marketNames.add(marketName);
    });
  });

  const sortedMarketNames = Array.from(marketNames).sort();

  return (
    <tbody>
      {dates.map((date, index) => (
        <tr key={index}>
          <th scope="row">{date}</th>
          {sortedMarketNames.map((market, index) => (
            <td key={index}>
              {marketData[date][market] ? (
                marketData[date][market].map((item, index) => (
                  <div key={index}>{item.bet_key}</div>
                ))
              ) : (
                <div>-</div>
              )}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default DecemberMonthResults;
