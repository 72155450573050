import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { TfiClose } from "react-icons/tfi";
import BabaJi from "../images/com-logo.svg";
import WheelComponent from "./WheelComponent";
import { usePlayerData } from "../hooks/useHome";
import { useUser } from "../constants/UserContext";
import useSpinWin from "../hooks/useSpinWin";
import NotificationPopup from "./NotificationPopup";
import { Wheel } from "react-custom-roulette";
import { separateDateAndTime } from "./ChatScreen";
import { useParams } from "react-router-dom";
import useSpinUser from "../hooks/useSpinUser ";

const SpinAndEarn = ({ showModal, setShowModal ,refetch }) => {
  const [spinSuccess, setSpinSuccess] = useState("");
  const [spinError, setSpinError] = useState("");
  const [zeroSpin, setZeroSpin] = useState(false);
  
  
  const { mobileNumber } = useUser();
  const playerInfo = usePlayerData();
  const winSpin = useSpinWin();
  const { spinUser} = useSpinUser({refetch});

  useEffect(() => {
    playerInfo.mutate({ mobile: mobileNumber });
  }, []);

  useEffect(() => {
    playerInfo?.data?.spin_remaining === 0 && setZeroSpin(true);
  }, [playerInfo.data?.spin_remaining]);

  // ==============> SPIN WHEEL
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  // Data for the wheel
  const data = [
    { option: "10", style: { backgroundColor: "#EE4040", textColor: "white" } },
    { option: "20", style: { backgroundColor: "#F0CF50", textColor: "black" } },
    { option: "30", style: { backgroundColor: "#815CD1", textColor: "white" } },
    { option: "40", style: { backgroundColor: "#3DA5E0", textColor: "black" } },
    { option: "50", style: { backgroundColor: "#34A24F", textColor: "white" } },
    { option: "60", style: { backgroundColor: "#F9AA1F", textColor: "black" } },
    { option: "70", style: { backgroundColor: "#EC3F3F", textColor: "white" } },
    { option: "80", style: { backgroundColor: "#FF9000", textColor: "black" } },
    { option: "90", style: { backgroundColor: "#513B1C", textColor: "white" } },
    {
      option: "100",
      style: { backgroundColor: "#73A16C", textColor: "black" },
    },
  ];

  const handleSpinClick = () => {
    if (!mustSpin && playerInfo && playerInfo?.data?.spin_remaining !== 0) {
     

      const used = String(playerInfo?.data?.spin_used);
      const index = parseInt(used.charAt(used.length - 1));
      const newPrizeNumber = index;

      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    } else if (playerInfo?.data?.spin_remaining === 0) {
      setSpinError("Refer someone to earn spin.");
    }
  };

  const onFinished = async (winner) => {
    await winSpin.mutateAsync({ mobile: mobileNumber, amount: winner });
    setSpinSuccess(winner);
    setTimeout(() => {
      setSpinSuccess("");
    }, 1200);
    playerInfo.mutate({ mobile: mobileNumber });
    setMustSpin(false);
  };
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} centered  className="modal-scrollable"> 
      <div className="transfar-heding">Welcome to The BGM Game</div>
      <Modal.Header closeButton>
        <div
          className="custom-close-button"
          onClick={() => setShowModal(false)}
        >
          <TfiClose />
        </div>
      </Modal.Header>
      {winSpin.isSuccess && spinSuccess && (
        <NotificationPopup
          noReload
          message={`CONGRATULATIONS!! You WON ${spinSuccess} points!🥳`}
          isSuccess
        />
      )}
      {spinError && <NotificationPopup noReload message={spinError} />}
      <Modal.Body >
        <div className="add-pont-title mt-0">
          SPIN LEFT 👉{" "}
          {playerInfo?.data?.spin_remaining ||
            (!zeroSpin ? "Calculating spins left.." : 0)}
        </div>

        <div
          className="spin-wheel"
          style={{ pointerEvents: zeroSpin ? "none" : "auto", display: "flex", justifyContent: "center", alignItems: "center"}}
        >
          <Wheel
            mustStartSpinning={mustSpin}
            outerBorderWidth={2}
            outerBorderColor="lightgray"
            radiusLineWidth={2}
            radiusLineColor="lightgray"
            prizeNumber={prizeNumber}
            data={data}
            onStopSpinning={() => {
              const amount = `${String(prizeNumber + 1)}0`;
              onFinished(amount);
            }}
          />
        </div>

        <div className="text-center p-3 row">
          <button
            type="button"
            class=" btn btn-primary"
            disabled={zeroSpin}
            onClick={handleSpinClick}
          >
            🔥SPIN🔥
          </button>
        </div>

        {/* <div className="add-pont-title mt-0">
          इनाम पाने के लिए यहां क्लिक करें👇👇👇
        </div>

        <div className="text-center p-3">
          <button type="button" class=" btn btn-primary">
            🔥CLAIM REWARD🔥
          </button>
        </div>

        <div className="add-pont-title mt-0">
          और स्पिन पैन के लिए रेफर करें 👇
        </div>

        <div className="text-center p-3">
          <button type="button" class="btn btn-primary">
            🔥COPY REFER LINK🔥
          </button>
        </div> */}


 <div className="table-responsive white-table">
          <table className="table table-green">
            <thead>
              <tr>
                <th scope="col">S. No.</th>
                <th scope="col"> Mobile</th>
                <th scope="col"> Points</th>
                <th scope="col">Date</th>
              </tr>
            </thead>

            <DatewiseSpinTableBody
                  data={spinUser || []} 
                  refetch={refetch}
                />
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SpinAndEarn;

const DatewiseSpinTableBody = ({ data, refetch }) => {
  console.log(data)
  return (
    <tbody>
      {data && data?.length > 0 ?
      (data?.map ((bonus, index) => (
        <tr className="text-center">
          <td> {index + 1}</td>
          <td>{bonus.mobile}</td>  
          <td>{bonus.points}</td>
          <td>
            {`${separateDateAndTime(bonus.created_at).date} `}
             
          </td>
         
        </tr>
      ))) : (<tr>
        <td scope="row" colSpan={4} className="text-center bg-light-sec">
          No Data Available.
        </td>
      </tr>)}

      
    </tbody>
  );
};


